import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EventoService } from '../../../services/evento.service';
import { ResultadoService } from '../../../services/resultado.service';
import { CertificadoHelper } from 'src/app/shared/certificado_helper';
import { format } from 'date-fns';
import * as jsPDF from 'jspdf';
import { GoogleAnalytics } from 'src/app/shared/google-analytics';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
    public id = 0;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    public resultados: any[] = [];
    public desafios: any[] = [];
    public configuracionDistancias: any[] = [];
    public resultadosFilter: any[] = [];
    public search: string;
    public evento;
    public searchCategoria = '0';
    public searchDesafio = 'TODOS';
    public categorias: any[] = [];
    public urlBannerSanitizer;
    public certificado = {
        'deportista':  '-',
        'evento' : '-',
        'categoria': '-',
        'fecha': '-',
        'fecha_emision': '-',
        'tiempo': '-'
    };

    constructor(
        private activatedroute: ActivatedRoute,
        private resultadoService: ResultadoService,
        private eventoService: EventoService,
        private sanitizer: DomSanitizer,
        private certificadoHelper: CertificadoHelper,
        private googleAnalytics: GoogleAnalytics


    ) {
        this.activatedroute.queryParams.subscribe(params => {
            if (params.id !== undefined) {
                this.id = Number(params.id);

            }
        });
    }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('resultados', 'exitoso', '', 'click', 0);

        this.resultadoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataInitial();
    }

    getDataInitial() {
        this.loading = true;
        let petitionEvent = null;
        let petitionConfiguracionDistancias = null;

        petitionConfiguracionDistancias = this.eventoService.obtenerConfiguracionDistanciaPorEvento(this.id);
        petitionEvent = this.eventoService.obtenerEventoSimplifyById(this.id);
        const obtenerDesafiosPorEventoId = this.eventoService.obtenerDesafiosPorEventoId(this.id);

        const petitions = forkJoin([petitionEvent, petitionConfiguracionDistancias, obtenerDesafiosPorEventoId]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.evento = res[0];
                    this.configuracionDistancias = res[1];
                    this.desafios = res[2];
                    this.getDataDefault();
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getDataDefault() {
        this.loading = true;
        let petition = null;


        if (this.id !== undefined && this.id !== 0) {
            petition = this.resultadoService.obtenerResultadosPorEvento(this.id);
        } else {
            petition = this.resultadoService.obtenerResultados();

        }

        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.resultados = [];

                    res[0].forEach((element: any) => {
                        element.collapsable = false;
                        this.resultados.push(element);
                        if (this.categorias.find((cat: any) => cat.id === element.categoriaRider.id) === undefined) {
                            this.categorias.push(element.categoriaRider);
                        }
                    });

                    this.resultadosFilter = this.resultados;
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    changeSearch() {
        this.searchCategoria = '0';
        this.searchDesafio = 'TODOS';
        this.resultadosFilter = this.resultados.filter((resultado) =>
            (resultado.rider.nombre.toLowerCase() + ' ' + resultado.rider.apellido.toLowerCase()).includes(this.search.toLowerCase()) ||
            (resultado.rider.dor !== null && (resultado.rider.dor.toLowerCase()).includes(this.search.toLowerCase()))
        );
    }

    changeSearchCategoria() {
        this.search = '';
        this.searchDesafio = 'TODOS';
        if (this.searchCategoria === '0') {
            this.resultadosFilter =
                this.resultados;
        } else {
            this.resultadosFilter =
                this.resultados.filter((resultado) =>
                    // resultado.categoria.nombre.toLowerCase().includes(this.search.toLowerCase())
                    `${resultado.categoriaRider.nombre} - ${resultado.categoriaRider.desafioEntity.nombre} - ${resultado.categoriaRider.modo} - ${resultado.categoriaRider.sexo}` === this.searchCategoria
                );
        }

    }

    changeSearchDesafio() {
        this.search = '';
        this.searchCategoria = '0';
        if (this.searchDesafio === 'TODOS') {
            this.resultadosFilter =
                this.resultados;
        } else {
            this.resultadosFilter =
                this.resultados.filter((resultado) =>
                    // resultado.categoria.nombre.toLowerCase().includes(this.search.toLowerCase())
                    `${resultado.categoriaRider.desafioEntity.nombre}` === this.searchDesafio
                );
        }

    }

    hrToSec(hr: string): number {
        const splitHr = hr.split(':').reverse();
        let sec = 0;
        for (const [i, h] of splitHr.entries()) {
            sec = (Math.pow(60, i) * parseInt(h)) + sec;
        }
        return sec;
    }

    calculoVelocidadPromedio(resultado) {
        if (this.hrToSec(resultado.tiempo) === 0) {
            return '-';
        }
        return (this.configuracionGiro(resultado).kmxgiro / (this.hrToSec(resultado.tiempo) / 3600)).toFixed(2);
    }

    secondDif(hrSec1: number, hrSec2: number): number {
        return hrSec2 - hrSec1;
    }

    configuracionGiro(resultado) {
        return this.configuracionDistancias.find((configuracionDistancia) => configuracionDistancia.categoriaRider === resultado.categoriaRider.id);
    }

    hrFormat(sec: number) {
        let negative = false;
        if (sec < 0) {
            sec = Math.abs(sec);
            negative = true;
        }
        let hours: any = Math.floor(sec / 3600); // get hours
        let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
        if (hours < 10) { hours = '0' + hours; }
        if (minutes < 10) { minutes = '0' + minutes; }
        if (seconds < 10) { seconds = '0' + seconds; }

        return negative ? hours + ':' + minutes + ':' + seconds : '-' + hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    }

    getTopCategory(categoria) {
        return this.resultados.filter((resultado) =>
            // resultado.categoria.nombre.toLowerCase().includes(this.search.toLowerCase())
            `${resultado.categoriaRider.nombre} - ${resultado.categoriaRider.modo} - ${resultado.categoriaRider.sexo}` === `${categoria.nombre} - ${categoria.modo} - ${categoria.sexo}`
        )[0];
    }

    sanitizeUrl(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${url}/preview`);


    }

    obtenerCantidadGiros(resultado) {
        return Array(resultado.categoriaRider.giros).fill(0).map((x, i) => i + 1);
    }

    obtenerTiempoGiro(resultado, indice) {
        return resultado[`tiempo_giro_${indice}`];
    }

    mostrarDetalle(resultado) {
        resultado.collapsable = !resultado.collapsable;
    }

    encontrarHoraMenor(resultado): string {
        const horas = [
            resultado.tiempo_giro_1,
            resultado.tiempo_giro_2,
            resultado.tiempo_giro_3,
            resultado.tiempo_giro_4,
            resultado.tiempo_giro_5,
            resultado.tiempo_giro_6,
            resultado.tiempo_giro_7,
            resultado.tiempo_giro_8,
            resultado.tiempo_giro_9,
            resultado.tiempo_giro_10,
            resultado.tiempo_giro_11,
            resultado.tiempo_giro_12,
            resultado.tiempo_giro_13,
            resultado.tiempo_giro_14,
            resultado.tiempo_giro_15,
        ]

        let horaMenor: string | null = null;

        for (const hora of horas) {

            if (hora !== null && hora.length > 0 && (!horaMenor || hora < horaMenor)) {
                horaMenor = hora;
            }
        }

        return horaMenor || '';
    }

    encontrarGiroMenor(resultado): number {
        const horas = [
            resultado.tiempo_giro_1,
            resultado.tiempo_giro_2,
            resultado.tiempo_giro_3,
            resultado.tiempo_giro_4,
            resultado.tiempo_giro_5,
            resultado.tiempo_giro_6,
            resultado.tiempo_giro_7,
            resultado.tiempo_giro_8,
            resultado.tiempo_giro_9,
            resultado.tiempo_giro_10,
            resultado.tiempo_giro_11,
            resultado.tiempo_giro_12,
            resultado.tiempo_giro_13,
            resultado.tiempo_giro_14,
            resultado.tiempo_giro_15,
        ]
        let giroMenor = 16;
        let horaMenor: string | null = null;
        let tieneGiro = false;

        let indice = 1;
        for (const hora of horas) {
            if (hora !== null && hora.length > 0 && (!horaMenor || hora < horaMenor)) {
                horaMenor = hora;
                giroMenor = indice;
                tieneGiro = true;
            }
            indice++;
        }

        return tieneGiro ? giroMenor : 0;
    }

    generarCertificado(resultado){
        this.googleAnalytics.eventEmitter('resultados-generar-certificado', 'exitoso', '', 'click', 0);

        this.loading = true;
        const pdf = new jsPDF.jsPDF({
            unit: 'px',
            format: [792, 617], // Ancho x Alto
            orientation: 'landscape',
            compress: true,
            
          });

          const fechaEvento = new Date(this.evento.fecha_evento);
          const formattedDateEvento = format(fechaEvento.getTime() + fechaEvento.getTimezoneOffset() * 60000, 'dd-MM-yyyy');
          
          const fechaHoy = new Date();
          const formattedDateHoy = format(fechaHoy.getTime() + fechaHoy.getTimezoneOffset() * 60000, 'dd-MM-yyyy');

          this.certificado = {
            'deportista':  `${resultado.rider.nombre} ${resultado.rider.apellido}`,
            'evento' : this.evento.nombre,
            'categoria': resultado.categoriaRider.nombre,
            'fecha': formattedDateEvento,
            'fecha_emision': formattedDateHoy,
            'tiempo' : resultado.tiempo
        };

        var elemento = this.certificadoHelper.generarCertificado(this.certificado);


          pdf.html(elemento, {
            html2canvas: {
              scale:1,
              letterRendering: true
      
            },  
            callback: (pdf) => {
                this.loading = false;
              pdf.save('certificado-participacion.pdf');
            }
          });
    }

}
