<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>{{dataUser.firstName}} {{dataUser.lastName}}</h3>

                        <ul>
                            <li><a routerLink="/profile">Mi Perfil <i class='bx bxs-user'></i></a></li>
                            <li><a routerLink="/mis-inscripciones">Mis inscripciones <i class='bx bx-task'></i></a></li>
                            <li><a routerLink="/mis-transacciones">Mis pagos <i class='bx bxs-credit-card'></i></a></li>
                            <li><a routerLink="/mis-estadisticas">Mis estadisticas <i class='bx bxs-bar-chart-alt-2'></i></a></li>
                            <li><a routerLink="/change-password">Contraseña <i class='bx bxs-key'></i></a></li>
                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <h2>Mis Pagos</h2>
                <br>
                <section class="cart-area">
                    <div class="container">
                        <div class="cart-wrap">
                            <table class="table">
                                <thead class="thead">
                                <tr>
                                    <th class="table-head" scope="col"># Pago</th>
                                    <th class="table-head" scope="col">Fecha de pago</th>
                                    <th class="table-head" scope="col">Estado</th>
                                    <th class="table-head" scope="col">Monto</th>
                                    <th class="table-head" scope="col"></th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr *ngFor="let transaccion of transacciones">
                                    <td>{{transaccion.id}}</td>
                                    <td>{{transaccion.createdAt  | date : 'dd-MM-yyyy' : 'UTC'}}</td>
                                    <td>{{transaccion.estado}}</td>
                                    <td>$ {{transaccion.monto}}</td>
                                    <td>
                                        <a routerLink="/detalle-transaccion" [queryParams]="{ id: transaccion.id}">Ver más</a>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
