<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logoriderticketmobile.png" alt="Logo"></a>
    </div>

    <div class="main-nav main-nav-two">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logoriderticket.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Inicio</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/eventos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Eventos</a>
                        </li>

                        
                        
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Resultados <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/resultados-evento" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Eventos</a></li>

                                <li class="nav-item"><a routerLink="/ranking-campeonato" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Ranking</a></li>

                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/reserva-numeros" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Reserva números</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/cambio-categoria" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cambio Categorias</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/solicitud-devolucion" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Devolución ticket</a>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/fechas-disponibles" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Agenda de Cronometrajes</a>
                        </li> -->

                        <!--                        <li class="nav-item">-->
                        <!--                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home <i class='bx bx-chevron-down'></i></a>-->

                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page One</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page Two</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page Three</a></li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->
<!--                        <li class="nav-item"><a routerLink="/noticias" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Noticias</a></li>-->

<!--                        <li class="nav-item"><a routerLink="/nosotros" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Nosotros</a></li>-->
<!--                        <li class="nav-item"><a routerLink="/servicios" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Servicios</a></li>-->

                        <!--                        <li class="nav-item"><a routerLink="/categories" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Categories</a></li>-->

                        <!--                        <li class="nav-item">-->
                        <!--                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class='bx bx-chevron-down'></i></a>-->

                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->

                        <!--                        <li class="nav-item">-->
                        <!--                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class='bx bx-chevron-down'></i></a>-->

                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->

                        <!--                        <li class="nav-item">-->
                        <!--                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class='bx bx-chevron-down'></i></a>-->

                        <!--                            <ul class="dropdown-menu">-->
                        <!--                                <li class="nav-item"><a routerLink="/food-collection" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Food Collection</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/online-order" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Order</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/chefs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Chefs</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/book-table" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Book A Table</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>-->

                        <!--                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>-->
                        <!--                            </ul>-->
                        <!--                        </li>-->

                        <!--                        <li class="nav-item">-->
                        <!--                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>-->
                        <!--                        </li>-->
                    </ul>

                    <div class="side-nav user">
                        <a *ngIf="dataUser" routerLink="/profile">{{dataUser.firstName}}</a>
                        <a *ngIf="!dataUser" class="nav-cart" routerLink="/login"><i class='bx bxs-user'></i> </a>

                    </div>
                    <div class="side-nav">
                        <a class="nav-cart" routerLink="/carro"><i class='bx bxs-cart'></i> <span>{{this.eventos}}</span></a>
                        <!--                        <a class="nav-tel" href="tel:+56955284395"><i class='bx bxs-phone-call'></i> +569 55284395</a>-->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>

<div id="myModalRight" class="modal fade modal-right" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <img src="assets/img/logo.png" alt="Logo">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <h2>About Us</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic aliquid quas qui minus! Dolor, ad. Odit, ullam perspiciatis nesciunt numquam explicabo, sunt ipsa libero ipsum maiores officia eius reprehenderit exercitationem.</p>

                <div class="image-area">
                    <h2>Instagram</h2>

                    <div class="row">
                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog1.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog2.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog3.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog4.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog5.jpg" alt="Instagram"></a>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" target="_blank"><img src="assets/img/home-one/blog6.jpg" alt="Instagram"></a>
                        </div>
                    </div>
                </div>

                <div class="social-area">
                    <h3>Our Social Contact</h3>

                    <ul>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
