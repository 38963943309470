<footer class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logobikerace.png" alt="Logo"></a>
<!--                        <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                        <div class="social-link">
                            <ul>
<!--                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                                <li><a href="https://instagram.com/bikerace.cl" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
<!--                <div class="footer-item">-->
<!--                    <div class="footer-services">-->
<!--                        <h3>Services</h3>-->

<!--                        <ul>-->
<!--                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Support</a></li>-->
<!--                            <li><a routerLink="/about"><i class='bx bx-chevron-right'></i> About</a></li>-->
<!--                            <li><a routerLink="/chefs"><i class='bx bx-chevron-right'></i> Chefs</a></li>-->
<!--                            <li><a routerLink="/blog"><i class='bx bx-chevron-right'></i> Blog</a></li>-->
<!--                            <li><a routerLink="/privacy-policy"><i class='bx bx-chevron-right'></i> Privacy Policy</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="col-sm-6 col-lg-3">
<!--                <div class="footer-item">-->
<!--                    <div class="footer-services">-->
<!--                        <h3>Quick Links</h3>-->

<!--                        <ul>-->
<!--                            <li><a routerLink="/services"><i class='bx bx-chevron-right'></i> Services</a></li>-->
<!--                            <li><a routerLink="/food-collection"><i class='bx bx-chevron-right'></i> Food Collection</a></li>-->
<!--                            <li><a routerLink="/online-order"><i class='bx bx-chevron-right'></i> Online Order</a></li>-->
<!--                            <li><a routerLink="/blog"><i class='bx bx-chevron-right'></i> Blog</a></li>-->
<!--                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Contact</a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Contáctanos</h3>

                        <ul>
                            <li><a href="tel:+56949575982"><i class='bx bx-phone-call'></i> +569 49575982</a></li>
                            <li><a href="mailto:hola@riderticket.cl"><i class='bx bx-message-detail'></i> hola@riderticket.cl</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Copyright @2022. Todos los derechos reservados por <a href="https://www.riderticket.cl" target="_blank">RiderTicket</a></p>
        </div>
    </div>
</div>
