import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RiderService } from '../../../services/rider.service';
import { GoogleAnalytics } from '../../../shared/google-analytics';
import { TransaccionService } from 'src/app/services/transaccion.service';

@Component({
  selector: 'app-inscription-details',
  templateUrl: './inscription-details.component.html',
  styleUrls: ['./inscription-details.component.scss']
})
export class InscriptionDetailsComponent implements OnInit {
    public id = 0;
    public rider: any;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    url = environment.urlRiderWeb + 'detalle-inscripcion?id=';
    elementType = NgxQrcodeElementTypes.URL;
    errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;


    constructor(
        private activatedroute: ActivatedRoute,
        private riderService: RiderService,
        private googleAnalytics: GoogleAnalytics,
        private router: Router,
    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

  ngOnInit(): void {
      this.googleAnalytics.eventEmitter('mi-inscripcion', 'exitoso', '', 'click', 0);

      this.riderService.observableData$
          .subscribe(
              res => {
                  if (res) {
                      this.getDataDefault();
                  }
              });

      this.getDataDefault();

  }

    getDataDefault() {
        this.loading = true;
        const obtenerEvento = this.riderService.obtenerRider(this.id);

        const petitions = forkJoin([obtenerEvento]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.rider = res[0];
                    this.url = this.url + this.rider.id;
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    verTicket() {
        this.router.navigate(['/mi-ticket'], { queryParams: { id: this.id } });

     }

     round(value: any) {
        return Math.round(Number(value));
    }
}
