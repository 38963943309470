<app-navbar-style-three></app-navbar-style-three>

<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>404!</h1>
                    <p>Disculpa! Página no encontrada</p>
                    <span>Oops! La página que buscas no existe. es posible que se haya movido o eliminado.</span>
                    <a routerLink="/">Ir al inicio</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
