import { Component, OnInit } from '@angular/core';
import {NoticiaService} from '../../../services/noticia.service';
import {forkJoin, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-noticia-details',
  templateUrl: './noticia-details.component.html',
  styleUrls: ['./noticia-details.component.scss']
})
export class NoticiaDetailsComponent implements OnInit {
    public loading = false;
    public noticia: any;
    private compDestroy: Subject<boolean> = new Subject();
    public id = 0;

  constructor(
      private activatedroute: ActivatedRoute,
      private noticiaService: NoticiaService,
  ) {
      this.activatedroute.queryParams.subscribe(params => {
          this.id = Number(params.id);
      });
  }

    ngOnInit(): void {
        this.noticiaService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerNoticias = this.noticiaService.obtenerNoticiaPorId(this.id);

        const petitions = forkJoin([obtenerNoticias]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.noticia = res[0];


                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

}
