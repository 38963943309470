import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {
    public urlPanelAdministracion = '';

  constructor() {
      this.urlPanelAdministracion = environment.urlPanelAdministracion;
  }

  ngOnInit(): void {
  }

}
