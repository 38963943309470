import {Component, OnInit} from '@angular/core';
import {GoogleAnalytics} from '../../../shared/google-analytics';
import {AgendaService} from '../../../services/agenda.service';
import {forkJoin, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {
    CalendarEvent,
    CalendarView,
} from 'angular-calendar';
import {
    isSameMonth,
    isSameDay,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
    format, subDays, addDays, addHours,
} from 'date-fns';
import { EventColor } from 'calendar-utils';

const colors: Record<string, EventColor> = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
};

@Component({
  selector: 'app-fechas-disponibles',
  templateUrl: './fechas-disponibles.component.html',
  styleUrls: ['./fechas-disponibles.component.scss']
})
export class FechasDisponiblesComponent implements OnInit {
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();

    // CALENDAR
    locale = 'es';

    view: CalendarView = CalendarView.Month;
    viewDate: Date = new Date();
    activeDayIsOpen = false;
    CalendarView = CalendarView;

    refresh = new Subject<void>();

    events: CalendarEvent[] = [
    ];
    modalData: {
        action: string;
        event: CalendarEvent;
    };
    // END CALENDAR

    constructor(
      private googleAnalytics: GoogleAnalytics,
      private agendaService: AgendaService,
  ) { }

    handleEvent(action: string, event: CalendarEvent): void {
        this.modalData = { event, action };

        window.open(event.meta.url_redireccion);
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0);
            this.viewDate = date;
        }
    }


    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('fechas-disponibles', 'exitoso', '', 'click', 0);

        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        this.loadData(firstDay.toISOString(), lastDay.toISOString());
    }


    loadData(desde: any, hasta: any){

        this.loading = true;
        const obtenerTodasAgendas = this.agendaService.obtenerTodasAgendas(desde, hasta, 'VISIBLE');

        const petitions = forkJoin([obtenerTodasAgendas]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.events = [];
                    res[0].forEach((element: any) => {
                        this.events.push(
                            {
                                start: subDays(startOfDay(new Date(element.fecha_evento)), -1),
                                // end: addDays(new Date(element.fecha_evento), 1),
                                title: element.nombre_evento,
                                color: { ...colors.red },
                                allDay: true,
                                meta: {
                                    url_redireccion : element.url_redireccion
                                }
                            }
                        );
                    });


                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }



    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
        const getStart: any = {
            month: startOfMonth,
            week: startOfWeek,
            day: startOfDay,
        }[this.view];

        const getEnd: any = {
            month: endOfMonth,
            week: endOfWeek,
            day: endOfDay,
        }[this.view];
        this.loadData(format(getStart(this.viewDate), 'yyyy-MM-dd'), format(getEnd(this.viewDate), 'yyyy-MM-dd') );

    }

    hoy(){
        this.activeDayIsOpen = false;
        const getStart: any = {
            month: startOfMonth,
            week: startOfWeek,
            day: startOfDay,
        }[this.view];

        const getEnd: any = {
            month: endOfMonth,
            week: endOfWeek,
            day: endOfDay,
        }[this.view];
        this.loadData(format(getStart(new Date()), 'yyyy-MM-dd'), format(getEnd(new Date()), 'yyyy-MM-dd') );
    }

    setView(view: CalendarView) {
        this.view = view;
    }

}
