import { Component, OnInit } from '@angular/core';
import {GoogleAnalytics} from '../../../shared/google-analytics';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(
      private googleAnalytics: GoogleAnalytics

  ) { }

  ngOnInit(): void {
      this.googleAnalytics.eventEmitter('error', 'exitoso', '', 'click', 0);

  }

}
