import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class RiderService {

    // Observable refresh list data categorias
    flagRefresh = new BehaviorSubject<any>(null);
    observableData$ = this.flagRefresh.asObservable();

    constructor(private http: HttpClient) { }

    // actualizamos flag
    nextData(flag: boolean) {
        this.flagRefresh.next(flag);
    }


    crearRider(rider: any, paymentId: string) {
        rider.paymentId = paymentId;
        return this.http.post<any>(`${environment.api}/rider`, rider);
    }

    obtenerRider(riderId: number) {

        return this.http.get<any>(`${environment.api}/rider/${riderId}`);
    }

    obtenerRiderPorRut(rut: string) {

        return this.http.get<any>(`${environment.api}/rider/rut/${rut}`);
    }

    obtenerRidersPorRutYEvento(rut: string, id: number) {

        return this.http.get<any>(`${environment.api}/rider/rut/${rut}/evento/${id}`);
    }

    obtenerMejoresRidersPorSexo(sexo: string) {
        return this.http.get<any>(`${environment.api}/rider/mejores/${sexo}`);
    }

    obtenerTodosTeamsPorRider(usuarioId: number) {
        return this.http.get<any>(`${environment.api}/rider/teams/usuario/${usuarioId}`);
    }

    obtenerRiderPorEventoSimplify(eventoId: number) {
        return this.http.get<any>(`${environment.api}/rider/simplify/evento/${eventoId}`);
    }

    obtenerRiderPorEventoYEstadosSimplify(eventoId: number, estados: string) {
        return this.http.get<any>(`${environment.api}/rider/simplify/evento/${eventoId}/estado/${estados}`);
    }

    obtenerInscripciones(riderId: number) {
        return this.http.get<any>(`${environment.api}/rider/inscripcion/${riderId}`);
    }

    crearRiders(riderUno: any, riderDos: any, paymentId: string) {
        riderUno.paymentId = paymentId;
        riderDos.paymentId = paymentId;

        const riders = {
            riders: [
                riderUno,
                riderDos
            ]
        };

        return this.http.post<any>(`${environment.api}/rider/many`, riders);
    }

    obtenerDorsalesDisponibles(eventoId: number) {
        return this.http.get<any>(`${environment.api}/rider/dorsales-disponibles/evento/${eventoId}`);
    }



}
