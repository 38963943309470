import { Component, OnInit } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EventoService } from '../../../services/evento.service';
import { GoogleAnalytics } from '../../../shared/google-analytics';
import { MathHelper } from 'src/app/shared/math_helper';

@Component({
    selector: 'app-eventos-reserva-numeros',
    templateUrl: './eventos-reserva-numeros.component.html',
    styleUrls: ['./eventos-reserva-numeros.component.scss']
})
export class EventosReservaNumerosComponent implements OnInit {
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public eventos: any[] = [];


    constructor(
        private eventoService: EventoService,
        private googleAnalytics: GoogleAnalytics,
        private mathHelper: MathHelper,

    ) { }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('eventos-reserva-numeros', 'exitoso', '', 'click', 0);


        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventos = this.eventoService.obtenerEventosReservaPlacas();

        const petitions = forkJoin([obtenerEventos]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = res[0];



                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    obtenerMenorPrecio(evento: any, platform: string){
        return this.mathHelper.obtenerMenor(evento.categoriasRider, 'valor');
    }

}
