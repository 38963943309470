import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EncryptService} from '../../../shared/encrypt';
import {TransaccionService} from '../../../services/transaccion.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

    public loading = false;
    public dataUser = null;
    private compDestroy: Subject<boolean> = new Subject();
    public transacciones = [];
  constructor(private transaccionService: TransaccionService,
              private router: Router,
              private encryptService: EncryptService
  ) { }

  ngOnInit(): void {
      this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));

      this.getTransactions();
  }

    getTransactions(){
          this.loading = true;

          const petition = this.transaccionService.obtenerTransaccionesPorUsuario(this.dataUser.id);

          petition
              .pipe(take(1), takeUntil(this.compDestroy))
              .subscribe(
                  res => {
                      this.transacciones = [];
                      this.transacciones = res;
                      this.loading = false;

                  },
                  err => {
                      this.loading = false;
                  });

  }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();

        this.router.navigate(['/']);
    }
}
