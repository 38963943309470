<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Carro de compras</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Carro de compra</li>
            </ul>
        </div>
    </div>
</div>

<section class="cart-area ptb-100" #carro>
    <div class="container">
        <div class="cart-wrap">
            <table class="table">
                <thead class="thead">
                    <tr>
                        <th class="table-head" scope="col">Descripción</th>
                        <th class="table-head" scope="col">Categoría</th>
                        <th class="table-head" scope="col">Precio</th>
                        <th class="table-head" scope="col">Eliminar</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let transaccion of transacciones">
                        <td>Inscripción evento {{transaccion['evento']['nombre']}}</td>
                        <td>{{transaccion['categoria']['nombre']}} - {{transaccion['categoria']['modo']}}</td>
                        <td>
                            <span [ngClass]="{'con-descuento': transaccion['cupon'] !== null}">CLP${{transaccion['monto']}}</span>
                            <span [ngClass]="{'precio-final': transaccion['cupon'] !== null}" *ngIf="transaccion['cupon'] !== null"> - CLP${{calculoDescuento(transaccion['monto'],transaccion['cupon']['porcentaje_descuento'] )}}</span>
                        </td>
                        <td>
                            <span (click)="eliminarTransaccion(transaccion)" class="delete-notebook btn cmn-btn"><i class='bx bx-trash'></i> Eliminar</span>
                            <span (click)="eliminarTransaccion(transaccion)" class="delete-smartphone"><i class='bx bx-trash'></i> Eliminar</span>
                        </td>
                    </tr>

                    <tr *ngFor="let adicional of adicionales; let i = index">
                        <td>Producto adicional: {{adicional['eventoAdicional']['adicional']['nombre']}}</td>
                        <td>-</td>
                        <td>
                            <span>CLP${{adicional['eventoAdicional']['precio']}}</span>
                        </td>
                        <td>
                            <span (click)="eliminarAdicional(i)" class="delete-notebook btn cmn-btn"><i class='bx bx-trash'></i> Eliminar</span>
                            <span (click)="eliminarAdicional(i)" class="delete-smartphone"><i class='bx bx-trash'></i> Eliminar</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="shop-back">

                <a routerLink="/eventos">¿Quieres ir a ver más eventos?</a>
            </div>

            <div class="total-shopping">
                <h2>Total Compra</h2>
                <h3>Subtotal:
                    <strong>CLP${{totalCompra}}</strong>
                </h3>
                <h3>Costos por servicios y transacción :
                    <strong>CLP${{gastos()}}</strong>
                </h3>

                <h3>Total: <strong>CLP${{total()}} 
                    <span style="margin-left: initial !important; color: grey" *ngIf="loading">(Buscando el precio del dolar)</span> 
                    <span style="margin-left: initial !important; color: grey" *ngIf="!loading && !errorDolar">(USD${{calculoTotalDolar()}})</span>
                    <span style="margin-left: initial !important; color: grey" *ngIf="!loading && errorDolar">(USD$ S/I)</span>
                </strong> 
            </h3>

                <br>
                <h2>Escoge el medio de pago:</h2>
                <!-- <br> -->
                <!-- <span style="color:red">(*) Para realizar el pago tarjetas no emitidas en Chile, debes pagar mediante Paypal y se efectuará con la moneda USD.</span> -->
                <!-- <br> -->
                <br>

                <ng-container *ngFor="let medioPago of mediosPago">
                    <button *ngIf="medioPago.nombre === 'WEBPAY'" (click)="iniciarTransaccionWebpay()"
                        class="btn cmn-btn payment">
                        <div class="name-top" *ngIf="medioPago.pago_nacional === 'ACTIVADO'"><img src="assets/img/chile.png"
                            style="width: 17px;height: 17px;"> Pago nacional</div>
                            <div class="name-top-wt" *ngIf="medioPago.pago_nacional === 'DESACTIVADO'"></div>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/webpay-plus.png?alt=media&token=04424dbe-5609-4be2-806e-1866008ea37c">
                        
                        <div class="name-bottom" *ngIf="medioPago.pago_internacional === 'ACTIVADO'"><img src="assets/img/mundo.png"
                            style="width: 17px;height: 17px;"> Pago internacional</div>
                            <div class="name-bottom-wt" *ngIf="medioPago.pago_internacional === 'DESACTIVADO'"></div>
 

                    </button>
                    <button *ngIf="medioPago.nombre === 'TRANSFERENCIA'" (click)="iniciarTransaccionTransferencia()"
                        class="btn cmn-btn payment">
                        <div class="name-top" *ngIf="medioPago.pago_nacional === 'ACTIVADO'"><img src="assets/img/chile.png"
                            style="width: 17px;height: 17px;"> Pago nacional</div>
                            <div class="name-top-wt" *ngIf="medioPago.pago_nacional === 'DESACTIVADO'"></div>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/transferencia.png?alt=media&token=68d9b1ab-b559-479a-8ded-c2f891d9cbb9">
                        
                            <div class="name-bottom" *ngIf="medioPago.pago_internacional === 'ACTIVADO'"><img src="assets/img/mundo.png"
                                style="width: 17px;height: 17px;"> Pago internacional</div>
                                <div class="name-bottom-wt" *ngIf="medioPago.pago_internacional === 'DESACTIVADO'"></div></button>

                    <button *ngIf="medioPago.nombre === 'KHIPU'" (click)="iniciarTransaccionKhipu()"
                        class="btn cmn-btn payment">
                        <div class="name-top" *ngIf="medioPago.pago_nacional === 'ACTIVADO'"><img src="assets/img/chile.png"
                            style="width: 17px;height: 17px;"> Pago nacional</div>
                            <div class="name-top-wt" *ngIf="medioPago.pago_nacional === 'DESACTIVADO'"></div>
                        <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2021/110x50-purple.png">
                        
                        <div class="name-bottom" *ngIf="medioPago.pago_internacional === 'ACTIVADO'"><img src="assets/img/mundo.png"
                            style="width: 17px;height: 17px;"> Pago internacional</div>
                            <div class="name-bottom-wt" *ngIf="medioPago.pago_internacional === 'DESACTIVADO'"></div>
                    </button>
                    <button *ngIf="medioPago.nombre === 'MERCADOPAGO'" (click)="iniciarTransaccion()"
                        class="btn cmn-btn payment">
                        <div class="name-top" *ngIf="medioPago.pago_nacional === 'ACTIVADO'"><img src="assets/img/chile.png"
                            style="width: 17px;height: 17px;"> Pago nacional</div>
                            <div class="name-top-wt" *ngIf="medioPago.pago_nacional === 'DESACTIVADO'"></div>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/mercadopago.png?alt=media&token=875507df-fadd-410b-b819-8c61cee2e50e">
                        
                            <div class="name-bottom" *ngIf="medioPago.pago_internacional === 'ACTIVADO'"><img src="assets/img/mundo.png"
                                style="width: 17px;height: 17px;"> Pago internacional</div>
                                <div class="name-bottom-wt" *ngIf="medioPago.pago_internacional === 'DESACTIVADO'"></div> </button>
                    <button *ngIf="medioPago.nombre === 'PAYPAL'" (click)="iniciarTransaccionPaypal()"
                        class="btn cmn-btn payment">
                        <div class="name-top" *ngIf="medioPago.pago_nacional === 'ACTIVADO'"><img src="assets/img/chile.png"
                            style="width: 17px;height: 17px;"> Pago nacional</div>
                            <div class="name-top-wt" *ngIf="medioPago.pago_nacional === 'DESACTIVADO'"></div>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/paypal.png?alt=media&token=45e001cd-aad7-4d1d-8441-7d0e01b4aa1b">
                        
                            <div class="name-bottom" *ngIf="medioPago.pago_internacional === 'ACTIVADO'"><img src="assets/img/mundo.png"
                                style="width: 17px;height: 17px;"> Pago internacional</div>
                                <div class="name-bottom-wt" *ngIf="medioPago.pago_internacional === 'DESACTIVADO'"></div>
                    </button>
                </ng-container>

                

            </div>
            <div style="margin-top:40px">

                <ngx-paypal [config]="payPalConfig"><h2>Selecciona el medio de pago:</h2>
                    <br></ngx-paypal>
            </div>
            <div style="margin-top:15px">
                <ng-adsense
                    [adClient]="getEnvironmentAdSense()"
                    [adSlot]="2041812580"
                ></ng-adsense>
            </div>

        </div>
    </div>
</section>


<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>
