import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CarroService } from "../../../services/carro.service";
import { EventoService } from '../../../services/evento.service';
import { LocalStorageService } from '../../../shared/storage';
import { StringHelper } from "../../../shared/string_helper";

@Component({
    selector: 'app-adicional',
    templateUrl: './adicional.component.html',
    styleUrls: ['./adicional.component.scss']
})
export class AdicionalComponent implements OnInit {

    public id = 0;
    public adicional = 0;
    public loading = false;
    public loadingInfoInicial = false;
    public eventoAdicional: any = null;
    public evento: any = null;
    private compDestroy: Subject<boolean> = new Subject();
    public formInscripcion: FormGroup ;



    constructor(private activatedroute: ActivatedRoute,
                private eventoService: EventoService,
                private fb: FormBuilder,
                private stringHelper: StringHelper,
                private router: Router,
                private localStorageAs: LocalStorageService,
                private carroService: CarroService,
    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
            this.adicional = Number(params.adicional);
        });
    }

    ngOnInit(): void {
        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getEvento();
                    }
                });


        this.getEvento();


    }

    getEvento() {
        this.loadingInfoInicial = true;
        const obtenerEventoById = this.eventoService.obtenerEventoById(this.id);


        const petitions = forkJoin([obtenerEventoById]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.evento= res[0];


                    this.loadingInfoInicial = false;


                },
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }


    initTransaccion(adicionalForm: any) {
        this.loading = true;
        const compra: any = {
            adicionales: [
                adicionalForm
            ],
            transacciones : []
        };

        let adicional: any[] = [];
        let transaccion: any[] = [];
        if ( JSON.parse(this.localStorageAs.get('carro_compra')) !== null){
            adicional = JSON.parse(this.localStorageAs.get('carro_compra')).adicionales === undefined ? [] : JSON.parse(this.localStorageAs.get('carro_compra')).adicionales ;
            transaccion = JSON.parse(this.localStorageAs.get('carro_compra')).transacciones === undefined ? [] : JSON.parse(this.localStorageAs.get('carro_compra')).transacciones;

        }
        compra.adicionales = compra.adicionales.concat(adicional);
        compra.transacciones = compra.transacciones.concat(transaccion);
        this.localStorageAs.set('carro_compra', JSON.stringify(compra));


        this.loading = false;

        this.router.navigate(['/carro']);
    }
}
