import {Component, OnInit, TemplateRef} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {EventoService} from '../../../services/evento.service';
import {ActivatedRoute} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {GoogleAnalytics} from '../../../shared/google-analytics';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-event-adicionales',
  templateUrl: './event-adicionales.component.html',
  styleUrls: ['./event-adicionales.component.scss']
})
export class EventAdicionalesComponent implements OnInit {
    public id = 0;
    public evento: any;
    public adicionales = [] ;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;

    constructor(
        private activatedroute: ActivatedRoute,
        private eventoService: EventoService,
        private googleAnalytics: GoogleAnalytics,
        private datePipe: DatePipe,


    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

  ngOnInit(): void {
      this.googleAnalytics.eventEmitter('evento-adicionales', 'exitoso', '', 'click', 0);

      this.eventoService.observableData$
          .subscribe(
              res => {
                  if (res) {
                      this.getDataDefault();
                  }
              });

      this.getDataDefault();
  }

    getDataDefault() {
        this.loading = true;
        const obtenerEvento = this.eventoService.obtenerEventoById(this.id);
        const obtenerAdicionalesByEventoId = this.eventoService.obtenerAdicionalesByEventoId(this.id);

        const petitions = forkJoin([obtenerEvento, obtenerAdicionalesByEventoId]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.evento = res[0];
                    this.adicionales = res[1].filter((adicional) => adicional.cupos > adicional.vendidos);
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }
}
