import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

@Injectable()
export class StringHelper {
    constructor() { }

    capitalizarPrimeraLetra(palabra: string) {
        return palabra.charAt(0).toUpperCase() + palabra.slice(1);
    }



}
