<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Términos y condiciones</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Términos y condiciones</li>
            </ul>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <h1>Términos y Condiciones Generales de uso</h1>
        <div class="privacy-item">
            <h2>TÉRMINOS LEGALES Y CONDICIONES GENERALES DE USO DEL SITIO WEB RIDERTICKET.CL</h2>
        </div>

        <div class="privacy-item">
            <h2>PRIMERO: GENERALIDADES.</h2>
            <p>El presente documento, elaborado con fecha 19 de febrero de 2022, regula los términos y condiciones generales aplicables al acceso y uso que el Usuario realizará del Sitio Web: RIDERTICKET.CL (en adelante "RIDERTICKET", "RIDERTICKET.CL" o "el Sitio Web" indistintamente), así como a cualquier tipo de información, contenido, imagen, audio u otro material comunicado o presente en el Sitio Web.</p>
            <p>Para efectos del presente documento se entenderá como "Sitio Web": la apariencia externa de los interfaces de pantalla, tanto de forma estática como de forma dinámica, es decir, el árbol de la navegación; y todos los elementos integrados tanto en los interfaces de pantalla como en el árbol de navegación (en adelante, "Contenidos") y todos aquellos servicios o recuerdos en línea que en su caso ofrezca a los usuarios (en adelante, "Servicios").</p>
            <p>RIDERTICKET se reserva la facultad de modificar, en cualquier momento, y sin previo aviso, la presentación y configuración del Sitio Web y de los Contenidos y Servicios que en él pudieran estar incorporados. El Usuario reconoce y acepta que en cualquier momento RIDERTICKET pueda interrumpir, desactivar y/o cancelar cualquiera de estos elementos que se integran en el Sitio Web o el acceso a los mismos.</p>
            <p>El acceso y uso del sitio web y del contenido relacionado o anexo, descrito en el presente documento, se regirá íntegramente por las leyes de la República de Chile. Por consiguiente, las visitas, así como también las posibles transacciones que el usuario realice en la plataforma que representa el sitio web, así como los efectos jurídicos que éstas pudieran tener, quedan sometidos a las leyes y a la jurisdicción de los tribunales de justicia de la República de Chile. De particular importancia resultan la aplicación de la Ley N° 19.628 de Protección de Datos Personales y la Ley N° 19.496 sobre Derechos del Consumidor.</p>
            <p>Adicionalmente, este sitio web y sus contenidos, así como también las transacciones que se pudieran llevar a cabo en él, están destinados únicamente a personas con residencia o domiciliadas en Chile, sea que éstas sean chilenos o extranjeros.</p>
            <p>Por lo mismo, RIDERTICKET no puede asegurar a personas que no cumplan con los requisitos previamente descritos, que el Sitio Web vaya a cumplir, total o parcialmente, con legislaciones extranjeras. Así, RIDERTICKET no se hace responsable en aquellos casos en que Usuarios extranjeros, sin residencia ni domicilio en Chile, tengan acceso o procedan a navegar en el Sitio Web.</p>
            <p>Se recomienda al usuario leer detenidamente el contenido de estos Términos y Condiciones Generales de Uso, así como imprimir y conservar una copia de éstas en una unidad de disco local de almacenamiento, o de almacenamiento portátil, para su conveniencia y seguridad.</p>
        </div>

        <div class="privacy-item">
            <h2>SEGUNDO.- INFORMACIÓN DEL SITIO WEB RIDERTICKET.CL.</h2>
            <p>La titularidad de RIDERTICKET corresponde a FELIPE ANDRÉS SOTOMAYOR CONTRERAS, Cédula Nacional de Identidad número: 19.072.350-K.</p>
            <p>Adicionalmente, para efectos de cualquier tipo de comunicación, presentación, consulta o reclamo relacionados con el uso o funcionamiento del Sitio Web, o con los contratos y transacciones que se hubieran llevado a cabo, RIDERTICKET procede a designar, por medio del presente acto, como representante especial a don MANUEL ALEJANDRO GONZÁLEZ VILLARREAL, ostentando el cargo de Gerente RR.HH, quien recibirá todo tipo de comunicaciones al correo electrónico: hola@riderticket.cl, el cual sólo estará disponible para efectos de funciones de Servicio al Cliente. </p>
            <p>El Sitio Web también cuenta con un servicio de atención al cliente, ante el cual se pueden hacer valer distintas presentaciones o reclamos relacionados con el uso o acceso a la página, así como también con las transacciones realizados en el sitio web, el cual puede ser contactado a la dirección de correo electrónico hola@riderticket.cl.</p>
        </div>

        <div class="privacy-item">
            <h2>TERCERO.- USUARIO.</h2>
            <p>
                El acceso a este Sitio Web, la navegación y uso del mismo, así como cualquier espacio habilitado para interacciones entre Usuarios, o entre éste y el Sitio Web (secciones de comentarios, espacios de blogging y micro blogging, y extensiones destinadas a esta función), conferirán a las personas la condición de "Usuario". Por lo tanto, se entienden aceptar los Términos y Condiciones Presentes en este documento desde el momento de acceder al Sitio Web o a sus contenidos, así como también todo tipo de futuras modificaciones que se hicieran a los Términos y Condiciones, sin perjuicio de lo que pudiera establecer futura legislación dictada en la República de Chile destinada a regular esta clase de documentos, o que fuera aplicable al uso de Sitios Web.
            </p>
            <p>Adicionalmente, el acceso al Sitio Web por parte del Usuario tendrá un carácter libre y gratuito, sin que éste tenga que pagar por acceder al sitio o sus contenidos, más allá del costo de la conexión a internet, en los casos que correspondiera.</p>
            <p><strong>Registro del Usuario:</strong></p>
            <p>
                Para el usuario organizador será requisito necesario para la publicación de eventos en el Sitio Web, no así, para realizar transacciones y contratar servicios ofrecidos por esta vía, la aceptación de los presentes Términos y Condiciones al momento del registro por parte del Usuario. Adicionalmente, al usuario organizador se le asignará una clave o contraseña de acceso.
            </p>
            <p>Se entenderán conocidos y aceptados estos Términos y Condiciones por el sólo hecho de registrarse el Usuario, acto en el cual se incluirá una manifestación expresa del Usuario sobre el conocimiento de las presentes condiciones de uso.</p>
            <p><strong>Clave Secreta:</strong></p>
            <p>Una vez registrado, el Usuario tendrá a su disposición un nombre de usuario y una contraseña o clave secreta, por medio de los cuales no sólo podrá ingresar al Sitio Web y tener acceso a sus contenidos, sino también un acceso personalizado, confidencial y seguro.</p>
            <p>El Usuario asume totalmente su responsabilidad por el mantenimiento de la confidencialidad de su contraseña o clave secreta registrada en el Sitio Web. Dicha contraseña o clave es de uso personal, por lo que su entrega voluntaria a terceros por parte del Usuario, no acarrea ningún tipo de responsabilidad por parte de RIDERTICKET, ni de sus afiliados y representantes, en caso de uso malicioso.</p>
            <p><strong>Responsabilidad de los Usuarios:</strong></p>
            <p>RIDERTICKET entrega al Usuario un servicio caracterizado por la diversidad del contenido proporcionado. El Usuario asume su responsabilidad al ingresar al Sitio Web, para realizar un correcto uso del mismo y sus contenidos. Así, esta responsabilidad se extenderá, de forma no taxativa, a:</p>
            <p>a.- Usar la información, Contenidos y/o Servicios y datos ofrecidos por RIDERTICKET, sin que sea contrario a estos Términos y Condiciones, así como al Ordenamiento Jurídico Chileno y a la Moral y el Orden Público, o que de cualquier otra forma pudieran acarrear la vulneración de derechos de terceros, o el funcionamiento y operaciones normales del Sitio Web.</p>
            <p>b.- La veracidad y licitud de los datos e información aportados por el Usuario en los formularios de registro presentes en el Sitio Web. En todo caso, el Usuario notificará de forma inmediata a RIDERTICKET acerca de cualquier hecho relacionado con el uso indebido de información registrada en dichos formularios, tales como, pero no sólo, robo, extravío, o el acceso no autorizado a identificadores y/o contraseñas, con el fin de proceder a su inmediata cancelación.</p>
            <p>RIDERTICKET se reserva el derecho de retirar todos aquellos comentarios e interacciones emanados de Usuarios que vulneren la ley, el respeto a la dignidad a la persona, que sean discriminatorios en base a raza, nacionalidad, sexo, orientación sexual, estirpe o condición, así como también aquellos que contengan contenido pornográfico o que constituyan mensajes conocidos como "spam", o que atenten contra la juventud o infancia, el orden, o la seguridad pública o que, a juicio de RIDERTICKET, no resulten apropiados para el Sitio Web.</p>
            <p>De todas maneras, RIDERTICKET no se hace responsable de las opiniones, de cualquier tipo, emitidas por los Usuarios mediante comentarios u herramientas o extensiones de blogging o microblogging.</p>
            <p>El simple acceso a RIDERTICKET.CL no supone una relación comercial entre RIDERTICKET y el Usuario.</p>
            <p><strong>Capacidad Legal:</strong></p>
            <p>El Sitio Web no requiere una edad mínima para poder acceder a su Contenido y Servicios; por lo tanto, estos Términos y Condiciones se aplicarán indistintamente a todos los Usuarios del Sitio Web que cumplan con lo estipulado en este documento.</p>
            <p>Aun así, las reglas legales de capacidad establecidas en el ordenamiento jurídico regirán en todos aquellos casos donde su aplicación sea pertinente. Por tanto, si una persona no tiene capacidad legal para contratar, debe abstenerse de utilizar los Contenidos y Servicios que requieran de esta capacidad. RIDERTICKET podrá, en cualquier momento, en forma temporal o definitiva, suspender la participación de usuarios sobre los cuales se compruebe que carecen de capacidad legal para usar de los Servicios y Contenido que la requieran, o que proporcionen información falsa, inexacta o fraudulenta al Sitio Web.</p>
            <p>El Sitio Web RIDERTICKET.CL está dirigido principalmente a Usuarios residentes en la República de Chile. RIDERTICKET no asegura que el Sitio Web cumpla, parcial o totalmente, con legislaciones de otros países. Si el Usuario reside o está domiciliado en un país extranjero, y decide acceder y/o navegar en este Sitio Web, lo hará bajo su propia responsabilidad, asegurándose de que tal acceso y navegación cumpla con lo dispuesto en la legislación local que le sea aplicable, no asumiendo RIDERTICKET responsabilidad alguna que pueda derivar de dicho acceso.</p>
        </div>

        <div class="privacy-item">
            <h2>CUARTO.- DERECHOS Y GARANTÍAS DEL USUARIO DEL SITIO.</h2>
            <p>El Usuario gozará de todos los derechos que le reconoce la legislación sobre protección al consumidor vigente en Chile, adicionalmente a los derechos que le otorgan estos Términos y Condiciones. Además, el Usuario dispondrá en todo momento de los derechos de información, rectificación y cancelación de los datos personales conforme a la Ley N° 19.628 sobre protección de datos personales.</p>
            <p><strong>Derecho de Retracto:</strong></p>
            <p>El Usuario tendrá un plazo de 24 horas para retractarse del uso del servicio desde que recibe sus credenciales, o desde que solicite el Servicio, antes de que éste sea prestado o tenga acceso a un Contenido.</p>
            <p>El ejercicio de este derecho debe utilizar los mismos medios empleados para celebrar la prestación, y siempre que RIDERTICKET hubiera enviado comunicación al Usuario, informando el perfeccionamiento del servicio y entregando una copia del mismo, así como acceso claro e inequívoco de los Términos y Condiciones Generales, así como la posibilidad de conservar o imprimir dichos documentos.</p>
            <p>En caso que RIDERTICKET no hubiera dado cumplimiento íntegro a lo establecido en los párrafos anteriores de este acápite, el plazo con el que cuenta el Usuario para terminar la prestación, 48 horas.</p>
            <p>Este derecho no podrá ejercerse cuando el bien, materia del contrato, se haya deteriorado por hecho imputable al Usuario.</p>
            <p>Si el bien o Servicio se obtuvo por medio de un crédito, éste quedará sin efecto, pero los intereses serán de cargo del Usuario cuando se hubiera otorgado por un tercero.</p>
            <p>RIDERTICKET estará obligado a devolver las sumas abonadas, con retención de gastos del 15%, a la mayor brevedad posible y, en cualquier caso, antes de 24 horas a la comunicación del retracto. </p>

        </div>

        <div class="privacy-item">
            <h2>QUINTO.- CONTRATACIÓN.</h2>
            <p>Para realizar compras o contratar servicios en el Sitio Web, se deben seguir los siguientes pasos, haciendo click cuando corresponda:</p>
            <p>a.- Seleccionar el producto o servicio que interesa, ingresar datos de usuario y dar clic en "continuar", u alguna opción similar con nombres parecidos ("ir a pago", "siguiente", por nombrar algunos).</p>
            <p>b.- Iniciar sesión en el sitio o ingresar correo electrónico y contraseña. En caso de no estar registrado y si se desea hacerlo, haga uso del ícono y menú que corresponda a esta opción.</p>
            <p>c.- Seleccionar el medio de pago.</p>
            <p>d.- Una vez que se ha realizado la orden de compra o de contratación de servicio, se desplegará en la pantalla la siguiente información, la cual debe aparecer antes de la selección del medio de pago:</p>
            <p>I. Descripción del producto adquirido o servicio contratado.</p>
            <p>II. Precio del producto o servicio.</p>
            <p>III. Indicación del costo adicional, cuando corresponda.</p>
            <p>IV. Medio de pago que escogerá el usuario.</p>
            <p>V. Valor total de la operación.</p>
            <p>VI. Otras condiciones de la orden de producto o servicio adquirido.</p>
            <p>VII. Posibilidad de imprimir y conservar la orden, por parte del Usuario.</p>
            <p>e.- Envío de la información al correo electrónico registrado por el Usuario.</p>
            <p>f.- La orden luego será enviada de forma automática a un proceso de confirmación de identidad, en el cual se resguardará siempre la seguridad y privacidad del usuario y del proceso mismo de contratación, disponibilidad, vigencia y cupo del medio de pago que se haya seleccionado.</p>
            <p>g.- No se verá afectado el comprador en sus derechos pero se le efectuarán cargos por servicio y comisiones</p>
        </div>

        <div class="privacy-item">
            <h2>SEXTO.- MEDIOS DE PAGO.</h2>
            <p>A menos que se indique un medio distinto para casos u ofertas específicos, los productos y servicios que se informan y transan en este sitio sólo podrán ser pagados por medio de:</p>
            <p>a.- Tarjeta de crédito bancarias Visa, Mastercard, Dinners Club International, American Express u otras habilitadas y válidamente emitidas en Chile o en el extranjero, siempre que mantengan un contacto vigente para este efecto con RIDERTICKET.</p>
            <p>b.- Tarjetas de débito bancarias acogidas al sistema Redcompra o habilitadas para realizar pagos y transacciones a través del sistema WebPay, válidamente emitidas en Chile por bancos nacionales, que mantengan un contrato vigente para tales efectos con RIDERTICKET.</p>
            <p>Otros medios de pago que pudieran ser anunciados y aceptados a futuro, los cuales serán informados pertinentemente por los canales y conductos que correspondan.</p>
            <p>El pago con tarjetas de débito se realizará a través de WebPay, que es un sistema de pago electrónico que se encarga de hacer el cargo automático a la cuenta bancaria del usuario.</p>
            <p>El Usuario declara que entiende que estos medios o portales de pago son de propiedad de terceras empresas o personas proveedoras de dichos servicios, independientes y no vinculadas a RIDERTICKET, por lo que la continuidad de su prestación de servicios en el tiempo, así como el correcto funcionamiento de sus herramientas y botones de pago en línea, son de exclusiva responsabilidad de las proveedoras de estos servicios y en ningún caso de RIDERTICKET.</p>


        </div>

        <div class="privacy-item">
            <h2>SÉPTIMO.- RESPONSABILIDAD.</h2>
            <p>RIDERTICKET no responderá, en ningún caso, por los siguientes hechos:</p>
            <p>a.- Uso indebido que Usuarios o visitantes al Sitio Web realicen del contenido almacenado, o de los productos que adquirieran en el mismo, así como de los derechos de propiedad industrial o intelectual contenidos en el Sitio Web, o en los productos o servicios.</p>
            <p>b.- Daños y perjuicios, concretos o eventuales, causados a Usuarios derivados del funcionamiento de las herramientas de búsqueda del Sitio Web, así como de errores generados por elementos técnicos del Sitio o del motor de búsqueda.</p>
            <p>c.- Contenido de Sitios Web a las que los Usuarios puedan acceder con o sin autorización de RIDERTICKET.</p>
            <p>d.- Pérdida, mal uso o uso no autorizado de contraseña o códigos de validación, sea por parte del Usuario o de un tercero, de la forma expresada en estos Términos y Condiciones. De la misma forma, las partes reconocen y dejan constancia que el soporte computacional entregado por RIDERTICKET.CL no es infalible, por lo tanto, pueden verificarse circunstancias ajenas a la voluntad de RIDERTICKET que puedan causar que el Sitio Web o su plataforma no se encuentren operativos durante un cierto período de tiempo.</p>
            <p>e.- Información referida a RIDERTICKET que se encuentre en Sitios Web distintos o ajenos a RIDERTICKET.CL.</p>
            <p>En caso de estar ante alguno de estos supuestos, RIDERTICKET tomará todas las medidas para reestablecer el correcto funcionamiento del Sitio Web y de su sistema comunicacional lo más pronto posible, sin que pudiera imputársele algún tipo de responsabilidad por aquello.</p>
            <p>RIDERTICKET no asegura disponibilidad ni continuidad de funcionamiento del Sitio Web, y tampoco que, en cualquier momento, los Usuarios puedan acceder a él a las promociones y ofertas que estuvieran disponibles.</p>
            <p>RIDERTICKET tampoco es responsable por la existencia de virus u otros elementos perjudiciales en los documentos o archivos almacenados en los sistemas informáticos de todo tipo de propiedad de los Usuarios. RIDERTICKET no responderá de perjuicios causados al Usuario, derivados del uso indebido de las tecnologías y plataformas puestas a su disposición, cualquiera sea la forma en que se utilicen inadecuadamente estos elementos tecnológicos. Asimismo, RIDERTICKET no responderá por daños producidos por el uso indebido o mala fe de los Usuarios al utilizar RIDERTICKET.CL.</p>
            <p>No obstante, en el caso de producirse un doble pago por algún Usuario en el Sitio Web, RIDERTICKET realizará la devolución del 85% de la suma del sobrepago, dentro de los 7 días siguientes a la recepción del correspondiente reclamo realizado por el Usuario o sus representantes, el cual debe contar con un anexo que incluya los comprobantes de pago originales correspondientes al sobrepago realizado.</p>
            <p>Al momento de la realización de pagos y para comunicarse, RIDERTICKET.CL utiliza certificados digitales de seguridad (SSL), con el fin de encriptar la información. RIDERTICKET no almacena ni conserva datos financieros de sus Usuarios.</p>

        </div>

        <div class="privacy-item">
            <h2>OCTAVO.- POLÍTICA DE SEGURIDAD DE DATOS.</h2>
            <p>RIDERTICKET adoptará todas las medidas necesarias para resguardar los datos de sus Usuarios y clave secreta, como sistemas de encriptado de información, certificados de seguridad y otros semejantes o equivalentes que RIDERTICKET considere prudentes para estos efectos. En caso de realizarse cambios hechos por el Usuario en la información registrada o ingresada en el Sitio Web, o bien, si se produce la detección de cualquier tipo de irregularidad atribuible al Usuario relacionada con la información entregada o con transferencias realizadas por el Usuario, así como con todo lo relacionado a la identificación en las mismas o el uso de medios de pago o simplemente como medida de protección de identidad, el personal del Sitio Web se pondrá en contacto con el Usuario, sea por vía telefónica o por correo electrónico, para corroborar los datos y la información, así como para evitar la existencia de posibles fraudes.</p>
            <p>En la eventualidad de no establecerse contacto en un plazo de 12 horas, y pensando en la seguridad del Usuario, cualquier compra de un producto o contratación de un servicio no será confirmada. Se informará al Usuario por vía telefónica o por correo electrónico el hecho de haber quedado sin efectos producto de no haber podido comprobarse su identidad o medio de pago utilizado. Adicionalmente, los comprobantes en que consten las gestiones realizadas para intentar contactarse con el Usuario con el objetivo de confirmar la transacción, quedarán a disposición de este último por un plazo de 1 días, en caso que el Usuario quisiera confirmar la compra o contratación. Consultas adicionales pueden realizarse por las vías de contacto con el Sitio Web establecidas en el párrafo relativo a la Información del Sitio Web, presente en este mismo documento de Términos y Condiciones.</p>
            <p>Con todo lo anterior, los Usuarios son plenamente responsables por el extravío o pérdida, mal uso o uso no autorizado que se diera del producto o servicio adquirido o contratado, sea que incurran ellos o terceros en los hechos descritos previamente, luego de realizada una compra o contratación de servicio siguiendo la forma estipulada en los presentes Términos y Condiciones.</p>
            <p><strong>Datos Personales:</strong></p>
            <p>Al acceder al Sitio Web RIDERTICKET.CL, los Usuarios o visitantes garantizan que la información que proporcionan para ingresar o para la compra de inscripción o contratación de servicios es veraz, completa, exacta y actualizada.</p>
            <p>De acuerdo a lo establecido en la Ley N° 19.628 de Protección de Datos Personales, los datos de este tipo que se suministren al Sitio Web, pasan a formar parte de una base de datos perteneciente a RIDERTICKET y serán destinados única y exclusivamente para los fines que motivaron su entrega, especialmente para la comunicación entre RIDERTICKET y sus Usuarios, validar datos relativos a compras o contratación de servicios, concretar despachos, y responder consultas. Estos datos nunca serán comunicados o compartidos con otras empresas sin expresa autorización de su titular (el Usuario) ni serán transferidos internacionalmente.</p>
            <p>RIDERTICKET jamás solicitará a sus Usuarios la entrega de datos personales o financieros a través de correos electrónicos.</p>
            <p>RIDERTICKET presume que los datos incorporados por los Usuarios o por personas autorizadas por éstos son correctos y exactos. Los Usuarios, al aceptar los presentes Términos y Condiciones, manifiestan su conformidad con que los datos personales aportados al rellenar formularios presentes en RIDERTICKET.CL puedan ser utilizados posteriormente para la elaboración y envío de ofertas que puedan diferir de las ofrecidas en el Sitio Web.</p>
            <p>Sin perjuicio de lo anterior, RIDERTICKET garantiza a todos sus Usuarios el libre ejercicio de los derechos contemplados en la Ley N° 19.628 de Protección de Datos Personales en lo relativo a la información, modificación, cancelación y bloqueo de sus datos personales. En consecuencia, los Usuarios podrán realizar requerimientos relacionados con los ya mencionados derechos, los que serán respondidos por RIDERTICKET en un plazo no superior a dos días corridos.</p>
            <p><strong>Documentos Electrónicos:</strong></p>
            <p>Los Usuarios, como receptores manuales de documentos electrónicos según lo establecido en la Resolución Exenta N° 11 del 14 de febrero de 2003, dictada por el Servicio De Impuestos Internos, la cual establece el procedimiento para que contribuyentes autorizados para emitir documentos electrónicos puedan también realizar su envío por estos mismos medios a los receptores manuales; declaran y aceptan que, al aprobar estos Términos y Condiciones, autorizan a RIDERTICKET, representada por FELIPE ANDRÉS SOTOMAYOR CONTRERAS, Cédula Nacional de Identidad número: 19.072.350-K, para que el documento tributario correspondiente a la transacción por compra de producto o contratación de servicio, le sea entregada únicamente a través de un medio electrónico. Igualmente, se autoriza que el aviso de publicación del documento tributario sea enviado a los Usuarios por correo electrónico.</p>
        </div>

        <div class="privacy-item">
            <h2>NOVENO.- PROPIEDAD INTELECTUAL Y DERECHOS RESERVADOS.</h2>
            <p>Todos los derechos presentes en el Sitio Web, incluyendo la propiedad intelectual, respecto al mismo Sitio Web, páginas y otro tipo de contenido, como podría ser, de forma no taxativa, textos de todo tipo, material gráfico o audiovisual (incluyendo, pero no limitado a imágenes), logos y logotipos, íconos de todo tipo, material y contenido descargable, recopilaciones de datos e información y códigos fuente de la página, son de propiedad de RIDERTICKET.</p>
            <p>Asimismo, todo o parte del contenido mencionado en el párrafo anterior, se encuentra registrado como marca comercial, creación o imagen comercial, por lo tanto, los derechos son de propiedad de RIDERTICKET, y se prohíbe su uso indebido y reproducción con fines comerciales sin previa autorización, así como también se prohíbe su uso de cualquier forma que pudiera desprestigiar o afectar negativamente a RIDERTICKET.</p>
            <p>Cualquier otra marca comercial que tuviera presencia en el Sitio Web y cuyos derechos no sean de propiedad de RIDERTICKET, pertenecerá a sus debidos dueños.</p>
        </div>



<!--        <div class="privacy-item">-->
<!--            <h2>3.How we do manage everything</h2>-->

<!--            <ul>-->
<!--                <li><i class='bx bx-chevron-right'></i> Our chefs are very dedicated on work</li>-->
<!--                <li><i class='bx bx-chevron-right'></i> Our restaurant always gives best services</li>-->
<!--                <li><i class='bx bx-chevron-right'></i> We always give qualityful food</li>-->
<!--                <li><i class='bx bx-chevron-right'></i> You can get everything under a roof</li>-->
<!--                <li><i class='bx bx-chevron-right'></i> We have a option to give online service</li>-->
<!--            </ul>-->
<!--        </div>-->
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
