import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import Swal from 'sweetalert2';
import {LocalStorageService} from '../../../shared/storage';
import {Router} from '@angular/router';
import {TransaccionService} from '../../../services/transaccion.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject, forkJoin} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {CurrencyService} from '../../../services/currency.service';
import {CarroService} from "../../../services/carro.service";
import { MediosPagoService } from 'src/app/services/medios-pago.service';

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, AfterViewInit  {

    precioDolar: any;
    carroDeCompra: any;
    transacciones: any[] = [];
    adicionales: any[] = [];
    totalCompra = 0;
    totalCargos = 0;
    tokenWs = '';
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    @ViewChild('carro') el: ElementRef;
    public payPalConfig?: IPayPalConfig;
    public errorDolar = false;
    mediosPago: any[] = [];

    constructor(private localStorageAs: LocalStorageService,
                private router: Router,
                private transaccionService: TransaccionService,
                private currencyService: CurrencyService,
                private carroService: CarroService,
                private mediosPagoService: MediosPagoService
    ) { }

    ngOnInit(): void {

        this.obtenerData();
        this.calcularTotalYCargos();


    }

    calcularTotalYCargos(){
        this.localStorageAs.watch('carro_compra').subscribe(carroCompra => {
            if (carroCompra !== null){
                this.carroDeCompra = JSON.parse(carroCompra);

                if (this.carroDeCompra.transacciones === undefined){
                    this.carroDeCompra.transacciones = [];
                }

                if (this.carroDeCompra.adicionales === undefined){
                    this.carroDeCompra.adicionales = [];
                }
                this.transacciones =  this.carroDeCompra.transacciones;
                this.adicionales =  this.carroDeCompra.adicionales;

                this.totalCompra = 0;
                this.totalCargos = 0;
                this.transacciones.forEach((transaccion) => {
                    this.totalCompra = this.totalCompra + (transaccion.monto * (1 - (transaccion.cupon === null ? 0 : transaccion.cupon.porcentaje_descuento)));
                    this.totalCargos = this.totalCargos + (transaccion.monto * (1 - (transaccion.cupon === null ? 0 : transaccion.cupon.porcentaje_descuento))  * transaccion.evento.configuracionPrecioRider.porcentaje_cobro);
                });

                this.adicionales.forEach((adicional) => {
                    this.totalCompra = this.totalCompra + adicional.eventoAdicional.precio;
                    this.totalCargos = this.totalCargos + (adicional.eventoAdicional.precio * adicional.porcentaje_cobro);
                });

            }else{
                this.carroDeCompra = null;
                this.transacciones = [];
                this.totalCompra = 0;
                this.totalCargos = 0;


            }


        });

        if (this.transacciones.length === 0 && this.adicionales.length === 0) {
            this.router.navigate(['/eventos']);

        }

    }

    private iniciarPagoPaypal(): void {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        this.payPalConfig = {
            clientId: environment.clientIdPaypal,
            createOrderOnServer: (data) => fetch(`${environment.api}/transaccion-rider/init-transaction-paypal`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(this.carroDeCompra)
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }

                    return response.text().then(text => {throw new Error(text); });
                })
                .then((order) => order.id)
                .catch(error => {
                    if (error) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(error.error).message,
                        })
            
                      }
                }),
                // .catch((err) => console.log(err)),
            onApprove: (data, actions) => fetch(`${environment.api}/transaccion-rider/capture-transaction-paypal/${data.orderID}`, {
            method: 'POST',
            // body: JSON.stringify({})
        })
            .then((res) => res.json())
            .then((order) => {
                localStorage.removeItem('paymentId');
                localStorage.setItem('paymentId', order.id);
                localStorage.removeItem('paymentType');
                localStorage.setItem('paymentType', 'PAYPAL');
                if (order.status === 'COMPLETED'){
                    this.localStorageAs.remove('carro_compra');
                    this.router.navigate([`/pago`], { queryParams: { paymentId: order.id }});
                }
            }),


            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
                swalWithBootstrapButtons.fire(
                    'No te preocupes!',
                    'Podrás inscribirte en el evento que quieras :)',
                    'error'
                );
                // this.showCancel = true;

            },
            onError: err => {
                console.log('OnError', err);


            },
            onClick: (data, actions) => {
                console.log('onClick', data, actions);
                // this.resetStatus();
            }
        };
    }

    ngAfterViewInit() {
        this.el.nativeElement.scrollIntoView();

    }

    eliminarTransaccion(transaccion: any){

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: '¿Estás seguro que quieres eliminar la inscripción?',
            showCancelButton: true,
            confirmButtonText: 'Si, estoy seguro',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
            imageHeight: 100,
        }).then((result) => {
            if (result.isConfirmed) {

                const indexDelete = this.transacciones.findIndex((item) => item.monto === transaccion.monto
                    && item.categoria.id === transaccion.categoria.id
                    && item.evento.id === transaccion.evento.id
                    && item.rider.nombre === transaccion.rider.nombre
                    && item.rider.apellido === transaccion.rider.apellido
                    && item.rider.email === transaccion.rider.email);

                this.transacciones.splice(indexDelete, 1);
                this.carroDeCompra.transacciones = this.transacciones;
                this.localStorageAs.set('carro_compra', JSON.stringify(this.carroDeCompra));

                this.calcularTotalYCargos();
                if (this.transacciones.length === 0 && this.adicionales.length === 0){
                    this.localStorageAs.remove('carro_compra');
                    this.router.navigate(['/eventos']);

                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        });

    }

    eliminarAdicional(indice: any){

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: '¿Estás seguro que quieres eliminar el producto adicional?',
            showCancelButton: true,
            confirmButtonText: 'Si, estoy seguro',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
            imageHeight: 100,
        }).then((result) => {
            if (result.isConfirmed) {

                this.adicionales.splice(indice, 1);
                this.carroDeCompra.adicionales = this.adicionales;
                this.localStorageAs.set('carro_compra', JSON.stringify(this.carroDeCompra));

                this.calcularTotalYCargos();

                if (this.transacciones.length === 0 && this.adicionales.length === 0){
                    this.localStorageAs.remove('carro_compra');
                    this.router.navigate(['/eventos']);

                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        });

    }

    iniciarTransaccionKhipu(){
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: 'Confirmar pago',
            html: this.mensajeValor(),
            showCancelButton: true,
            confirmButtonText: 'Confirmar pago',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
            imageUrl: 'https://s3.amazonaws.com/static.khipu.com/buttons/2021/110x50-purple.png',
            imageHeight: 100,
        }).then((result) => {
            if (result.isConfirmed) {
                this.iniciarPagoKhipu();

                swalWithBootstrapButtons.fire({
                    title: 'Ten paciencia',
                    text: 'Te estamos redirigiendo a la plataforma de pago...',
                    showCancelButton: false,
                    showConfirmButton: false,
                    reverseButtons: true,
                    imageUrl: 'https://s3.amazonaws.com/static.khipu.com/buttons/2021/110x50-purple.png',
                    imageHeight: 100,
                }).then(() => {

                });



            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                this.loading = false;

                swalWithBootstrapButtons.fire(
                    'No te preocupes!',
                    'Podrás inscribirte en el evento que quieras :)',
                    'error'
                );
            }
        });


    }

    iniciarTransaccionWebpay(){

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: 'Confirmar pago',
            html: this.mensajeValor(),
            showCancelButton: true,
            confirmButtonText: 'Confirmar pago',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/webpay-plus.png?alt=media&token=04424dbe-5609-4be2-806e-1866008ea37c',
            imageHeight: 100,
        }).then((result) => {
            if (result.isConfirmed) {

                this.iniciarPagoWebpay();

                swalWithBootstrapButtons.fire({
                    title: 'Ten paciencia',
                    text: 'Te estamos redirigiendo a la plataforma de pago...',
                    showCancelButton: false,
                    showConfirmButton: false,
                    reverseButtons: true,
                    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/webpay-plus.png?alt=media&token=04424dbe-5609-4be2-806e-1866008ea37c',
                    imageHeight: 100,
                }).then(() => {

                });


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                this.loading = false;

                swalWithBootstrapButtons.fire(
                    'No te preocupes!',
                    'Podrás inscribirte en el evento que quieras :)',
                    'error'
                );
            }
        });



    }

    iniciarTransaccionPaypal(){

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: 'Confirmar pago',
            html: this.mensajeValor(),
            showCancelButton: true,
            confirmButtonText: 'Confirmar pago',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/paypal.png?alt=media&token=45e001cd-aad7-4d1d-8441-7d0e01b4aa1b',
            imageHeight: 100,
        }).then((result) => {
            if (result.isConfirmed) {

                this.iniciarPagoPaypal();


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

                swalWithBootstrapButtons.fire(
                    'No te preocupes!',
                    'Podrás inscribirte en el evento que quieras :)',
                    'error'
                );
            }
        });



    }

    iniciarTransaccion(){

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: 'Confirmar pago',
            html: this.mensajeValor(),
            showCancelButton: true,
            confirmButtonText: 'Confirmar pago',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true,
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/mercadopago.png?alt=media&token=875507df-fadd-410b-b819-8c61cee2e50e',
            imageHeight: 100,
        }).then((result) => {
            if (result.isConfirmed) {

                this.iniciarPago();

                swalWithBootstrapButtons.fire({
                    title: 'Ten paciencia',
                    text: 'Te estamos redirigiendo a la plataforma de pago...',
                    showCancelButton: false,
                    showConfirmButton: false,
                    reverseButtons: true,
                    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/mercadopago.png?alt=media&token=875507df-fadd-410b-b819-8c61cee2e50e',
                    imageHeight: 100,
                }).then(() => {

                });


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                this.loading = false;

                swalWithBootstrapButtons.fire(
                    'No te preocupes!',
                    'Podrás inscribirte en el evento que quieras :)',
                    'error'
                );
            }
        });

    }

    mensajeValor(): string{
        return 'El precio a pagar es: ' +
            '<br>Valor inscripción: $' + this.totalCompra +
            '<br>Costos por servicios y transacción: $' + (Math.ceil(Number(this.totalCargos))) +
            '<br><strong>Total a pagar: $' + (this.totalCompra + Math.ceil(Number(this.totalCargos))) + '</strong>';
    }


    private iniciarPago() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        const petition = this.transaccionService.iniciarTransaccionMercadoPago(this.carroDeCompra);
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    console.log(res);
                    localStorage.removeItem('paymentId');
                    localStorage.removeItem('paymentType');
                    localStorage.setItem('paymentId', res.additional_info);
                    localStorage.setItem('paymentType', 'MERCADO_PAGO');
                    window.open(res.init_point, '_self').focus();
                    this.localStorageAs.remove('carro_compra');

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });

    }

    private iniciarPagoKhipu() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        const petition = this.transaccionService.iniciarTransaccionKhipu(this.carroDeCompra);
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    console.log(res);
                    localStorage.removeItem('paymentId');
                    localStorage.setItem('paymentId', res.payment_id);
                    localStorage.removeItem('paymentType');
                    localStorage.setItem('paymentType', 'KHIPU');
                    window.open(res.simplified_transfer_url, '_self').focus();
                    this.localStorageAs.remove('carro_compra');

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });

    }

    private obtenerData() {
        this.loading = true;

        const petitions = forkJoin([
            this.currencyService.obtenerPrecioDivisa('dolar'),
            this.mediosPagoService.obtenerMediosPagoHabilitados()]);

            petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {

                    this.precioDolar = Number(res[0]);
                    this.mediosPago = res[1];
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                    this.errorDolar = true;
                });

    }

    private iniciarPagoWebpay() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        const petition = this.transaccionService.iniciarTransaccionWebpay(this.carroDeCompra);
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {

                    console.log(res);

                    this.tokenWs = res.token;

                    localStorage.removeItem('paymentId');
                    localStorage.setItem('paymentId', res.token);
                    localStorage.removeItem('paymentType');
                    localStorage.setItem('paymentType', 'WEBPAY');
                    const webpayForm = document.createElement('form') as any;
                    webpayForm.method = 'POST';
                    webpayForm.action = `${res.url}`;
                    webpayForm.style.display = 'none';
                    const tokenInput = document.createElement('input') as any;
                    tokenInput.type = 'hidden';
                    tokenInput.name = 'token_ws';
                    tokenInput.value = res.token;
                    webpayForm.appendChild(tokenInput);
                    document.body.appendChild(webpayForm);

                    this.localStorageAs.remove('carro_compra');


                    webpayForm.submit();

                    // const forms: HTMLFormElement = document.getElementById('myForm') as HTMLFormElement;
                    // forms.submit();

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });

    }

    gastos(){
        return Math.ceil(this.totalCargos);
    }

    total(){
        return Math.ceil(this.totalCargos + this.totalCompra);
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    iniciarTransaccionTransferencia() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });


        swalWithBootstrapButtons.fire({
            title: 'Datos Transferencia',
            html: this.datosTransferencia(),
            showCancelButton: true,
            showConfirmButton: true,
            reverseButtons: true,
            confirmButtonText: 'Finalizar',
            cancelButtonText: 'Cancelar',
            icon: 'info'
        }).then((result) => {
            if (result.isConfirmed) {

                this.iniciarTransferencia();

                swalWithBootstrapButtons.fire({
                    title: 'Ten paciencia',
                    text: 'Estamos registrando tu inscripción',
                    showConfirmButton: false,
                    reverseButtons: true,
                    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/transferencia.png?alt=media&token=68d9b1ab-b559-479a-8ded-c2f891d9cbb9',
                    imageHeight: 100,
                    showCancelButton: false,
                }).then(() => {

                });


            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                this.loading = false;

                swalWithBootstrapButtons.fire(
                    'No te preocupes!',
                    'Podrás inscribirte en el evento que quieras :)',
                    'error'
                );
            }
        });
    }

    datosTransferencia(): string{
        return '<strong>Nombre: </strong> BIKERACE SPA.' +
            '<br><strong>Banco: </strong> Banco Chile' +
            '<br><strong>Tipo de cuenta: </strong> Cuenta Vista' +
            '<br><strong>Nro. Cuenta: </strong> 00-220-19234-00' +
            '<br><strong>RUT: </strong> 77.629.483-7' +
            '<br><strong>Correo Electrónico: </strong> hola@riderticket.cl' +
            '<br><strong>Monto: </strong>$' + (this.totalCompra + Math.ceil(Number(this.totalCargos) )) +
            '<hr> Debes mandar el comprobante de transferencia a <a href="mailto:hola@riderticket.cl">hola@riderticket.cl</a> o mediante <a href="https://api.whatsapp.com/send?phone=56949575982" target="_blank"> Whatsapp</a>.' +
            '<hr><strong style="color:red">IMPORTANTE: </strong> Presiona "Finalizar" para registrar tu inscripción.';
    }

    private iniciarTransferencia() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        const petition = this.transaccionService.iniciarTransaccionTransferencia(this.carroDeCompra);
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    console.log(res);
                    // localStorage.removeItem('paymentId');
                    // localStorage.setItem('paymentId', res.additional_info);
                    // window.open(environment.urlRiderWeb + '/pago-transferencia?paymentId=' + res.paymentId, '_self').focus();
                    swalWithBootstrapButtons.close();
                    this.localStorageAs.remove('carro_compra');

                    this.router.navigate([`/pago-transferencia`], { queryParams: { paymentId: res.paymentId }});

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });

    }

    calculoDescuento(valor: number, descuento: number){
        return Math.ceil(valor * (1 - descuento));
    }

    calculoTotalDolar(){
        return Math.ceil(this.total() / this.precioDolar);
    }

    getEnvironmentAdSense(){
        return environment.adSenseClient;
    }



}
