import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class PublicidadService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerAfichePorEstado(estado: string) {
    return this.http.get<any>(`${environment.api}/afiches-web-rider/estado/${estado}`);
  }






}
