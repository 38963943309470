<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2 *ngIf="loading">Esperando estado de la inscripción</h2>
            <h2 *ngIf="!loading">Solicitud de inscripción realizada</h2>

            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Pago</li>
            </ul>
        </div>
    </div>
</div>

<section class="checkout-area ptb-100" >
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="checkout-item" >
                    <h2 *ngIf="!loading">Detalle de transferencia</h2>

                    <div class="checkout-two" *ngIf="!loading">
                        <h3>Solicitud de transacción recibida</h3>

                        <strong>Nombre: </strong> BIKERACE SPA.
                        <br><strong>Banco: </strong> Banco Chile
                        <br><strong>Tipo de cuenta: </strong> Cuenta Corriente
                        <br><strong>Nro. Cuenta: </strong> 00-220-19234-00
                        <br><strong>RUT: </strong> 77.629.483-7
                        <br><strong>Correo Electrónico: </strong> hola@riderticket.cl
                        <br><strong>Monto: </strong>${{amount}}
                        <hr> Debes mandar el comprobante de transferencia a <a href="mailto:hola@riderticket.cl">hola@riderticket.cl</a> o mediante <a href="https://api.whatsapp.com/send?phone=56949575982" target="_blank"> Whatsapp</a>
                        <p>Revisaremos tu transacción en un máximo de 48 horas.</p>


                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <ng-adsense
                    [adClient]="getEnvironmentAdSense()"
                    [adSlot]="8327470592"
                ></ng-adsense>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
