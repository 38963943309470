<app-navbar-style-three></app-navbar-style-three>


<div class="book-table-area ptb-100"  *ngIf="!loading">
    <div  class="container">
        <div class="book-table-wrap">
            <div class="section-title">
                <h2>Solicitud devolución de ticket</h2>
                <span  style="color:red">NOTA: Esta solicitud llegará al equipo Bikerace, quien la analizará y te notificará la respuesta mediante correo electrónico.</span>
            </div>

            <ng-container [formGroup]="formBusqueda">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label *ngIf="!extranjero">RUT del participante: <span style="color:red">(requerido)</span></label>
                            <input *ngIf="!extranjero" type="text" formatRut formControlName="rut" class="form-control" placeholder="RUT">
                            <span *ngIf="!extranjero && formBusqueda.controls['rut'].invalid && (formBusqueda.controls['rut'].dirty || formBusqueda.controls['rut'].touched)" style="color:red"><b>Debes ingresar un rut válido.</b></span>

                            <label *ngIf="extranjero">Ingresa aquí tu Pasaporte: <span style="color:red">(requerido)</span></label>
                            <input *ngIf="extranjero" type="text" formControlName="rut" class="form-control" placeholder="Pasaporte">
                            <span *ngIf="extranjero && formBusqueda.controls['rut'].invalid && (formBusqueda.controls['rut'].dirty || formBusqueda.controls['rut'].touched)" style="color:red"><b>Debes ingresar un pasaporte válido.</b></span>
                            <br>
                            <span (click)="changeExtranjero(!extranjero)">
                                <input type="checkbox" [checked]="extranjero" ><label>&nbsp;&nbsp;Soy extranjero</label>
                            </span>

                        </div>
                    </div>
                </div>

                <div  class="text-center">
                    <button (click)="buscarRut()" class="btn cmn-btn">Buscar</button>
                </div>
            </ng-container>


            <ng-container *ngIf="eventos.length>0" >
                <hr>

                    <div class="row">
                        <div class="col-lg-12">

                            <!-- Primer paso -->
                            <div [hidden]="currentStep !== 1">
                                <h3>Datos del evento</h3>
                                <ng-container [formGroup]="formEvento">
                                    
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label>Ingresa el evento: <span style="color:red">(requerido)</span></label>
                                                    <select formControlName="evento" class="form-control">
                                                        <option *ngFor="let evento of eventos" [value]="evento.id">{{evento.nombre}}</option>
                                                    </select>
                                                    <span *ngIf="formEvento.controls['evento'].invalid && (formEvento.controls['evento'].dirty || formEvento.controls['evento'].touched)" style="color:red"><b>El campo evento es obligatorio.</b></span>
                        
                                                </div>
                                            </div>
                                        </div>
                                        <button style="float: right; " (click)="nextStep()" class="btn cmn-btn" [disabled]="!this.formEvento.valid">Siguiente</button>
                                      
                                </ng-container>
                              
                            </div>
                            
                            <!-- Segundo paso -->
                            <div [hidden]="currentStep !== 2">
                                <h3>Datos bancarios</h3>
                                <ng-container [formGroup]="formDatosBancarios">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Ingresa el nombre de titular: <span style="color:red">(requerido)</span></label>
                                                <input type="text" formControlName="nombre" class="form-control"
                                                placeholder="Nombre">
                                                <span *ngIf="formDatosBancarios.controls['nombre'].invalid && (formDatosBancarios.controls['nombre'].dirty || formDatosBancarios.controls['nombre'].touched)" style="color:red"><b>El campo nombre es obligatorio.</b></span>
                    
                                            </div>
                                        </div>
                    
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Ingresa el RUT de titular: <span style="color:red">(requerido)</span></label>
                                                <input formatRut formControlName="rut_transferencia" class="form-control"
                                                placeholder="RUT">
                                                <span *ngIf="formDatosBancarios.controls['rut_transferencia'].invalid && (formDatosBancarios.controls['rut_transferencia'].dirty || formDatosBancarios.controls['rut_transferencia'].touched)" style="color:red"><b>Debes ingresar un RUT válido.</b></span>
                    
                    
                                            </div>
                                        </div>
                    
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Ingresa el correo electrónico de titular: <span style="color:red">(requerido)</span></label>
                                                <input type="text" formControlName="correo_transferencia" class="form-control"
                                                placeholder="Correo electrónico">
                                                <span *ngIf="formDatosBancarios.controls['correo_transferencia'].invalid && (formDatosBancarios.controls['correo_transferencia'].dirty || formDatosBancarios.controls['correo_transferencia'].touched)" style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>
                    
                                            </div>
                                        </div>
                    
                                       
                    
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Selecciona el banco que deseas que se te haga la devolución <span style="color:red">(requerido)</span></label>
                                                <select formControlName="banco" class="form-control" >
                                                    <option *ngFor="let banco of bancos" [value]="banco.id">{{banco.banco}}</option>                            
                                                </select>
                                                <span *ngIf="formDatosBancarios.controls['banco'].invalid && (formDatosBancarios.controls['banco'].dirty || formDatosBancarios.controls['banco'].touched)" style="color:red"><b>El campo banco es obligatorio.</b></span>
                    
                                            </div>
                                        </div>
                    
                                       
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Selecciona tu tipo de cuenta <span style="color:red">(requerido)</span></label>
                                                <select formControlName="tipoCuenta" class="form-control" >
                                                    <option *ngFor="let tipoCuenta of tipoCuentas" [value]="tipoCuenta.id">{{tipoCuenta.tipoCuenta}}</option>                            
                                                </select>
                                                <span *ngIf="formDatosBancarios.controls['tipoCuenta'].invalid && (formDatosBancarios.controls['tipoCuenta'].dirty || formDatosBancarios.controls['tipoCuenta'].touched)" style="color:red"><b>El campo tipo de cuenta es obligatorio.</b></span>
                    
                                            </div>
                                        </div>
                    
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Número de cuenta: <span style="color:red">(requerido)</span></label>
                                                <input type="number" formControlName="numero_cuenta" class="form-control"
                                                placeholder="Número de cuenta">
                                                <span *ngIf="formDatosBancarios.controls['numero_cuenta'].invalid && (formDatosBancarios.controls['numero_cuenta'].dirty || formDatosBancarios.controls['numero_cuenta'].touched)" style="color:red"><b>El campo número de cuenta es obligatorio.</b></span>
                    
                                            </div>
                                        </div>
                                    </div>
                                    <button style="float: left; " class="btn cmn-btn" (click)="prevStep()">Atrás</button>
                                    <button style="float: right; "  class="btn cmn-btn" (click)="nextStep()" [disabled]="!this.formDatosBancarios.valid">Siguiente</button>
                                </ng-container>
                              
                            </div>
                            
                            <div [hidden]="currentStep !== 3">
                                <h3>Datos de contacto</h3>
                                <ng-container [formGroup]="formContacto">
                                    
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label>Teléfono móvil titular: <span style="color:red">(requerido)</span></label>
                                                    <input type="text" formControlName="telefono" class="form-control"
                                                    placeholder="Teléfono" (keyup)="formatPhone()" (keypress)="validatePhone($event)">
                                                    <label>*Nota: El formato es 569XXXXXXXX, y sólo serás contactado en el caso de algún error.</label><br>
                                                    <span
                                                    *ngIf="formContacto.controls['telefono'].invalid && (formContacto.controls['telefono'].dirty || formContacto.controls['telefono'].touched)"
                                                    style="color:red"><b>Ingresa un teléfono móvil válido.</b></span>
                                                </div>
                                            </div>
                                        </div>
                                        <button style="float: left; " class="btn cmn-btn" (click)="prevStep()">Atrás</button>
                                        <button style="float: right; "  class="btn cmn-btn" (click)="nextStep()" [disabled]="!this.formContacto.valid">Siguiente</button>                                      
                                </ng-container>
                              
                            </div>

                            <!-- Tercer paso (Confirmación) -->
                            <div [hidden]="currentStep !== 4">
                                <h3>Revisa tu solicitud</h3>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <h5>Datos del evento</h5>
                                        <p><b>Evento:</b> {{this.obtenerCampoEvento().nombre}}</p>

                                        <h5>Datos de contacto</h5>
                                        <p><b>Teléfono celular:</b> {{this.obtenerCampoContacto().telefono}}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5>Datos bancarios</h5>
                                        <p><b>Nombre titular:</b> {{this.obtenerCampoDatosBancarios('nombre')}}</p>
                                        <p><b>RUT titular:</b> {{this.obtenerCampoDatosBancarios('rut_transferencia')}}</p>
                                        <p><b>Correo electrónico titular:</b> {{this.obtenerCampoDatosBancarios('correo_transferencia')}}</p>
                                        <p><b>Banco:</b> {{this.obtenerCampoDatosBancarios('banco')}} </p>
                                        <p><b>Tipo cuenta:</b> {{this.obtenerCampoDatosBancarios('tipoCuenta')}}</p>
                                        <p><b>Número de cuenta:</b> {{this.obtenerCampoDatosBancarios('numero_cuenta')}}</p>
                                    </div>
                                </div>
                               
   
                              
                              <button style="float: left; " class="btn cmn-btn" (click)="prevStep()">Atrás</button>
                              <button style="float: right; " class="btn cmn-btn" (click)="enviarSolicitud()">Enviar solicitud</button>
                            </div>
                          </div>

                          

                    </div>

                <!-- <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Evento: <span style="color:red">(requerido)</span></label>
                            <select formControlName="evento" class="form-control">
                                <option *ngFor="let evento of eventos" [value]="evento.id">{{evento.nombre}}</option>
                            </select>
                            <span *ngIf="formSolicitud.controls['evento'].invalid && (formSolicitud.controls['evento'].dirty || formSolicitud.controls['evento'].touched)" style="color:red"><b>El campo evento es obligatorio.</b></span>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Ingresa el nombre de titular: <span style="color:red">(requerido)</span></label>
                            <input type="text" formControlName="nombre" class="form-control"
                            placeholder="Nombre">
                            <span *ngIf="formSolicitud.controls['nombre'].invalid && (formSolicitud.controls['nombre'].dirty || formSolicitud.controls['nombre'].touched)" style="color:red"><b>El campo nombre es obligatorio.</b></span>

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Ingresa el RUT de titular: <span style="color:red">(requerido)</span></label>
                            <input formatRut formControlName="rut_transferencia" class="form-control"
                            placeholder="RUT">
                            <span *ngIf="formSolicitud.controls['rut_transferencia'].invalid && (formSolicitud.controls['rut_transferencia'].dirty || formSolicitud.controls['rut_transferencia'].touched)" style="color:red"><b>Debes ingresar un RUT válido.</b></span>


                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Ingresa el correo electrónico de titular: <span style="color:red">(requerido)</span></label>
                            <input type="text" formControlName="correo_transferencia" class="form-control"
                            placeholder="Correo electrónico">
                            <span *ngIf="formSolicitud.controls['correo_transferencia'].invalid && (formSolicitud.controls['correo_transferencia'].dirty || formSolicitud.controls['correo_transferencia'].touched)" style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Teléfono móvil titular: <span style="color:red">(requerido)</span></label>
                            <input type="text" formControlName="telefono" class="form-control"
                            placeholder="Teléfono" (keyup)="formatPhone()" (keypress)="validatePhone($event)">
                            <label>*Nota: El formato es 569XXXXXXXX, y sólo serás contactado en el caso de algún error.</label><br>
                            <span
                            *ngIf="formSolicitud.controls['telefono'].invalid && (formSolicitud.controls['telefono'].dirty || formSolicitud.controls['telefono'].touched)"
                            style="color:red"><b>Ingresa un teléfono móvil válido.</b></span>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Selecciona el banco que deseas que se te haga la devolución <span style="color:red">(requerido)</span></label>
                            <select formControlName="banco" class="form-control" >
                                <option *ngFor="let banco of bancos" [value]="banco.id">{{banco.banco}}</option>                            
                            </select>
                            <span *ngIf="formSolicitud.controls['banco'].invalid && (formSolicitud.controls['banco'].dirty || formSolicitud.controls['banco'].touched)" style="color:red"><b>El campo banco es obligatorio.</b></span>

                        </div>
                    </div>

                   
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Selecciona tu tipo de cuenta <span style="color:red">(requerido)</span></label>
                            <select formControlName="tipoCuenta" class="form-control" >
                                <option *ngFor="let tipoCuenta of tipoCuentas" [value]="tipoCuenta.id">{{tipoCuenta.tipoCuenta}}</option>                            
                            </select>
                            <span *ngIf="formSolicitud.controls['tipoCuenta'].invalid && (formSolicitud.controls['tipoCuenta'].dirty || formSolicitud.controls['tipoCuenta'].touched)" style="color:red"><b>El campo tipo de cuenta es obligatorio.</b></span>

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Número de cuenta: <span style="color:red">(requerido)</span></label>
                            <input type="number" formControlName="numero_cuenta" class="form-control"
                            placeholder="Número de cuenta">
                            <span *ngIf="formSolicitud.controls['numero_cuenta'].invalid && (formSolicitud.controls['numero_cuenta'].dirty || formSolicitud.controls['numero_cuenta'].touched)" style="color:red"><b>El campo número de cuenta es obligatorio.</b></span>

                        </div>
                    </div>

                    
                </div> -->

                <!-- <div  class="text-center">
                    <button (click)="enviarSolicitud()" class="btn cmn-btn">Enviar solicitud</button>
                </div> -->
            </ng-container>
        </div>
    </div>

</div>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>
