import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

@Injectable()
export class EncryptService {
    constructor() { }

    encryptData(data) {

        try {
            return CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretUser).toString();
        } catch (e) {
            console.log(e);
        }
    }

    decryptData(data) {
        if (!data){
          return null;
        }
        try {
            const bytes = CryptoJS.AES.decrypt(data, environment.secretUser);
            if (bytes.toString()) {
                return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            }
            return data;
        } catch (e) {
            console.log(e);
        }
    }


}
