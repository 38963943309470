<div class="row" >
    <ng-container *ngFor="let evento of eventosFiltro" >
    <div class="col-sm-6 col-lg-4" [ngClass]="[evento.estado]" data-aos="fade-up-right"  >
        <div class="collection-item">
            <div *ngIf="obtenerDias(evento) >0 || obtenerHoras(evento) > 0" class="time-item con-crono" >
                <span > QUEDAN &nbsp; {{obtenerDias(evento)}} DÍA(S) {{obtenerHoras(evento)}} HR(S)</span>
            </div>
            <div *ngIf="!(obtenerDias(evento) >0 || obtenerHoras(evento) > 0)" class="time-item con-crono" >
                <span > EVENTO FINALIZADO</span>
            </div>
            
            <div class="collection-top">
                <img [src]="evento.url_foto" alt="Collection">
                <ul>
                    <a *ngIf="evento.platform === 'bikerace'" [href]="generarUrlBikerace('evento?id='+evento.id)" target="_blank">Descripción</a>
                    <a *ngIf="evento.platform === 'riderticket'" routerLink="/evento" [queryParams]="{ id: evento.id}">Descripción</a>
                </ul>
                <div class="inscritos">
                    <a *ngIf="evento.publicar_inscritos === 'PUBLICAR' && evento.platform === 'bikerace'" [href]="generarUrlBikerace('inscripciones?id='+evento.id)" target="_blank"><i class='bx bxs-user-plus'></i> Inscritos</a>
                    <a *ngIf="evento.publicar_inscritos === 'PUBLICAR' && evento.platform === 'riderticket'" routerLink="/inscripciones" [queryParams]="{ id: evento.id}"><i class='bx bxs-user-plus'></i> Inscritos</a>
                </div>
                <div class="add-cart">
                    <a *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos && evento.platform === 'bikerace'" [href]="generarUrlBikerace('inscripcion?id='+evento.id)" target="_blank" ><i class='bx bxs-cart'></i> Inscribirse</a>
                    <a *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos && evento.platform === 'riderticket'" routerLink="/inscripcion" [queryParams]="{ id: evento.id}"><i class='bx bxs-cart'></i> Inscribirse</a>
                    <a *ngIf="evento.estado === 'REALIZADO' && evento.platform === 'bikerace' && evento.servicio_cronometraje === 'ACTIVADO'" class="cmn-btn" [href]="generarUrlBikerace('resultados?id='+evento.id)" target="_blank"> Resultados</a>
                    <a *ngIf="evento.estado === 'REALIZADO' && evento.platform === 'bikerace' && evento.servicio_cronometraje === 'DESACTIVADO'" class="cmn-btn"> Finalizado</a>
                    <a *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'ACTIVADO'" class="cmn-btn" routerLink="/resultados" [queryParams]="{ id: evento.id}"> Resultados</a>
                    <a *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'DESACTIVADO'" class="cmn-btn"> Finalizado</a>
                    <a *ngIf="evento.cupos === evento.cantidad_inscritos && evento.estado === 'POR_REALIZAR'" class="cmn-btn" > <span class="red">Cupos agotados</span></a>
                    <a *ngIf="evento.estado === 'PROXIMAMENTE'" class="cmn-btn" > <span class="green">Próximamente</span></a>
                    <a *ngIf="evento.estado === 'SUSPENDIDO'" class="cmn-btn" > <span class="red">Suspendido</span></a>
                    <a *ngIf="evento.estado === 'INSCRIPCIONES_CERRADAS'" class="cmn-btn" > <span class="red">Inscripciones cerradas</span></a>
                </div>
            </div>

            <div class="collection-bottom">
                <h3 style="text-align: center">{{evento.nombre}}</h3>
                <span><strong>Lugar: </strong>{{evento.lugar}}</span><br>
                <span *ngIf="evento.url_strava !== null"><strong>Strava: </strong><a [href]="evento.url_strava" target="_blank">Ver ruta</a></span>

                <ul>
                    <li data-aos="zoom-in" data-aos-duration="800"><span class="from">Desde ${{obtenerMenorPrecio(evento, evento.platform)}}</span></li>
                    <li>
                        <div class="number">
                            {{evento.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}<br>
                            <div data-aos="flip-left" data-aos-duration="1000" style="background-color: green; float: right; border-radius: 30px">
                                <span style="width: fit-content;font-size: 13px;margin: 0 5px 5px 5px;color: white;" *ngIf="evento.platform === 'riderticket' && evento.mostrar_preventa === 'ACTIVADO'"> CON PREVENTA</span>
                                <span style="width: fit-content;font-size: 13px;margin: 0 5px 5px 5px;color: white;" *ngIf="evento.platform === 'bikerace' && evento.mostrar_preventa === 'ACTIVADO'"> CON PREVENTA</span>
                                <span style="width: fit-content;font-size: 13px;margin: 0 5px 5px 5px;color: white;" *ngIf="evento.estado === 'PROXIMAMENTE'"> PRÓXIMAMENTE</span>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
            <div class="platform-item con-crono" *ngIf="evento.servicio_cronometraje === 'ACTIVADO'" >
                <span > ⏱️ CON CRONOMETRAJE BIKERACE</span>
            </div>
        </div>
    </div>
</ng-container>
</div>
