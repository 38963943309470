import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ActualizarPasswordComponent } from './components/pages/actualizar-password/actualizar-password.component';
import { AdicionalComponent } from './components/pages/adicional/adicional.component';
import { CambioCategoriaComponent } from './components/pages/cambio-categoria/cambio-categoria.component';
import { CampeonatoResultsComponent } from './components/pages/campeonato-results/campeonato_results.component';
import { ChangePasswordComponent } from './components/pages/change-password/change-password.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { EventAdicionalesComponent } from './components/pages/event-adicionales/event-adicionales.component';
import { EventDetailsComponent } from './components/pages/event-details/event-details.component';
import { EventResultsComponent } from './components/pages/event-results/event_results.component';
import { EventosReservaNumerosComponent } from './components/pages/eventos-reserva-numeros/eventos-reserva-numeros.component';
import { EventsComponent } from './components/pages/events/events.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { FechasDisponiblesComponent } from './components/pages/fechas-disponibles/fechas-disponibles.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { InscriptionDetailsComponent } from './components/pages/inscription-details/inscription-details.component';
import { InscriptionComponent } from './components/pages/inscription/inscription.component';
import { InscriptionsEventsComponent } from './components/pages/inscriptions-events/inscriptions-events.component';
import { InscriptionsComponent } from './components/pages/inscriptions/inscriptions.component';
import { LoginComponent } from './components/pages/login/login.component';
import { MisEstadisticasComponent } from './components/pages/mis-estadisticas/mis-estadisticas.component';
import { NoticiaDetailsComponent } from './components/pages/noticias-details/noticia-details.component';
import { NoticiaComponent } from './components/pages/noticias/noticia.component';
import { PaymentTransferenciaComponent } from './components/pages/payment-transferencia/payment-transferencia.component';
import { PaymentComponent } from './components/pages/payment/payment.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { RankingComponent } from './components/pages/ranking/ranking.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { ReservarNumeroComponent } from './components/pages/reservar-numero/reservar-numero.component';
import { ResultsComponent } from './components/pages/results/results.component';
import { ServiciosComponent } from './components/pages/servicios/servicios.component';
import { ShoppingCartComponent } from './components/pages/shopping-cart/shopping-cart.component';
import { SolicitudDevolucionComponent } from './components/pages/solicitud-devolucion/solicitud-devolucion.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { TicketComponent } from './components/pages/ticket/ticket.component';
import { TransactionDetailsComponent } from './components/pages/transaction-details/transaction-details.component';
import { TransactionsComponent } from './components/pages/transactions/transactions.component';
import { AuthUserGuard } from './guards/auth-user.guard';

const routes: Routes = [
    { path: '', component: HomeTwoComponent },
    // {path: 'home-two', component: HomeTwoComponent},
    // {path: 'home-three', component: HomeThreeComponent},
    { path: 'nosotros', component: AboutComponent },
    // {path: 'categorias', component: CategoriesComponent},
    { path: 'inscripcion', component: InscriptionComponent },
    { path: 'fechas-disponibles', component: FechasDisponiblesComponent },
    { path: 'cambio-categoria', component: CambioCategoriaComponent },
    { path: 'pago', component: PaymentComponent },
    { path: 'pago-transferencia', component: PaymentTransferenciaComponent },
    // {path: 'services', component: ServicesComponent},
    // {path: 'services-details', component: ServicesDetailsComponent},
    { path: 'detalle-inscripcion', component: InscriptionDetailsComponent },
    { path: 'evento', component: EventDetailsComponent },
    { path: 'adicionales-evento', component: EventAdicionalesComponent },
    { path: 'adicional', component: AdicionalComponent },
    // {path: 'blog', component: BlogComponent},
    { path: 'noticias', component: NoticiaComponent },
    // {path: 'blog-details', component: BlogDetailsComponent},
    { path: 'noticia-details', component: NoticiaDetailsComponent },
    // {path: 'food-collection', component: FoodCollectionComponent},
    { path: 'eventos', component: EventsComponent },
    // {path: 'online-order', component: OnlineOrderComponent},
    // {path: 'chefs', component: ChefsComponent},
    { path: 'faq', component: FaqComponent },
    // {path: 'book-table', component: BookTableComponent},
    { path: 'carro', component: ShoppingCartComponent },
    { path: 'servicios', component: ServiciosComponent },
    { path: 'reserva-numeros', component: EventosReservaNumerosComponent },
    { path: 'reservar-numero', component: ReservarNumeroComponent },

    { path: 'ranking-campeonato', component: CampeonatoResultsComponent },
    { path: 'ranking', component: RankingComponent },
    { path: 'resultados', component: ResultsComponent },
    { path: 'resultados-evento', component: EventResultsComponent },
    // {path: 'checkout', component: CheckoutComponent},
    { path: 'login', component: LoginComponent },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthUserGuard] },
    { path: 'mis-inscripciones', component: InscriptionsComponent, canActivate: [AuthUserGuard] },
    { path: 'mis-estadisticas', component: MisEstadisticasComponent, canActivate: [AuthUserGuard] },
    { path: 'inscripciones', component: InscriptionsEventsComponent },
    { path: 'mis-transacciones', component: TransactionsComponent, canActivate: [AuthUserGuard] },
    { path: 'detalle-transaccion', component: TransactionDetailsComponent, canActivate: [AuthUserGuard] },
    { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthUserGuard] },
    { path: 'actualizar-password', component: ActualizarPasswordComponent },
    { path: 'recover-password', component: RecoverPasswordComponent },
    // {path: 'coming-soon', component: ComingSoonComponent},
    { path: 'terminos-y-condiciones', component: TermsConditionsComponent },
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    { path: 'error', component: ErrorComponent },
    // {path: 'contact', component: ContactComponent},
    { path: 'mi-ticket', component: TicketComponent },
    { path: 'solicitud-devolucion', component: SolicitudDevolucionComponent },

    { path: '**', component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
