<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Nuestros eventos</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Eventos</li>
            </ul>
        </div>
    </div>
</div>


<section class="collection-area collection-area-two pt-100 pb-70">
    <div class="container" *ngIf="!loading">
        <div class="section-title">
            <h2>Eventos</h2>
<!--            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>

        <div class="sorting-menu">
            <ul>
                <li #todos [ngClass]="filtro === 'TODAS' ? 'active' : ''" class="filter" (click)="filtrarEventos('TODAS')">Todos</li>
                <!-- <li #realizados [ngClass]="filtro === 'POR_REALIZAR' ? 'active' : ''" class="filter" (click)="filtrarEventos('POR_REALIZAR')">Por Realizar</li>
                <li #suspendidos [ngClass]="filtro === 'REALIZADO' ? 'active' : ''" class="filter"  (click)="filtrarEventos('REALIZADO')">Finalizados</li> -->
                <!-- <li #por_realizar [ngClass]="filtro === 'SUSPENDIDO' ? 'active' : ''" class="filter"  (click)="filtrarEventos('SUSPENDIDO')">Suspendidos</li> -->
                <li #ciclismo [ngClass]="filtro === 'bikerace' ? 'active' : ''" class="filter"  (click)="filtrarEventos('bikerace')">Ciclismo</li>
                <li #trail_running [ngClass]="filtro === 'trail-running' ? 'active' : ''" class="filter"  (click)="filtrarEventos('trail-running')">Trail-running</li>
                <li #motociclismo [ngClass]="filtro === 'motociclismo' ? 'active' : ''" class="filter"  (click)="filtrarEventos('motociclismo')">Motociclismo</li>
                <!-- <li #escolar [ngClass]="filtro === 'escolar' ? 'active' : ''" class="filter"  (click)="filtrarEventos('escolar')">Escolar</li>
                <li #tenis [ngClass]="filtro === 'tenis' ? 'active' : ''" class="filter"  (click)="filtrarEventos('tenis')">Tenis</li> -->

            </ul>
        </div>
        
        <app-evento-item [eventosFiltro] = "eventosFiltro" ></app-evento-item>

    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>

