import {Component, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';

import {take, takeUntil} from 'rxjs/operators';
import {EventoService} from '../../../services/evento.service';

@Component({
  selector: 'app-event-results',
  templateUrl: './event_results.component.html',
  styleUrls: ['./event_results.component.scss']
})
export class EventResultsComponent implements OnInit {

    public loading = false;
    public eventos: any[] = [];
    private compDestroy: Subject<boolean> = new Subject();

  constructor(
              private eventoService: EventoService) {

  }

    ngOnInit(): void {
        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventosFinalizados = this.eventoService.obtenerEventosFinalizados();

        const petitions = forkJoin([obtenerEventosFinalizados]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = [];
                    res[0].forEach((element: any) => {
                        this.eventos.push(element);
                    });
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

}
