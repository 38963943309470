import {Injectable} from '@angular/core';

@Injectable()
export class PhoneMaskService {
    constructor() { }

    formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{2})(\d{2})(\d{2})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3] + '-' + match[4] + '-' + match[5];
        }
        return phoneNumberString;
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }
}
