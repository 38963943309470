<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>{{dataUser.firstName}} {{dataUser.lastName}}</h3>

                        <ul>
                            <li><a routerLink="/profile">Mi Perfil <i class='bx bxs-user'></i></a></li>
                            <li><a routerLink="/mis-inscripciones">Mis inscripciones <i class='bx bx-task'></i></a></li>
                            <li><a routerLink="/mis-transacciones">Mis pagos <i class='bx bxs-credit-card'></i></a></li>
                            <li><a routerLink="/mis-estadisticas">Mis estadisticas <i class='bx bxs-bar-chart-alt-2'></i></a></li>
                            <li><a routerLink="/change-password">Contraseña <i class='bx bxs-key'></i></a></li>
                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>


                </div>
            </div>

            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-12" style="margin-bottom: 15px" *ngIf="!loading && lineChartLabelsPorCategoria.length>0">
                        <h4>Clasificación categoría por evento</h4>
                        <br>
                        <section class="cart-area">
                            <div class="container">
                                <div class="cart-wrap">
                                    <div class="chart-wrapper" >
                                        <canvas baseChart
                                                [datasets]="lineChartDataPorCategoria"
                                                [labels]="lineChartLabelsPorCategoria"
                                                [options]="lineChartOptions"
                                                [legend]="lineChartLegend"
                                                [type]="lineChartType"
                                                [plugins]="lineChartPlugins">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-12" style="margin-top: 15px" *ngIf="!loadingGeneral && lineChartLabelsPorGeneral.length > 0">
                        <hr>

                        <h4>Clasificación general por evento</h4>
                        <br>
                        <section class="cart-area">
                            <div class="container">
                                <div class="cart-wrap">
                                    <div class="chart-wrapper" >
                                        <canvas baseChart
                                                [datasets]="lineChartDataPorGeneral"
                                                [labels]="lineChartLabelsPorGeneral"
                                                [options]="lineChartOptions"
                                                [legend]="lineChartLegend"
                                                [type]="lineChartType"
                                                [plugins]="lineChartPlugins">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-6" style="margin-bottom: 15px" *ngIf="!loadingPodios && podiosPorCategoria.length > 0">
                        <hr>
                        <h4>Podios clasificación por categoria</h4>
                        <br>
                        <section class="cart-area">
                            <div class="container">
                                <div class="cart-wrap">
                                    <table class="table">
                                        <thead class="thead">
                                        <tr>
                                            <th class="table-head" scope="col">Posición</th>
                                            <th class="table-head" scope="col">Evento</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr *ngFor="let podio of podiosPorCategoria">
                                            <td>
                                                <div class="primer-lugar" *ngIf="podio.posicion_c === 1">
                                                    <div>1°</div>
                                                </div>

                                                <div class="segundo-lugar" *ngIf="podio.posicion_c === 2">
                                                    <div>2°</div>
                                                </div>

                                                <div class="tercer-lugar" *ngIf="podio.posicion_c === 3">
                                                    <div>3°</div>
                                                </div>
                                            </td>
                                            <td>{{podio.evento.nombre}} - {{podio.categoria.nombre}}</td>

                                        </tr>

                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </section>

                    </div>
                    <div class="col-lg-6" style="margin-bottom: 15px" *ngIf="!loadingGeneralPodios && podiosPorGeneral.length > 0">
                        <hr>
                        <h4>Podios clasificación general</h4>
                        <br>
                        <section class="cart-area">
                            <div class="container">
                                <div class="cart-wrap">
                                    <table class="table">
                                        <thead class="thead">
                                        <tr>
                                            <th class="table-head" scope="col">Posición</th>
                                            <th class="table-head" scope="col">Evento</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr *ngFor="let podio of podiosPorGeneral">
                                            <td>
                                                <div class="primer-lugar" *ngIf="podio.posicion_g === 1">
                                                    <div>1°</div>
                                                </div>

                                                <div class="segundo-lugar" *ngIf="podio.posicion_g === 2">
                                                    <div>2°</div>
                                                </div>

                                                <div class="tercer-lugar" *ngIf="podio.posicion_g === 3">
                                                    <div>3°</div>
                                                </div>
                                            </td>
                                            <td>{{podio.evento.nombre}} - {{podio.categoria.nombre}}</td>

                                        </tr>

                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-6" style="margin-bottom: 15px" *ngIf="!loadingTeams && teams.length > 0">
                        <hr>
                        <h4>Teams</h4>
                        <br>
                        <section class="cart-area">
                            <div class="container">
                                <div class="cart-wrap">
                                    <table class="table">
                                        <thead class="thead">
                                        <tr>
                                            <th class="table-head" scope="col">Evento</th>
                                            <th class="table-head" scope="col">Team</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr *ngFor="let team of teams">
                                            <td>
                                                {{team.evento.nombre}} - {{team.categoria.nombre}}
                                            </td>
                                            <td>{{team.team}}</td>

                                        </tr>

                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
