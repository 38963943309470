<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one" *ngIf="!loadingInfoInicial">
    <div class="container">
        <div class="page-title-item">
            <h2>{{evento.nombre}}</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Categoría</li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Producto adicional</li>

            </ul>
        </div>
    </div>
</div>
<br>
<app-adicional-form *ngIf="!loadingInfoInicial"
                    [adicionalId]="adicional"
                    [eventoId]="id" (adicionalForm)="initTransaccion($event)"
                    id = 0
                    nombreBoton="Continuar"
                    lock=false
></app-adicional-form>


<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading || loadingInfoInicial" ></app-preloader>
