import { Component, Input, OnInit } from '@angular/core';
import { MathHelper } from 'src/app/shared/math_helper';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-evento-item',
    templateUrl: './evento-item.component.html',
    styleUrls: ['./evento-item.component.scss'],
})
export class EventoItemComponent implements OnInit {

    @Input() public eventosFiltro = [];

    constructor(
        private mathHelper: MathHelper,

    ) {

    }


    ngOnInit(): void    {
    }


    obtenerDias(evento){
        const fechaDada: Date = new Date(evento.fecha_evento);
        const fechaDada2: Date = new Date(fechaDada.getTime() + fechaDada.getTimezoneOffset() * 60000);        
        const fechaActual = new Date();
        const diferencia = fechaDada2.getTime() - fechaActual.getTime(); // Diferencia en milisegundos

        return Math.floor(diferencia / (1000 * 60 * 60 * 24))

    }



    obtenerHoras(evento){
        const fechaDada: Date = new Date(evento.fecha_evento);
        const fechaDada2: Date = new Date(fechaDada.getTime() + fechaDada.getTimezoneOffset() * 60000);


        const fechaActual = new Date();
        const diferencia = fechaDada2.getTime() - fechaActual.getTime(); // Diferencia en milisegundos
        return Math.floor((diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    }
    
    obtenerMenorPrecio(evento: any, platform: string){
        if (platform === 'bikerace'){
            return this.mathHelper.obtenerMenor(evento.categorias, 'valor');
        }
        return this.mathHelper.obtenerMenor(evento.categoriasRider, 'valor');
    }

    generarUrlBikerace(path: string){
        return `${environment.urlBikeraceWeb}${path}`;
    }

}
