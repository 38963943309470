import {Component, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';

import {take, takeUntil} from 'rxjs/operators';
import {EventoService} from '../../../services/evento.service';
import {CampeonatoService} from "../../../services/campeonato.service";
import {GoogleAnalytics} from "../../../shared/google-analytics";

@Component({
  selector: 'app-campeonato-results',
  templateUrl: './campeonato_results.component.html',
  styleUrls: ['./campeonato_results.component.scss']
})
export class CampeonatoResultsComponent implements OnInit {

    public loading = false;
    public campeonatos: any[] = [];
    private compDestroy: Subject<boolean> = new Subject();

  constructor(
              private campeonatoService: CampeonatoService,
              private googleAnalytics: GoogleAnalytics
  ) {

  }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('resultado-campeonatos', 'exitoso', '', 'click', 0);

        this.campeonatoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerCampeonatosFinalizados = this.campeonatoService.obtenerCampeonatos();

        const petitions = forkJoin([obtenerCampeonatosFinalizados]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.campeonatos = [];
                    res[0].forEach((element: any) => {
                        this.campeonatos.push(element);
                    });
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

}
