
<div class="book-table-area" *ngIf="!loadingInfoInicial && !lock" >
    <div  class="container">
        <div class="book-table-wrap">
            <div class="section-title">
                <h2><span>Producto: </span>{{eventoAdicional.adicionalRider.nombre}} <span *ngIf="lock" style="cursor: pointer; color:red" (click)="eliminar()"><i class='bx bx-trash'></i> Eliminar</span>
                </h2>
            </div>
            <ng-container [formGroup]="formInscripcion">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Nombre: <span style="color:red">(requerido)</span></label>
                            <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre">
                            <span *ngIf="formInscripcion.controls['nombre'].invalid && (formInscripcion.controls['nombre'].dirty || formInscripcion.controls['nombre'].touched)" style="color:red"><b>El campo nombre es obligatorio.</b></span>

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Apellido: <span style="color:red">(requerido)</span></label>
                            <input type="text" formControlName="apellido" class="form-control" placeholder="Apellido">
                            <span *ngIf="formInscripcion.controls['apellido'].invalid && (formInscripcion.controls['apellido'].dirty || formInscripcion.controls['apellido'].touched)" style="color:red"><b>El campo apellido es obligatorio.</b></span>

                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Número de teléfono: <span style="color:red">(requerido)</span></label>
                            <input (keyup)="formatPhone(formInscripcion, 'nro_telefono')" (keypress)="validatePhone($event)" type="text" formControlName="nro_telefono" class="form-control" placeholder="Número de teléfono">
                            <label>*Nota: El formato es 569XXXXXXXX</label><br>
                            <span *ngIf="formInscripcion.controls['nro_telefono'].invalid && (formInscripcion.controls['nro_telefono'].dirty || formInscripcion.controls['nro_telefono'].touched)" style="color:red"><b>El campo número de teléfono es obligatorio.</b></span>

                        </div>
                    </div>


                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Correo electrónico: <span style="color:red">(requerido)</span></label>
                            <input type="correo_electronico" formControlName="correo_electronico" class="form-control" placeholder="Correo electrónico">
                            <label>*Nota: Debes ingresar un correo electrónico válido ya que te llegará el comprobante de pago</label><br>
                            <span *ngIf="formInscripcion.controls['correo_electronico'].invalid && (formInscripcion.controls['correo_electronico'].dirty || formInscripcion.controls['correo_electronico'].touched)" style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>


                        </div>
                    </div>

                    <ng-container [formGroup]="getFormGroupCamposFormulario('campos_formulario')">
                        <div class="col-lg-6" *ngFor="let campo of eventoAdicional.camposRider">
                            <div class="form-group">
                                <label>{{capitalizarPrimeraLetra(campo.nombre)}}: <span style="color:red">(requerido)</span></label>

                                <input *ngIf="campo.tipo === 'TEXTO'" type="text" [formControlName]="toLowerCaseAndDeleteSpace(campo.nombre)" class="form-control" [placeholder]="capitalizarPrimeraLetra(campo.nombre)">
                                <input *ngIf="campo.tipo === 'NUMERICO'" type="number" [formControlName]="toLowerCaseAndDeleteSpace(campo.nombre)" class="form-control" [placeholder]="capitalizarPrimeraLetra(campo.nombre)">
                                <input *ngIf="campo.tipo === 'FECHA'" type="date" [formControlName]="toLowerCaseAndDeleteSpace(campo.nombre)" class="form-control" [placeholder]="capitalizarPrimeraLetra(campo.nombre)">
                                <input *ngIf="campo.tipo === 'EMAIL'" type="email" [formControlName]="toLowerCaseAndDeleteSpace(campo.nombre)" class="form-control" [placeholder]="capitalizarPrimeraLetra(campo.nombre)">
                                <ng-container *ngIf="campo.tipo === 'TELEFONO'">
                                    <input  (keyup)="formatPhone(getFormGroupCamposFormulario('campos_formulario'), toLowerCaseAndDeleteSpace(campo.nombre))" (keypress)="validatePhone($event)" type="text" [formControlName]="toLowerCaseAndDeleteSpace(campo.nombre)" class="form-control" [placeholder]="capitalizarPrimeraLetra(campo.nombre)">
                                    <label>*Nota: El formato es 569XXXXXXXX</label><br>
                                </ng-container>

                                <span *ngIf="formInscripcion.controls.campos_formulario['controls'][toLowerCaseAndDeleteSpace(campo.nombre)].invalid && (formInscripcion.controls.campos_formulario['controls'][toLowerCaseAndDeleteSpace(campo.nombre)].dirty || formInscripcion.controls.campos_formulario['controls'][toLowerCaseAndDeleteSpace(campo.nombre)].touched)" style="color:red"><b>El campo {{campo.nombre}} es obligatorio.</b></span>

                            </div>
                        </div>
                    </ng-container>




                </div>

                <div class="text-center">
                    <button (click) = 'initTransaccion()' class="btn cmn-btn">{{nombreBoton}}</button>
                </div>
            </ng-container>
        </div>
    </div>

</div>

<app-preloader *ngIf="loading || loadingInfoInicial" ></app-preloader>
