<app-navbar-style-three></app-navbar-style-three>


<section class="checkout-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="checkout-item ">
                    <h2>Bienvenido, ingresa tus datos</h2>

                    <div class="checkout-one">
                        <form [formGroup]="formLogin">
                            <div class="form-group">
                                <label>Email:</label>
                                <input formControlName="email" type="email" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Contraseña:</label>
                                <input formControlName="password" type="password" class="form-control">
                            </div>


                        </form>
                    </div>
                </div>
            </div>


        </div>

        <div class="text-center">
            <button (click)="login()" class="btn cmn-btn">Iniciar sesión</button>
        </div>
        <br>
        <div class="cart-wrap">
            <div class="shop-back">
                No tienes contraseña o se te olvido, te ayudamos<a style="display: initial !important" routerLink="/recover-password">, presiona aquí</a>
            </div>
        </div>

        <div class="cart-wrap">
            <div class="shop-back">
                <ul>
                    <li>
                        Si ya te has inscrito a eventos, tu cuenta ya está creada, escríbenos a
                        <a style="display: initial !important" href="mailto:hola@bikerace.cl">hola@bikerace.cl</a> y
                        te mandaremos tus credenciales.
                    </li>
                    <li>
                        Si se te olvidó la clave, escríbenos a
                        <a style="display: initial !important" href="mailto:hola@bikerace.cl">hola@bikerace.cl</a> y
                        te mandaremos tus credenciales.
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
