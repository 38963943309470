import {Component, OnInit, TemplateRef} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {RiderService} from '../../../services/rider.service';
import {EventoService} from '../../../services/evento.service';
import {GoogleAnalytics} from '../../../shared/google-analytics';
import {CategoriaService} from '../../../services/categoria.service';

@Component({
    selector: 'app-inscriptions-events',
    templateUrl: './inscriptions-events.component.html',
    styleUrls: ['./inscriptions-events.component.scss']
})
export class InscriptionsEventsComponent implements OnInit {
    public id = 0;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    public riders: any[] = [];
    public categoriasConRider: any[] = [];
    public categoriasConRiderFilter: any[] = [];
    public ridersFilter: any[] = [];
    public search: string;
    public searchCategoria = '0';
    public categorias: any = [];
    public evento: any;

    constructor(
        private activatedroute: ActivatedRoute,
        private riderService: RiderService,
        private eventoService: EventoService,
        private googleAnalytics: GoogleAnalytics,
        private categoriaService: CategoriaService
    ) {
        this.activatedroute.queryParams.subscribe(params => {
            if (params.id !== undefined){
                this.id = Number(params.id);
                
            }
        });
    }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('listado-inscritos', 'exitoso', '', 'click', 0);

        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getCategorias();
    }

    getCategorias() {
        this.loading = true;
        let petition = null;

        petition = this.categoriaService.obtenerCategoriaByEventoId(this.id);

        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.categorias = res[0];
                    this.getDataDefault();
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getDataDefault() {
        this.loading = true;
        const petition =  this.eventoService.obtenerEventoSimplifyById(this.id);


        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.evento = res[0];
                    if (res[0].publicar_inscritos === 'PUBLICAR'){
                        this.getDataRiders();
                    }
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

  

    getDataRiders() {
        this.loading = true;
        const petition =  this.riderService.obtenerRiderPorEventoYEstadosSimplify(this.id, 'APROBADO,RESERVADO');


        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    res[0].forEach((element: any) => {
                        this.riders.push(element);
                    });

                    this.ridersFilter = this.riders;
                    
                    this.clasificarPorCategoria();
                    this.procesarFederados();
                    this.categoriasConRiderFilter = this.categoriasConRider;

                    this.categoriasConRiderFilter = this.categoriasConRiderFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    changeSearch(){
        this.clasificarPorCategoria();
        this.procesarFederados();
        this.categoriasConRiderFilter = [];
        if (this.search.toLowerCase().length === 0){
            this.categoriasConRiderFilter = [].concat(this.categoriasConRider);
            this.categoriasConRiderFilter = this.categoriasConRiderFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        }else{
            this.categoriasConRiderFilter = [].concat(this.categoriasConRider.filter((categoria) =>
                categoria.riders.filter((rider) =>
                    (rider.nombre.toLowerCase() + ' ' + rider.apellido.toLowerCase()).includes(this.search.toLowerCase())).length > 0));

            this.categoriasConRiderFilter.forEach((categoria) => {
                categoria.riders = [].concat(categoria.riders.filter((rider) => (rider.nombre.toLowerCase() + ' ' + rider.apellido.toLowerCase()).includes(this.search.toLowerCase())));
            });
            this.categoriasConRiderFilter = this.categoriasConRiderFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        }

    }

    clasificarPorCategoria(){
        this.categoriasConRider = [];

        this.categorias.forEach((categoria) => {
            this.riders.filter((riderFilter) => riderFilter.categoriaRider === categoria.id).forEach((rider) => {
                if (!this.categoriasConRider.find((categoriaFind) => categoriaFind.id === rider.categoriaRider)){
                    this.categoriasConRider.push({
                        id: categoria.id,
                        nombre: categoria.nombre,
                        modo: categoria.modo,
                        desafio: categoria.desafioEntity.nombre,
                        federada: categoria.federada === 'ACTIVADA' ? 'Federada' : 'No federada',
                        sexo: categoria.sexo,
                        riders: [
                            rider
                        ],
                    });
                }else{
                    this.categoriasConRider.find((categoriaFind) => categoriaFind.id === rider.categoriaRider).riders.push(rider);

                }
            });

        });

    }

    procesarFederados(){
        this.categoriasConRider.forEach((categoria) => {
           if (categoria.federada === 'Federada'){
               const categoriaNoFederada = this.categoriasConRider.find((categoriaFind) => categoriaFind.nombre === categoria.nombre && categoriaFind.modo === categoria.modo && categoriaFind.sexo === categoria.sexo && categoriaFind.federada === 'No federada');
               if (categoriaNoFederada !== undefined){
                   categoriaNoFederada.riders = categoriaNoFederada.riders.concat(categoria.riders);
               }else{
                   this.categoriasConRider.push({
                       id: categoria.id,
                       nombre: categoria.nombre,
                       modo: categoria.modo,
                       desafio: categoria.desafioEntity.nombre,
                       federada: 'No federada',
                       sexo: categoria.sexo,
                       riders: categoria.riders,
                   });
               }
           }
        });
    }

    changeSearchCategoria(){
        this.search = '';
        if (this.searchCategoria === '0'){
            this.categoriasConRiderFilter = Object.assign([],  this.categoriasConRider);
            this.categoriasConRiderFilter = this.categoriasConRiderFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        }else{
            this.categoriasConRiderFilter =
                Object.assign([], this.categoriasConRider.filter((categoria) =>
                `${categoria.nombre} - ${categoria.desafio} - ${categoria.modo} - ${categoria.sexo}` === this.searchCategoria
                ));
            this.categoriasConRiderFilter = this.categoriasConRiderFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        }

    }

}
