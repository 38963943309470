<app-navbar-style-three></app-navbar-style-three>

<!--<div class="page-title-area page-title-img-one" *ngIf="!loadingInfoInicial">-->
<!--    <div class="container">-->
<!--        <div class="page-title-item">-->
<!--            <h2>{{evento.nombre}}</h2>-->
<!--            <ul>-->
<!--                <li><a routerLink="/">Inicio</a></li>-->
<!--                <li><i class='bx bx-chevron-right' ></i></li>-->
<!--                <li>Categoría</li>-->
<!--                <li><i class='bx bx-chevron-right' ></i></li>-->
<!--                <li>Inscripción</li>-->

<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="book-table-area ptb-100"  >
    <div  class="container">
        <div class="book-table-wrap">
            <div class="section-title">
                <h2>Reservar número</h2>
            </div>

            <ng-container *ngIf="!loading" [formGroup]="formReserva">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label *ngIf="!extranjero">Ingresa aquí tu RUT: <span style="color:red">(requerido)</span></label>
                            <input *ngIf="!extranjero" type="text" formatRut formControlName="rut" class="form-control" placeholder="RUT">
                            <span *ngIf="!extranjero && formReserva.controls['rut'].invalid && (formReserva.controls['rut'].dirty || formReserva.controls['rut'].touched)" style="color:red"><b>Debes ingresar un rut válido.</b></span>

                            <label *ngIf="extranjero">Ingresa aquí tu Pasaporte: <span style="color:red">(requerido)</span></label>
                            <input *ngIf="extranjero" type="text" formControlName="rut" class="form-control" placeholder="Pasaporte">
                            <span *ngIf="extranjero && formReserva.controls['rut'].invalid && (formReserva.controls['rut'].dirty || formReserva.controls['rut'].touched)" style="color:red"><b>Debes ingresar un pasaporte válido.</b></span>
                            <br>
                            <span (click)="changeExtranjero(!extranjero)">
                                <input type="checkbox" [checked]="extranjero" ><label>&nbsp;&nbsp;Soy extranjero</label>
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Número <span style="color:red">(requerido)</span></label>
                            <select formControlName="dor" class="form-control" >
                                <option *ngFor="let dor of numerosDisponibles" [value]="dor">{{dor}}</option>
                            </select>
                            <span *ngIf="formReserva.controls['dor'].invalid && (formReserva.controls['dor'].dirty || formReserva.controls['dor'].touched)" style="color:red"><b>El campo Número es obligatorio.</b></span>

                        </div>
                    </div>
                </div>

                <div  class="text-center">
                    <button (click) = "reservar()" class="btn cmn-btn">Reservar</button>
                </div>
            </ng-container>


           
        </div>
    </div>

</div>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>
