<app-navbar-style-two></app-navbar-style-two>

<div class="banner-area-two">
<!--    <div class="banner-shape">-->
<!--        <img src="assets/img/home-two/banner/1.png" alt="Shape">-->
<!--        <img src="assets/img/home-two/banner/2.png" alt="Shape">-->
<!--        <img src="assets/img/home-two/banner/3.png" alt="Shape">-->
<!--    </div>-->

<div class="container">
    <div class="banner-content">
        <h1>Tu aventura comienza aquí</h1>
        <p>Una empresa de la familia Bikerace. Descubre los servicios que tenemos para tí.        </p>
        <div class="search-box">
               
            <div class="form-search">
                <div class="form-inner">
                    <div class="form-group search-info">
                        <!-- <i class="fa fa-sticky-note  bficon"></i> -->
                        <i class='bx bxs-check-square'></i>
                        <input type="text" [(ngModel)]="nombre" class="form-control" placeholder="Nombre">
                    </div>
                    <div class="form-group search-location">
                        <!-- <i class="fas fa-map-marker-alt"></i> -->
                        <i class='bx bxs-map'></i>
                        <input type="text" [(ngModel)]="lugar" class="form-control" placeholder="Lugar">
                        <!-- <a class="current-loc-icon" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a> -->
                    </div>
                    <button (click)="buscar()" class="btn btn-primary search-btn mt-0"><i class="fas fa-search"></i> Buscar</button>
                </div>
            </div>
        
    </div>	

        <!-- <div class="banner-btn-wrap">
            <a class="cmn-btn" routerLink="/servicios">Ver nuestros servicios</a>
        </div> -->
    </div>
</div>
</div>

<section class="collection-area pt-100 pb-100">
    <div class="container" *ngIf="!loading">
        <div class="section-title">
            <h2>Encuentra tu evento</h2>
        </div>

        <div class="sorting-menu">
            <ul>
                <li #todos [ngClass]="filtro === 'TODAS' ? 'active' : ''" class="filter" (click)="filtrarEventos('TODAS')">Todos</li>
                <!-- <li #realizados [ngClass]="filtro === 'POR_REALIZAR' ? 'active' : ''" class="filter" (click)="filtrarEventos('POR_REALIZAR')">Por Realizar</li>
                <li #suspendidos [ngClass]="filtro === 'REALIZADO' ? 'active' : ''" class="filter"  (click)="filtrarEventos('REALIZADO')">Finalizados</li> -->
                <!-- <li #por_realizar [ngClass]="filtro === 'SUSPENDIDO' ? 'active' : ''" class="filter"  (click)="filtrarEventos('SUSPENDIDO')">Suspendidos</li> -->
                <li #ciclismo [ngClass]="filtro === 'bikerace' ? 'active' : ''" class="filter"  (click)="filtrarEventos('bikerace')">Ciclismo</li>
                <li #trail_running [ngClass]="filtro === 'trail-running' ? 'active' : ''" class="filter"  (click)="filtrarEventos('trail-running')">Trail-running</li>
                <li #motociclismo [ngClass]="filtro === 'motociclismo' ? 'active' : ''" class="filter"  (click)="filtrarEventos('motociclismo')">Motociclismo</li>
                <!-- <li #escolar [ngClass]="filtro === 'escolar' ? 'active' : ''" class="filter"  (click)="filtrarEventos('escolar')">Escolar</li>
                <li #tenis [ngClass]="filtro === 'tenis' ? 'active' : ''" class="filter"  (click)="filtrarEventos('tenis')">Tenis</li> -->

            </ul>
        </div>

        <app-evento-item [eventosFiltro] = "eventosFiltro" ></app-evento-item>

        <div class="more-collection">
            <a routerLink="/eventos">Ver más</a>
        </div>
    </div>
</section>

<section data-aos="fade-right" data-aos-duration="600" class="chef-area" style="padding-top: 30px;" *ngIf="auspiciadores.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Auspiciadores</h2>
<!--            <p>Ofrecemos el mejor servicio, siempre comprometidos a que sea una excelente experiencia. Te invitamos a unirte a los mejores!!</p>-->
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3" *ngFor="let auspiciador of auspiciadores">
                <div class="chef-item">
                    <div class="chef-top">
                        <img [src]="auspiciador.image_url" alt="Chef">
                        <div class="chef-inner">
                            <h3>{{auspiciador.titulo}}</h3>
                           <span>{{auspiciador.subtitulo}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li *ngIf="auspiciador.facebook_url !== null" ><a target="_blank" [href]="auspiciador.facebook_url"><i class='bx bxl-facebook'></i></a></li>
                            <li *ngIf="auspiciador.instagram_url !== null"><a target="_blank" [href]="auspiciador.instagram_url"><i class='bx bxl-instagram'></i></a></li>  
                            <li *ngIf="auspiciador.pagina_web_url !== null"><a target="_blank" [href]="auspiciador.pagina_web_url"><i class='bx bx-laptop'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section data-aos="fade-left" data-aos-duration="600" class="chef-area" style="padding-top: 30px;" *ngIf="clientes.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Clientes</h2>
            <p>Ofrecemos el mejor servicio, siempre comprometidos a que sea una excelente experiencia. Te invitamos a unirte a los mejores!!</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3" *ngFor="let cliente of clientes">
                <div class="chef-item">
                    <div class="chef-top">
                        <img [src]="cliente.image_url" alt="Chef">
                        <div class="chef-inner">
                            <h3>{{cliente.titulo}}</h3>
                           <span>{{cliente.subtitulo}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li *ngIf="cliente.facebook_url !== null" ><a target="_blank" [href]="cliente.facebook_url"><i class='bx bxl-facebook'></i></a></li>
                            <li *ngIf="cliente.instagram_url !== null"><a target="_blank" [href]="cliente.instagram_url"><i class='bx bxl-instagram'></i></a></li>  
                            <li *ngIf="cliente.pagina_web_url !== null"><a target="_blank" [href]="cliente.pagina_web_url"><i class='bx bx-laptop'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>
