<app-navbar-style-three></app-navbar-style-three>

<app-banner-custom
    *ngIf="!loading"
    [urlBanner]="evento.url_banner"
    [eventName]="evento.nombre"
    routerOne="/"
    navigationOne="Inicio"
    [navigationTwo]="evento.nombre"></app-banner-custom>

<section class="cart-area pt-100 pb-70" *ngIf="!loading">
    <div class="container">
        <div class="cart-wrap">
            <h2>¿Quieres agregar productos adicionales?</h2>
            <br>
            <table class="table">
                <thead class="thead">
                <tr>
                    <th class="table-head" scope="col">Producto adicional</th>
                    <th class="table-head" scope="col">Descripción</th>
                    <th class="table-head" scope="col"></th>
                </tr>
                </thead>

                <tbody>
                <tr *ngFor="let adicional of adicionales">
                    <td>{{adicional.adicional.nombre}}</td>
                    <td>{{adicional.adicional.descripcion}}</td>
                    <td>
                        <a routerLink="/adicional" [queryParams]="{ id: evento.id, adicional: adicional.id}"><span class="delete-notebook btn cmn-btn"><i class='bx bx-pointer'></i> Seleccionar</span></a>
                        <a routerLink="/adicional" [queryParams]="{ id: evento.id, adicional: adicional.id}"><span  class="delete-smartphone"><i class='bx bx-pointer'></i> Seleccionar</span></a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>

