<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Online Order</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Online Order</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-area ptb-100">
    <div class="container">
        <div class="services-slider owl-theme owl-carousel">
            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services1.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Fresh Healthy Food</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services2.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Free Fast Home Delivery</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>
        </div>
    </div>
</section>

<section class="download-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="download-content">
                    <div class="section-title">
                        <span class="sub-title">Download</span>
                        <h2>Download Our Mobile App That Make You More Easy to Order</h2>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                    </div>

                    <ul>
                        <li><span>01</span> Select Your Food</li>
                        <li><span>02</span> Add to Cart</li>
                        <li><span>03</span> Order Your Food</li>
                    </ul>
                    
                    <div class="app-wrap">
                        <a href="#"><img src="assets/img/home-two/google-store.png" alt="Google"></a>
                        <a href="#"><img src="assets/img/home-two/app-store.png" alt="App"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-two/download1.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>