import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class UsuarioOrganizadorService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }


      login(formLogin: any) {
        return this.http.post<any>(`${environment.api}/usuario-organizador/login`, formLogin);
      }

    update(id: number, formUpdate: any) {
        return this.http.patch<any>(`${environment.api}/usuario-organizador/${id}`, formUpdate);
    }

    obtenerUsuarioOrganizadorById(id: number) {
        return this.http.get<any>(`${environment.api}/usuario-organizador/${id}`);
    }

    actualizarContrasena(id: number, formUpdate: any) {
        return this.http.patch<any>(`${environment.api}/usuario-organizador/password/${id}`, formUpdate);
    }

}
