import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

@Injectable()
export class MathHelper {
    constructor() { }

    obtenerMenor(arrayObject: any, key: string) {
        return arrayObject.reduce((min, p) => p[key] < min ? p[key] : min, arrayObject[0][key]);
    }



}
