import {Component, OnInit} from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter, take, takeUntil } from 'rxjs/operators';
import {environment} from '../environments/environment';
import {EncryptService} from './shared/encrypt';
import {CarroService} from './services/carro.service';
import {LocalStorageService} from './shared/storage';
declare let $: any;
declare var gtag;
import AOS from 'aos';
import { IpService } from './services/ip.service';
import { forkJoin, Subject } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    public userDataComplete = null;
    public dataUser = null;
    private compDestroy: Subject<boolean> = new Subject();

    constructor(
        private router: Router,
        private encryptService: EncryptService,
        private carroService: CarroService,
        private localStorageAs: LocalStorageService,
        private ipService: IpService

    ) {
        const navEndEvents$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            );

        navEndEvents$.subscribe((event: NavigationEnd) => {
            gtag('config', environment.uuidAnalytics, {
                page_path: event.urlAfterRedirects
            });
        });



    }

    ngOnInit(){
        this.recallJsFuntions();

        this.userDataComplete = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));
        this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUser')));

        if (this.localStorageAs.get('id_session') === null || this.localStorageAs.get('id_session') === undefined){
            this.localStorageAs.set('id_session', Date.now());
        }

        this.obtenerIp();
        AOS.init();
        window.addEventListener('load', AOS.refresh);
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.loader').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.loader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    obtenerIp() {
        const obtenerIp = this.ipService.obtenerIp();


        const petitions = forkJoin([obtenerIp]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.localStorageAs.set('ip_connection', JSON.parse(res[0]).ip);
                },
                err => {
                    // console.log(err);

                });
    }
}
