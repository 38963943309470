import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, map} from 'rxjs';
import {EncryptService} from '../shared/encrypt';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
      private encryptService: EncryptService

  ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            responseType: 'text',
            headers: request.headers.set('Accept', 'text/plain')
          });
      
          if(request.method === 'POST' || request.method === 'PATCH'){
            request = request.clone({
                body: this.encryptService.encryptData(request.body),
              });
          }

        if (!request.url.includes('login')) {
            const tokenUser = this.encryptService.decryptData(sessionStorage.getItem('token'));
            if (tokenUser) {
                const token = tokenUser;
                request = request.clone({
                    setHeaders: {
                        adminToken: `Bearer ${token}`,
                        // 'x-auth-token': environment.api_token,
                        // 'PRIVATE-TOKEN': environment.api_token_gitlab,
                        // 'Access-Control-Allow-Origin': '*',
                        // 'Access-Control-Allow-Credentials': 'true',
                        // 'Access-Control-Allow-Methods': 'GET',
                    }
                });
            }
        }

        return next.handle(request).pipe(
      
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if(!event.url.includes('init-transaction-paypal') && !event.url.includes('capture-transaction-paypal')){
                  // Obtener la respuesta del servidor
                  const response = event.body;
                        
                  // Modificar la respuesta según sea necesario
                  const modifiedResponse = this.encryptService.decryptData(response);

                  // Crear una nueva respuesta con los datos modificados
                  const modifiedEvent = event.clone({ body: modifiedResponse });

                  // Devolver la respuesta modificada
                  return modifiedEvent;
                }else{
                  return event;
                }

                
            }
          })
          );
    }
}
