<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-area services-area-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services1.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Fresh Healthy Food</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services2.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Free Fast Home Delivery</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services3.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Discount Voucher</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services4.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>On Time Service</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services5.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Vat Free</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a routerLink="/services-details">
                        <img src="assets/img/home-one/services6.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Qualityful Food</h3>
                        <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>