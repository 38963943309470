import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {EventoService} from '../../../services/evento.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import {LocalStorageService} from '../../../shared/storage';
import {EncryptService} from '../../../shared/encrypt';
import {StringHelper} from '../../../shared/string_helper';
import Swal from 'sweetalert2';
import {PhoneMaskService} from '../../../shared/phone-mask';
import { Console } from 'console';

@Component({
    selector: 'app-adicional-form',
    templateUrl: './adicional-form.component.html',
    styleUrls: ['./adicional-form.component.scss']
})
export class AdicionalFormComponent implements OnInit {

    public loading = false;
    public loadingInfoInicial = false;
    public eventoAdicional: any = null;
    public evento: any = null;
    private compDestroy: Subject<boolean> = new Subject();
    public formInscripcion: FormGroup ;

    @Input() public id = 0;
    @Input() public lock = false;
    @Input() public adicionalId = 0;
    @Input() public eventoId = 0;
    @Input() public nombreBoton  = '';
    @Output() adicionalForm = new EventEmitter<any>();
    @Output() deleteItem = new EventEmitter<number>();



    constructor(private eventoService: EventoService,
                private fb: FormBuilder,
                private stringHelper: StringHelper,
                private router: Router,
                private localStorageAs: LocalStorageService,
                private encryptService: EncryptService,
                private phoneMask: PhoneMaskService,
    ) {

    }

    ngOnInit(): void {
        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getAdicional();
                    }
                });
        this.lock = this.lock.toString() === 'true';

        this.getAdicional();
    }

    getAdicional() {
        this.loadingInfoInicial = true;
        const obtenerEventoById = this.eventoService.obtenerEventoById(this.eventoId);
        const obtenerAdicionalPorId = this.eventoService.obtenerAdicionalPorId(this.adicionalId);


        const petitions = forkJoin([obtenerAdicionalPorId, obtenerEventoById]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventoAdicional = res[0];
                    this.evento = res[1];
                    this.initForm();

                    this.loadingInfoInicial = false;


                },
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }

    capitalizarPrimeraLetra(palabra: string){
        return this.stringHelper.capitalizarPrimeraLetra(palabra);
    }

    initForm() {
        this.formInscripcion = this.fb.group({
            id: [this.id],
            nombre: ['', Validators.required],
            apellido: ['', Validators.required],
            nro_telefono: ['', Validators.required],
            correo_electronico: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            porcentaje_cobro: [this.evento.configuracionPrecioRider.porcentaje_cobro, Validators.required],
            eventoAdicional: [this.eventoAdicional, Validators.required],
            campos_formulario : this.fb.group({})
        });

        this.eventoAdicional.camposRider.forEach((campo) => {
            if (campo.tipo === 'EMAIL'){
                (this.formInscripcion.get('campos_formulario') as FormGroup).
                addControl(campo.nombre.toLowerCase().replace(' ', '_'),
                    new FormControl(
                        '',
                        [Validators.required,
                            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]));
            }else{
                (this.formInscripcion.get('campos_formulario') as FormGroup).addControl(campo.nombre.toLowerCase().replace(' ', '_'), new FormControl('', Validators.required));

            }
            }
        );
    }

    toLowerCaseAndDeleteSpace(palabra: string){
        return palabra.toLowerCase().replace(' ', '_');
    }

    getFormGroupCamposFormulario(key: string){
        return (this.formInscripcion.get(key) as FormGroup);
    }

    initTransaccion() {
        if (!this.formInscripcion.valid){

            Swal.fire(
                    'Campos faltantes.',
                    'Debes rellenar todos los campos del formulario',
                    'error'
                );
            return;
        }
        this.adicionalForm.emit(this.formInscripcion.value);
    }
    eliminar(){
        this.deleteItem.emit(this.id);

    }

    formatPhone(formgroup: FormGroup, keyForm: string){
        formgroup.get(keyForm).setValue(this.phoneMask.formatPhoneNumber(formgroup.get(keyForm).value));
    }

    validatePhone(event){
        return this.phoneMask.numberOnly(event);
    }
}
