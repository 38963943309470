import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class SolicitudCambioCategoriaService {

    // Observable refresh list data categorias
    flagRefresh = new BehaviorSubject<any>(null);
    observableData$ = this.flagRefresh.asObservable();

    constructor(private http: HttpClient) { }

    // actualizamos flag
    nextData(flag: boolean) {
        this.flagRefresh.next(flag);
    }

    crearSolicitudCodigo(solicitudCodigo: any) {
        return this.http.post<any>(`${environment.api}/solicitud-cambio-categoria-rider`, solicitudCodigo);
    }

    validacionSolicitudCodigo(id: number, solicitudValidacionCodigo: any) {
        return this.http.post<any>(`${environment.api}/solicitud-cambio-categoria-rider/${id}/validacion-codigo`, solicitudValidacionCodigo);
    }



}
