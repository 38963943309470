<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Fechas disponibles</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Fechas disponibles</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row" *ngIf="!loading">
            <div class="row text-center">
                <div class="col-md-4">
                    <div class="btn-group">
                        <div
                            class="btn btn-primary background-primary"
                            mwlCalendarPreviousView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()">
                            Anterior
                        </div>
                        <div
                            class="btn btn-outline-secondary"
                            mwlCalendarToday
                            (viewDateChange)="hoy()"
                            [(viewDate)]="viewDate">
                            Hoy
                        </div>
                        <div
                            class="btn btn-primary background-primary"
                            mwlCalendarNextView
                            [view]="view"
                            [(viewDate)]="viewDate"
                            (viewDateChange)="closeOpenMonthViewDay()">
                            Siguiente
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
                </div>
                <div class="col-md-4">
                    <div class="btn-group">
                        <div
                            class="btn btn-primary background-primary"
                            (click)="setView(CalendarView.Month)"
                            [class.active]="view === CalendarView.Month"
                        >
                            Mes
                        </div>
                        <div
                            class="btn btn-primary background-primary"
                            (click)="setView(CalendarView.Week)"
                            [class.active]="view === CalendarView.Week"
                        >
                            Semana
                        </div>
                        <div
                            class="btn btn-primary background-primary"
                            (click)="setView(CalendarView.Day)"
                            [class.active]="view === CalendarView.Day"
                        >
                            Día
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div [ngSwitch]="view">
                <mwl-calendar-month-view
                    [weekStartsOn]="1"
                    *ngSwitchCase="CalendarView.Month"
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    [activeDayIsOpen]="activeDayIsOpen"
                    (dayClicked)="dayClicked($event.day)"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    [locale]="locale"

                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                    *ngSwitchCase="CalendarView.Week"
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                    *ngSwitchCase="CalendarView.Day"
                    [viewDate]="viewDate"
                    [events]="events"
                    [refresh]="refresh"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                >
                </mwl-calendar-day-view>
            </div>


        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
