import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {take, takeUntil} from 'rxjs/operators';
import {UsuarioDeportistaService} from '../../../services/usuario-deportista.service';
import {ActivatedRoute, Router} from '@angular/router';
import { EncryptService } from 'src/app/shared/encrypt';

@Component({
  selector: 'app-actualizar-password',
  templateUrl: './actualizar-password.component.html',
  styleUrls: ['./actualizar-password.component.scss']
})
export class ActualizarPasswordComponent implements OnInit {

    public formActualizarPassword: FormGroup ;
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    token = null;
  constructor(
      private fb: FormBuilder,
      private usuarioDeportistaService: UsuarioDeportistaService,
      private router: Router,
      private activatedroute: ActivatedRoute,
      private encryptService: EncryptService
  ) {

    this.activatedroute.queryParams.subscribe(params => {
        this.token = this.encryptService.decryptData(decodeURIComponent(params.token.replace(/\ /g, '+')));

        const fechaToken = new Date(this.token.fecha); // Primera fecha en milisegundos
        const fechaActual = new Date(); // Segunda fecha, usando la fecha actual

        const differenceInMillis = fechaActual.getTime() - fechaToken.getTime();
        const differenceInSeconds = Math.floor(differenceInMillis / 1000);
        const differenceInMinutes = Math.floor(differenceInSeconds / 60);
        if(differenceInMinutes>59){
            Swal.fire(
                'Solicitud expirada',
                'La duración para realizar el proceso de recuperación de contraseña es 1 hora, si esta expirada esta solicitud, debes realizar el proceso nuevamente.',
                'error'
            );
            this.router.navigate(['/recover-password']);

        }

});
   }

  ngOnInit(): void {
    
      this.initForm();
  }

    initForm() {
        this.formActualizarPassword = this.fb.group({
            password: ['', Validators.required],
            repeatPassword: ['', Validators.required],
            idUsuario: [this.token.usuario.id]
        });

    }

    actualizarContrasena(){
        if (!this.formActualizarPassword.valid){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Te faltan datos que agregar',
                'error'
            );
            return;
        }

        if (this.formActualizarPassword.get('password').value !== this.formActualizarPassword.get('repeatPassword').value){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Las dos contraseñas deben ser iguales!',
                'error'
            );
            return;
        }

        this.loading = true;

        const petition = this.usuarioDeportistaService.actualizarContrasenaPorCorreo(this.formActualizarPassword.value);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.loading = false;

                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    swalWithBootstrapButtons.fire({
                        title: 'Muy bien!',
                        text: 'Actualizaste tu contraseña de manera exitosa, ahora puedes iniciar sesión',
                        showCancelButton: false,
                        confirmButtonText: 'Iniciar sesión',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.router.navigate(['/login']);
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            this.router.navigate(['/']);
                        }
                    });
                },
                err => {


                        if (err) {
                            Swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: JSON.parse(err.error).message,
                            })
                
                          }

                    this.loading = false;
                });
    }
}
