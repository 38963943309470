import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {EncryptService} from '../shared/encrypt';



@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {

  constructor(
      private route: Router,
      private encryptService: EncryptService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {

        const dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));

        if (dataUser) {
                obs.next(true);

        } else {
                this.route.navigate(['/login']);
        }


    });
  }
}
