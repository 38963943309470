<app-navbar-style-three></app-navbar-style-three>

<div class="detail-inscription services-details-area pt-100 pb-70">
    <div class="container" >
        <div class="row" >
            <div class="col-lg-3" *ngIf="!loading">
                <div class="services-details-item">
                    <div class="services-details-order" *ngIf="rider.transaccionRider.estado === 'APROBADO'" style="background-color: green">
                        <h3>Inscripción</h3>
                        <span>#{{rider.id}}</span>
                        <ngx-qrcode
                        [elementType]="elementType"
                        [value]="url"
                        cssClass="aclass"
                        [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                    <div class="services-details-order" *ngIf="rider.transaccionRider.estado === 'PENDIENTE'" style="background-color: red">
                        <h3>Inscripción</h3>
                        <span>#{{rider.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                    <div class="services-details-order" *ngIf="rider.transaccionRider.estado === 'REVISION_TRANSFERENCIA'" style="background-color: orange">
                        <h3>Inscripción</h3>
                        <span>#{{rider.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>

                    <div class="services-details-order" *ngIf="rider.transaccionRider.estado === 'EXPIRADA'" style="background-color: red">
                        <h3>Inscripción</h3>
                        <span>#{{rider.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                </div>

                <div class="services-details-item" *ngIf="rider.transaccionRider.estado === 'APROBADO'" (click)="verTicket()" style="cursor: pointer;">
                        <div class="services-details-order" style="background-color: green">
                            <h3>Mi ticket</h3>

                            <img src="assets/img/boleto.png" style="width: 17%;margin-bottom: 26px;">
                            <br>
                        </div>


                </div>

                <div class="services-details-item">
                    <div class="">
                        <img [src]="rider.eventoRider.url_foto" alt="Service" style="margin-bottom: 16px;">

                        <br>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">
                <!-- <div class="services-details-item">
                    <div class="services-details-fresh">
                        <div class="title" *ngIf="rider.transaccionRider.estado === 'APROBADO' && !loading">
                            <br>
                            <i class='green bx bxs-check-circle'></i>
                            <h2>Gracias por su compra</h2>
                            <span>Esta confirmación será enviada por correo electrónico</span>
                        </div>
                        <br>

                        <div class="inscription-body" *ngIf="!loading" >
                            <h4>Información de pago:</h4>
                            <ul>
                                <li style="color:green" *ngIf="rider.transaccionRider.estado === 'APROBADO'"><strong>Estado de pago: </strong>APROBADO</li>
                                <li style="color:red" *ngIf="rider.transaccionRider.estado === 'PENDIENTE'"><strong>Estado de pago: </strong>PENDIENTE</li>
                                <li style="color:orange" *ngIf="rider.transaccionRider.estado === 'REVISION_TRANSFERENCIA'"><strong>Estado de pago: </strong>EN ESPERA DE RECEPCIÓN DE TRANSFERENCIA</li>
                                <li style="color:red" *ngIf="rider.transaccionRider.estado === 'EXPIRADA'"><strong>Estado de pago: </strong>EXPIRADA</li>
                                <li><strong>Monto inscripción: </strong>CLP${{rider.transaccionRider.monto_inscripcion}}</li>
                                <li><strong>Comisión inscripción: </strong>CLP${{rider.transaccionRider.monto_neto - rider.transaccionRider.monto_inscripcion}}</li>
                                <li><strong>Fecha de compra: </strong>{{rider.transaccionRider.createdAt| date : 'dd-MM-yyyy HH:mm:ss' }}</li>

                            </ul>
                            <h4>Información evento:</h4>
                            <ul>
                                <li><strong>Nombre evento: </strong>{{rider.eventoRider.nombre}}</li>
                                <li><strong>Fecha evento: </strong>{{rider.eventoRider.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}</li>
                                <li><strong>Hora citación: </strong>{{rider.eventoRider.hora_citacion}}</li>
                                <li><strong>Hora largada: </strong>{{rider.eventoRider.hora_largada}}</li>
                                <li *ngIf="rider.eventoRider.url_strava !== null"><strong>Strava: </strong>
                                    <a [href]="rider.eventoRider.url_strava" target="_blank">Ver ruta</a></li>

                            </ul>
                            <h4>Información deportista:</h4>
                            <ul>
                                <li><strong>Rider: </strong>{{rider.nombre}} {{rider.apellido}}</li>
                                <li><strong>DNI: </strong>{{rider.rut}}</li>
                                <li><strong>Categoria: </strong>{{rider.categoriaRider.nombre}} {{rider.categoriaRider.modo}} {{rider.categoriaRider.sexo}}</li>
                                <li><strong>Desafío: </strong>{{rider.categoriaRider.desafioEntity.nombre}}</li>

                            </ul>

                        </div>
                            
                        <br>
                        <div class="title" *ngIf="!loading">
                            <br>
                            <i class='yellow bx bxs-map'></i>
                            <h2>Ubicación evento</h2>
                        </div>
                        <br>
                        <div class="row">
                            <div id="mapa-mapbox" style="width: 100%; height: 300px;"></div>  
                        </div>
                        <br>
                        <div class="row" *ngIf="!loading">
                            <span style="color:red">“No hay distancia que no se pueda recorrer, ni meta que no se pueda alcanzar”</span>
                        </div>
                    </div>
                </div> -->

                <div class="content" *ngIf="!loading">
                    <div class="container">
            
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2">
                                <div class="invoice-content">
                                    <div class="invoice-item">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="invoice-logo">
                                                    <img src="assets/img/logoriderticket.png" alt="logo">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="invoice-details">
                                                    <strong>Inscripción:</strong> #{{rider.id}} <br>
                                                    <strong>Fecha:</strong> {{rider.transaccionRider.createdAt| date : 'dd-MM-yyyy HH:mm:ss'}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Invoice Item -->
                                    <div class="invoice-item">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="invoice-info">
                                                    <strong class="customer-text">Deportista</strong>
                                                    <p class="invoice-details invoice-details-two">
                                                        {{rider.nombre}} {{rider.apellido}}<br>
                                                        DNI: {{rider.rut}},<br>
                                                        Correo electrónico: {{rider.email}} <br>
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <div class="invoice-info invoice-info2">
                                                    <strong class="customer-text">Invoice To</strong>
                                                    <p class="invoice-details">
                                                        Walter Roberson <br>
                                                        299 Star Trek Drive, Panama City, <br>
                                                        Florida, 32405, USA <br>
                                                    </p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <!-- /Invoice Item -->
                                    
                                    <!-- Invoice Item -->
                                    <div class="invoice-item" *ngIf="rider.transaccionRider.estado === 'APROBADO'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="invoice-info">
                                                    <strong class="customer-text">Método de pago</strong>
                                                    <p class="invoice-details invoice-details-two">
                                                        {{rider.transaccionRider.method_payment}} <br>
                                                        <!-- XXXXXXXXXXXX-2541 <br> -->
                                                        {{rider.transaccionRider.platform}}<br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /Invoice Item -->
                                    
                                    <!-- Invoice Item -->
                                    <div class="invoice-item invoice-table-wrap" *ngIf="rider.transaccionRider.estado === 'APROBADO'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <table class="invoice-table table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Inscripción</th>
                                                                <th class="text-center">Cantidad</th>
                                                                <th class="text-center">Valor</th>
                                                                <th class="text-right">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <ul>
                                                                        <li><strong>Nombre evento: </strong>{{rider.eventoRider.nombre }}</li>
                                                                        <li><strong>Fecha evento: </strong>{{rider.eventoRider.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}</li>
                                                                        <li><strong>Hora citación: </strong>{{rider.eventoRider.hora_citacion}}</li>
                                                                        <li><strong>Hora largada: </strong>{{rider.eventoRider.hora_largada}}</li>
                                                                    </ul>
                                                                    <hr>
                                                                    <ul>
                                                                        <li><strong>Deportista: </strong>{{rider.nombre}} {{rider.apellido}}</li>
                                                                        <li><strong>DNI: </strong>{{rider.rut}}</li>
                                                                        <li><strong>Categoria: </strong>{{rider.categoriaRider.nombre}} {{rider.categoriaRider.modo}} {{rider.categoriaRider.sexo}}</li>
                                                                        <li><strong>Desafío: </strong>{{rider.categoriaRider.desafioEntity.nombre}}</li>
                                        
                                                                    </ul>
                                                                </td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-center">${{rider.transaccionRider.monto_inscripcion}}</td>
                                                                <td class="text-right">${{rider.transaccionRider.monto_inscripcion}}</td>
                                                            </tr>
                                                            <!-- <tr>
                                                                <td>Video Conference</td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-center">$0</td>
                                                                <td class="text-right">$250</td>
                                                            </tr> -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-xl-12">
                                                <div class="table-responsive">
                                                    <table class="invoice-table-two table">
                                                        <tbody>
                                                        <tr>
                                                            <th>Subtotal:</th>
                                                            <td><span>${{round(rider.transaccionRider.monto_inscripcion)}}</span></td>
                                                        </tr>
                                                       
                                                        <tr>
                                                            <th>Costo por transacción:</th>
                                                            <td><span>+${{round(rider.transaccionRider.monto - rider.transaccionRider.monto_inscripcion)}}</span></td>
                                                        </tr>
                                                        <tr >
                                                            <th>Total Pago:</th>
                                                            <td><span>${{round(rider.transaccionRider.monto_inscripcion + (rider.transaccionRider.monto - rider.transaccionRider.monto_inscripcion))}}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /Invoice Item -->
                                
            
                                    <div class="other-info" *ngIf="rider.transaccionRider.estado !== 'APROBADO'">
                                        <h4>Otra información</h4>
                                        <p class="text-muted mb-0">LA INSCRIPCIÓN NO ESTÁ APROBADA</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
            
                    </div>
            
                </div>    
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
