<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-three">
    <div class="container">
        <div class="page-title-item">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-location-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-time-five'></i>
                    <ul>
                        <li>9:00 AM to 12:00 AM</li>
                        <li>(Saturday to Thursday)</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item active">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-location-plus'></i>
                    <ul>
                        <li>Br1. 28/A Street, New York, USA</li>
                        <li>Br2. 31/B Street, Washington, USA</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-phone-call'></i>
                    <ul>
                        <li><a href="tel:+569552843950">(Branch 1) +569 552843950</a></li>
                        <li><a href="tel:+23256594596">(Branch 2) +2 325 659 4596</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-item">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <button type="submit" class="cmn-btn btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <div class="contact-social">
                        <span>Follow Us on</span>

                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="contact-img">
                    <img src="assets/img/contact-man.png" alt="Contact">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
