import {Component, OnInit} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {RankingService} from '../../../services/ranking.service';
import {CampeonatoService} from '../../../services/campeonato.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss']
})
export class RankingComponent implements OnInit {
    public id = 0;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    public resultados: any;
    public resultadosFilter: any = [];
    public categorias: any = [];
    public resultadosGroup: any[] = [];
    public searchCategoria = '0';
    public searchRider = '';

  constructor(
      private activatedroute: ActivatedRoute,
      private rankingService: RankingService,
      private campeonatoService: CampeonatoService,
  ) {
      this.activatedroute.queryParams.subscribe(params => {
          if (params.id !== undefined){
              this.id = Number(params.id);

          }
      });
  }

    ngOnInit(): void {
        this.rankingService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });
        this.getCategorias();
        // this.getDataDefault();
    }

    getCategorias() {
        this.loading = true;
        let petition = null;

        petition = this.campeonatoService.obtenerCategoriasPorCampeonatos(this.id);

        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.categorias = res[0];
                    this.getDataDefault();
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getDataDefault() {
        this.loading = true;
        let petition = null;

        petition = this.rankingService.obtenerRankingPorEvento(this.id);

        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.resultadosFilter = Object.assign({}, res[0]);
                    this.resultadosFilter = this.processRanking(this.resultadosFilter);
                    this.resultadosFilter.categorias = this.resultadosFilter.categorias.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

                    this.resultados = Object.assign({}, this.resultadosFilter);
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }


  getPuntajeFecha(rider: any, index: number){
      const fechas: any[] = rider.fechas;
      return fechas.find((fecha) => fecha.nombre.split('-')[1].includes('Fecha ' + index)) === undefined ? '-'
          : fechas.find((fecha) => fecha.nombre.split('-')[1].includes('Fecha ' + index)).puntos;
  }

  processRanking(rankings: any){
      rankings.categorias.forEach((categoria) => {
          const riders: any[] = [];
          categoria.riders.forEach((rider) => {
              const find = riders.filter((riderFind) => riderFind.usuario_id ===
                  rider.usuario_id)[0];
              if (find === undefined){
                    riders.push(rider);
                }else{
                    find.puntaje_total += rider.puntaje_total;
                    find.fechas.push(...rider.fechas);
              }

          });

          categoria.riders = riders;
          categoria.riders = categoria.riders.sort((a, b) => b.puntaje_total > a.puntaje_total ? 1 : -1);
          categoria.riders.forEach((rider, index) => {
              rider.lugar = index + 1;
          });
      });

      return rankings;

  }

    changeSearchCategoria(){
      this.searchRider = '';
      if (this.searchCategoria === '0'){
          this.resultadosFilter.categorias =
              this.resultados.categorias;
          this.resultadosFilter.categorias = this.resultadosFilter.categorias.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

      }else{
          this.resultadosFilter.categorias =
              this.resultados.categorias.filter((categoria) =>
                  `${categoria.nombre} - ${categoria.modo} - ${categoria.sexo}` === this.searchCategoria
              );
          this.resultadosFilter.categorias = this.resultadosFilter.categorias.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

      }

  }

    changeSearchRider(){
      this.searchCategoria = '0';

      this.resultadosFilter.categorias =
            this.resultados.categorias.filter((categoria) =>
                categoria.riders.filter((rider) =>
                    (rider.nombre.toLowerCase() + ' ' + rider.apellido.toLowerCase()).includes(this.searchRider.toLowerCase())).length > 0

            );

      this.resultadosFilter.categorias = this.resultadosFilter.categorias.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

    }

    filterRideres(riders: any[]){
      return riders.filter(rider =>
          (rider.nombre.toLowerCase() + ' ' + rider.apellido.toLowerCase()).includes(this.searchRider.toLowerCase()));
    }


}
