<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Noticias</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Noticias</li>
            </ul>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let noticia of noticias">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/noticia-details" [queryParams]="{ id: noticia.id}"><img [src]="noticia.url_imagen" alt="Blog"></a>
                        <span>{{noticia.fecha | date : 'dd-MM-yyyy' : 'UTC'}}</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/noticia-details" [queryParams]="{ id: noticia.id}">{{noticia.titulo}}</a></h3>
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>-->
                        <a class="cmn-btn" routerLink="/noticia-details" [queryParams]="{ id: noticia.id}">Leer más</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
