<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Reserva números</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Reserva números</li>
            </ul>
        </div>
    </div>
</div>


<section class="collection-area collection-area-two pt-100 pb-70">
    <div class="container" *ngIf="!loading">
        <div class="section-title">
            <h2>Reserva números</h2>
        </div>

        
        <div class="row" >
            <ng-container *ngFor="let evento of eventos" >
                <div class="col-sm-6 col-lg-4" [ngClass]="[evento.estado]">
                    <div class="collection-item">
                        <div class="collection-top">
                            <img [src]="evento.url_foto" alt="Collection">


                        </div>

                        <div class="collection-bottom">
                            <h3 style="text-align: center">{{evento.nombre}}</h3>


                            <ul style="align-items: center">
                                <li>
                                    <div class="number">

                                        <a class="cmn-btn" *ngIf="evento.reserva_placa === 'ACTIVADO'" routerLink="/inscripcion" [queryParams]="{ id: evento.id}">Reservar número</a>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

        </ng-container>
        </div>

    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>

