<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>{{dataUser.firstName}} {{dataUser.lastName}}</h3>

                        <ul>
                            <li><a routerLink="/profile">Mi Perfil <i class='bx bxs-user'></i></a></li>
                            <li><a routerLink="/mis-inscripciones">Mis inscripciones <i class='bx bx-task'></i></a></li>
                            <li><a routerLink="/mis-transacciones">Mis pagos <i class='bx bxs-credit-card'></i></a></li>
                            <li><a routerLink="/mis-estadisticas">Mis estadisticas <i class='bx bxs-bar-chart-alt-2'></i></a></li>
                            <li><a routerLink="/change-password">Contraseña <i class='bx bxs-key'></i></a></li>
                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>


                </div>
            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout-item ">
                                    <h2>Mi perfil</h2>

                                    <div class="checkout-one">
                                        <form [formGroup]="formUser">
                                            <div class="form-group">
                                                <label>Nombre: <span style="color:red">*</span></label>
                                                <input formControlName="firstName" type="text" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Apellido: <span style="color:red">*</span></label>
                                                <input formControlName="lastName" type="text" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Team:</label>
                                                <input formControlName="team" type="text" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Fec. Nac.: <span style="color:red">*</span></label>
                                                <input type="date" formControlName="birthDate" placeholder="Fecha evento" class="form-control-sm form-control mt5">
                                            </div>

                                            <div class="form-group">
                                                <label>Nacionalidad:</label>
                                                <input formControlName="nationality" type="text" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Teléfono: <span style="color:red">*</span></label>
                                                <input formControlName="phoneNumber" type="phone" class="form-control" (keyup)="formatPhone()" (keypress)="validatePhone($event)">
                                            </div>

                                            <div class="form-group">
                                                <label>RUT/Pasaporte:</label>
                                                <input formControlName="rut" type="text" class="form-control">

                                            </div>

                                            <div class="form-group">
                                                <input type="checkbox" [checked]="extranjero" (change)="changeExtranjero(!extranjero)"><label>&nbsp;&nbsp;Soy extranjero</label>

                                            </div>


                                            <span style="color:red">(*) Estos campos son obligatorios</span>

                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="text-center">
                            <button (click)="update()" class="btn cmn-btn">Actualizar información</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
