import { Component, OnInit } from '@angular/core';
import {interval, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {LocalStorageService} from '../../../shared/storage';
import {ActivatedRoute} from '@angular/router';
import {OrdenService} from '../../../services/orden.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-payment-transferencia',
  templateUrl: './payment-transferencia.component.html',
  styleUrls: ['./payment-transferencia.component.scss']
})
export class PaymentTransferenciaComponent implements OnInit {
    public paymentId = '';
    public loading = false;
    public amount = 0;

    private compDestroy: Subject<boolean> = new Subject();

    constructor(
        private ordenService: OrdenService,
        private localStorageAs: LocalStorageService,
        private activatedroute: ActivatedRoute,

    ) {
        this.activatedroute.queryParams.subscribe(params => {
            if (params.paymentId !== undefined){
                this.paymentId = params.paymentId;
            }

        });
    }

    ngOnInit(): void {
        this.getEstadoPago();
    }

    getEstadoPago(){

        this.loading = true;

        const petition = this.ordenService.obtenerOrden(this.paymentId);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.amount = res.montoTotal;
                    this.loading = false;

                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getEnvironmentAdSense(){
        return environment.adSenseClient;
    }

}
