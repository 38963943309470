<app-navbar-style-three></app-navbar-style-three>

<app-banner-custom *ngIf="!loading" [urlBanner]="evento.url_banner" [eventName]="'Resultados: '+evento.nombre"
    routerOne="/" navigationOne="Inicio" [navigationTwo]="evento.nombre"></app-banner-custom>

<section class="cart-area ptb-100">
    <div class="container">
        <div class="cart-wrap" *ngIf="!loading">
            <div class="row" *ngIf="this.resultados.length>0">
                <div class="col-lg-4">
                    <div class="form-group">
                        <h4>Buscar por categoría:</h4>
                        <select class="form-control" [(ngModel)]="searchCategoria" (change)="changeSearchCategoria()">
                            <option value="0">Todas</option>
                            <option *ngFor="let categoria of categorias">{{categoria.nombre}} -
                                {{categoria.desafioEntity.nombre }} - {{categoria.modo}} - {{categoria.sexo}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group">
                        <h4>Buscar por desafio:</h4>
                        <select class="form-control" [(ngModel)]="searchDesafio" (change)="changeSearchDesafio()">
                            <option value="TODOS">Todos</option>
                            <option *ngFor="let desafio of desafios" value={{desafio.nombre}}>{{desafio.nombre}}
                            </option>

                        </select>
                    </div>
                </div>

                <div class="col-lg-4 mg-top-20">
                    <div class="form-group">
                        <h4>Buscar por competidor / Dorsal:</h4>
                        <input (keyup)="changeSearch()" [(ngModel)]="search" type="text" class="form-control"
                            placeholder="Competidor">
                    </div>
                </div>

            </div>
            <br>

            <section class="services-area services-area-four" *ngIf="searchCategoria !== '0' && resultados.length>0">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 col-lg-4"
                            *ngIf="resultadosFilter[0] !== undefined && resultadosFilter[0].rider !== null && resultadosFilter[0].tiempo !== null">
                            <div class="services-item">
                                <a>
                                    <div class="primer-lugar">
                                        <div><i class='bx bxs-trophy'></i></div>
                                    </div>
                                    <img class="service-shape" src="assets/img/home-one/services-shape.png"
                                        alt="Service">
                                    <h3>
                                        {{resultadosFilter[0].rider.nombre}} {{resultadosFilter[0].rider.apellido}}<br>
                                        <span *ngIf="resultadosFilter[0].rider.dor !== null">DORSAL:
                                            {{resultadosFilter[0].rider.dor}}</span>

                                    </h3>
                                    <p>{{resultadosFilter[0].tiempo}}
                                        <br>
                                        <span style="color:red">Dif: <i class='bx bxs-up-arrow-alt'></i>
                                            {{hrFormat(secondDif(hrToSec(resultadosFilter[0].tiempo),
                                            hrToSec(getTopCategory(resultadosFilter[0].categoriaRider).tiempo)))}}</span>
                                    </p>
                                </a>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4"
                            *ngIf="resultadosFilter[1] !== undefined && resultadosFilter[1].rider !== null && resultadosFilter[1].tiempo !== null">
                            <div class="services-item">
                                <a>
                                    <div class="segundo-lugar">
                                        <div><i class='bx bxs-trophy'></i></div>
                                    </div>
                                    <img class="service-shape" src="assets/img/home-one/services-shape.png"
                                        alt="Service">
                                    <h3>{{resultadosFilter[1].rider.nombre}} {{resultadosFilter[1].rider.apellido}}
                                        <br>
                                        <span *ngIf="resultadosFilter[1].rider.dor !== null">DORSAL:
                                            {{resultadosFilter[1].rider.dor}}</span>
                                    </h3>
                                    <p>{{resultadosFilter[1].tiempo}}
                                        <br>
                                        <span style="color:red">Dif: <i class='bx bxs-up-arrow-alt'></i>
                                            {{hrFormat(secondDif(hrToSec(resultadosFilter[1].tiempo),
                                            hrToSec(getTopCategory(resultadosFilter[1].categoriaRider).tiempo)))}}</span>
                                    </p>
                                </a>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-4"
                            *ngIf="resultadosFilter[2] !== undefined && resultadosFilter[2].rider !== null && resultadosFilter[2].tiempo !== null">
                            <div class="services-item">
                                <a>
                                    <div class="tercer-lugar">
                                        <div><i class='bx bxs-trophy'></i></div>
                                    </div>
                                    <img class="service-shape" src="assets/img/home-one/services-shape.png"
                                        alt="Service">
                                    <h3>{{resultadosFilter[2].rider.nombre}} {{resultadosFilter[2].rider.apellido}}
                                        <br>
                                        <span *ngIf="resultadosFilter[2].rider.dor !== null">DORSAL:
                                            {{resultadosFilter[2].rider.dor}}</span>
                                    </h3>
                                    <p>{{resultadosFilter[2].tiempo}}
                                        <br>
                                        <span style="color:red">Dif: <i class='bx bxs-up-arrow-alt'></i>
                                            {{hrFormat(secondDif(hrToSec(resultadosFilter[2].tiempo),
                                            hrToSec(getTopCategory(resultadosFilter[2].categoriaRider).tiempo)))}}</span>
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br>
            <table class="table" *ngIf="this.resultados.length>0">
                <thead class="thead">
                    <tr>
                        <th class="table-head" scope="col" style="width: 10%">Pos. Gen.</th>
                        <th class="table-head" scope="col" style="width: 10%">Pos. Sexo.</th>
                        <th class="table-head" scope="col" style="width: 10%">Pos. Cat.</th>
                        <th class="table-head" scope="col">Competidor</th>
                        <th class="table-head" scope="col">Velocidad Promedio</th>
                        <th class="table-head" scope="col">Tiempo</th>
                    </tr>
                </thead>

                <tbody>
                    <ng-container *ngFor="let resultado of resultadosFilter">
                        <tr >
                            <td style="width: 10%"
                                *ngIf="resultado.estado_tiempo === 'DNF' && resultado.estado_tiempo !== 'DNS'">DNF</td>
                            <td style="width: 10%"
                                *ngIf="resultado.estado_tiempo === 'DNS' && resultado.estado_tiempo !== 'DNF'">DNS</td>
                            <td style="width: 10%" *ngIf="resultado.estado_tiempo === 'NORMAL'">{{resultado.posicion_g}}
                            </td>

                            <td style="width: 10%"
                                *ngIf="resultado.estado_tiempo === 'DNF' && resultado.estado_tiempo !== 'DNS'">DNF</td>
                            <td style="width: 10%"
                                *ngIf="resultado.estado_tiempo === 'DNS' && resultado.estado_tiempo !== 'DNF'">DNS</td>
                            <td style="width: 10%" *ngIf="resultado.estado_tiempo === 'NORMAL'">
                                {{resultado.posicion_g_sexo}}</td>

                            <td style="width: 10%"
                                *ngIf="resultado.estado_tiempo === 'DNF'  && resultado.estado_tiempo !== 'DNS'">DNF</td>
                            <td style="width: 10%"
                                *ngIf="resultado.estado_tiempo === 'DNS'  && resultado.estado_tiempo !== 'DNF'">DNS</td>
                            <td style="width: 10%" *ngIf="resultado.estado_tiempo === 'NORMAL'">{{resultado.posicion_c}}
                            </td>

                            <td>
                                {{resultado.rider.nombre}} {{resultado.rider.apellido}}
                                <br *ngIf="resultado.rider.dor !== null">
                                <span *ngIf="resultado.rider.dor !== null">DORSAL: {{resultado.rider.dor}}</span>
                                <br>
                                <span *ngIf="resultado.rider.team !== null"
                                    style="font-size: 12px; font-weight: bold; color: grey; word-wrap: break-word;">{{resultado.rider.team}}</span>
                                <br *ngIf="resultado.rider.team !== null">
                                <span
                                    style="font-size: 12px; font-weight: bold; color: grey; word-wrap: break-word;">{{resultado.categoriaRider.nombre}}
                                    - {{ resultado.categoriaRider.desafioEntity.nombre}} -
                                    {{resultado.categoriaRider.modo}}</span>
                                    <button *ngIf="resultado.estado_tiempo !== 'DNS' && evento.servicio_cronometraje === 'ACTIVADO'" (click) = "generarCertificado(resultado)" class="btn cmn-btn">
                                        Certificado participación
                                      
                                       
    
                                    </button>
                            </td>
                            <td>
                                <ng-container
                                    *ngIf="resultado.tiempo !== null && configuracionGiro(resultado) !== undefined">
                                    {{calculoVelocidadPromedio(resultado)}} Km/Hr
                                </ng-container>
                            </td>


                            <td>
                                <span *ngIf="resultado.estado_tiempo === 'DNF'">
                                    DNF
                                </span>
                                <span *ngIf="resultado.estado_tiempo === 'DNS'">
                                    DNS
                                </span>
                                <span *ngIf="resultado.estado_tiempo === 'NORMAL'">
                                    {{resultado.tiempo}}
                                </span>
                                <ng-container *ngIf="resultado.estado_tiempo === 'NORMAL'">
                                    <br>
                                    <span style="color:red">Dif: <i class='bx bxs-up-arrow-alt'></i>
                                        {{hrFormat(secondDif(hrToSec(resultado.tiempo),
                                        hrToSec(getTopCategory(resultado.categoriaRider).tiempo)))}}</span>
                                </ng-container>
                                <button (click)="mostrarDetalle(resultado)" *ngIf="resultado.estado_tiempo === 'NORMAL' && resultado.tiempo_giro_1 !== null && resultado.categoriaRider.giros > 1" class="btn cmn-btn">
                                    <span *ngIf="resultado.collapsable === false">Ver</span>
                                    <span *ngIf="resultado.collapsable === true">Ocultar</span> 
                                    detalles

                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="resultado.collapsable === true && resultado.categoriaRider.giros > 1">
                            <td colspan="6">
                                <table class="table">
                                    <thead class="thead">
                                        <tr>
                                            <th class="table-head-giros" scope="col" style="width: 10%">Mejor giro</th>
                                            <th *ngFor="let numero of obtenerCantidadGiros(resultado)"
                                                class="table-head-giros" scope="col" style="width: 10%">#{{numero}}</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td style="width: 10%">
                                                <span *ngIf="encontrarGiroMenor(resultado) !== 0">
                                                    {{encontrarHoraMenor(resultado)}}
                                                    ({{encontrarGiroMenor(resultado)}})
                                                </span>
                                                <span *ngIf="encontrarGiroMenor(resultado) === 0">
                                                    -
                                                </span>
                                            </td>
                                            <td *ngFor="let numero of obtenerCantidadGiros(resultado)"
                                                style="width: 10%">
                                                <span *ngIf="obtenerTiempoGiro(resultado, numero) !== null">
                                                    {{obtenerTiempoGiro(resultado, numero)}}
                                                </span>
                                                <span *ngIf="obtenerTiempoGiro(resultado, numero) === null">
                                                    -
                                                </span>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>

            <hr>
            <div class="row" *ngIf="evento.url_resultados !== null && evento.url_resultados !== ''">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h4>¿Quieres saber más detalles acerca de los resultados?</h4>
                        <br>
                        <!--                        <h4>Resultados en formato PDF 👈</h4>-->
                        <iframe [src]="sanitizeUrl(evento.url_resultados)" width="100%" height="600px"
                            allow="autoplay"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading"></app-preloader>