<app-navbar-style-three></app-navbar-style-three>


<section class="checkout-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="checkout-item ">
                    <h2>Ingresa tu nueva contraseña</h2>

                    <div class="checkout-one">
                        <form [formGroup]="formActualizarPassword">

                            <div class="form-group">
                                <label>Nueva Contraseña:</label>
                                <input formControlName="password" type="password" class="form-control">
                            </div>

                            <div class="form-group">
                                <label>Repita contraseña:</label>
                                <input formControlName="repeatPassword" type="password" class="form-control">
                            </div>

                        </form>
                    </div>
                </div>
            </div>


        </div>

        <div class="text-center">
            <button (click)="actualizarContrasena()" class="btn cmn-btn">Actualizar contraseña</button>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
