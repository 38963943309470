import { Component, OnInit } from '@angular/core';
import {interval, Subject} from 'rxjs';
import {TransaccionService} from '../../../services/transaccion.service';
import {take, takeUntil} from 'rxjs/operators';
import {LocalStorageService} from '../../../shared/storage';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
    public token = '';
    public paymentType = '';

    loading = true;
    pagoConfirmado = false;
    pagoFallido = false;
    response = {
        estado : false,
        receiptUrl : '',
        amount: 0
    };

    private compDestroy: Subject<boolean> = new Subject();

    constructor(
        private transaccionService: TransaccionService,
        private localStorageAs: LocalStorageService,
        private activatedroute: ActivatedRoute,

    ) {
        this.activatedroute.queryParams.subscribe(params => {
            if (params.token_ws !== undefined){
                this.token = params.token_ws;
            }

        });
    }

    ngOnInit(): void {
        this.paymentType = localStorage.getItem('paymentType');
        interval(4000).subscribe(x => {
            if (!this.pagoConfirmado && !this.pagoFallido){

                if (this.token.length > 1){
                    this.getEstadoPagoWebpay();
                }else{
                    this.getEstadoPago();
                }

            }
        });
    }

    getEstadoPago(){
        const paymentId = localStorage.getItem('paymentId');

        this.loading = true;

        const petition = this.transaccionService.obtenerTransaccion(paymentId);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.response = res;
                    if (res.approved === true){
                        this.pagoConfirmado = true;
                        this.loading = false;
                    }
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getEstadoPagoWebpay(){
        const paymentId = localStorage.getItem('paymentId');

        this.loading = true;

        const petition = this.transaccionService.obtenerTransaccionWebpay(paymentId);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.response = res;
                    if (res.response_code === 0){
                        this.pagoConfirmado = true;
                        this.loading = false;
                    }

                    if (res.status === 'FAILED'){
                        this.pagoFallido = true;
                        this.loading = false;
                    }

                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

}
