import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../../shared/storage';
import {EncryptService} from '../../../shared/encrypt';

@Component({
  selector: 'app-navbar-style-three',
  templateUrl: './navbar-style-three.component.html',
  styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {

    eventos = 0;
    public dataUser = null;

    constructor(
        private localStorageAs: LocalStorageService,
        private encryptService: EncryptService
    ) { }

    ngOnInit(): void {
        this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));

        this.localStorageAs.watch('carro_compra').subscribe(carroCompra => {

            if (carroCompra !== null){
                this.eventos = (JSON.parse(carroCompra).adicionales === undefined ? 0 : JSON.parse(carroCompra).adicionales.length) + ( JSON.parse(carroCompra).transacciones === undefined ? 0 : JSON.parse(carroCompra).transacciones.length);

            }else{
                this.eventos = 0;
            }
        });
    }
}
