import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {RiderService} from '../../../services/rider.service';
import {Router} from '@angular/router';
import {EncryptService} from '../../../shared/encrypt';
import {GoogleAnalytics} from '../../../shared/google-analytics';

@Component({
  selector: 'app-profile',
  templateUrl: './inscriptions.component.html',
  styleUrls: ['./inscriptions.component.scss']
})
export class InscriptionsComponent implements OnInit {

    public loading = false;
    public dataUser = null;
    private compDestroy: Subject<boolean> = new Subject();
    public inscripciones = [];
  constructor(private riderService: RiderService,
              private router: Router,
              private encryptService: EncryptService,
              private googleAnalytics: GoogleAnalytics

  ) { }

  ngOnInit(): void {
      this.googleAnalytics.eventEmitter('mis-inscripciones', 'exitoso', '', 'click', 0);

      this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));

      this.getInscription();
  }

  getInscription(){
          this.loading = true;

          const petition = this.riderService.obtenerInscripciones(this.dataUser.id);

          petition
              .pipe(take(1), takeUntil(this.compDestroy))
              .subscribe(
                  res => {
                      this.inscripciones = [];
                      this.inscripciones = res;
                      this.loading = false;

                  },
                  err => {
                      this.loading = false;
                  });

  }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();

        this.router.navigate(['/']);
    }
}
