import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/common/preloader/preloader.component';
import {NavbarStyleOneComponent} from './components/common/navbar-style-one/navbar-style-one.component';
import {HomeOneComponent} from './components/pages/home-one/home-one.component';
import {HomeTwoComponent} from './components/pages/home-two/home-two.component';
import {HomeThreeComponent} from './components/pages/home-three/home-three.component';
import {FooterStyleOneComponent} from './components/common/footer-style-one/footer-style-one.component';
import {FooterStyleTwoComponent} from './components/common/footer-style-two/footer-style-two.component';
import {NavbarStyleTwoComponent} from './components/common/navbar-style-two/navbar-style-two.component';
import {AboutComponent} from './components/pages/about/about.component';
import {ServicesComponent} from './components/pages/services/services.component';
import {ServicesDetailsComponent} from './components/pages/services-details/services-details.component';
import {BlogDetailsComponent} from './components/pages/blog-details/blog-details.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {FoodCollectionComponent} from './components/pages/food-collection/food-collection.component';
import {OnlineOrderComponent} from './components/pages/online-order/online-order.component';
import {ChefsComponent} from './components/pages/chefs/chefs.component';
import {BookTableComponent} from './components/pages/book-table/book-table.component';
import {CartComponent} from './components/pages/cart/cart.component';
import {CheckoutComponent} from './components/pages/checkout/checkout.component';
import {ComingSoonComponent} from './components/pages/coming-soon/coming-soon.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {TermsConditionsComponent} from './components/pages/terms-conditions/terms-conditions.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {NavbarStyleThreeComponent} from './components/common/navbar-style-three/navbar-style-three.component';
import {EventoService} from './services/evento.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {EventDetailsComponent} from './components/pages/event-details/event-details.component';
import {EventsComponent} from './components/pages/events/events.component';
import {CategoriaService} from './services/categoria.service';
import {InscriptionComponent} from './components/pages/inscription/inscription.component';
import {RiderService} from './services/rider.service';
import {TransaccionService} from './services/transaccion.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SafePipe} from './pipes/safe.pipe';
import {PaymentComponent} from './components/pages/payment/payment.component';
import {ResultsComponent} from './components/pages/results/results.component';
import {ResultadoService} from './services/resultado.service';
import {EventResultsComponent} from './components/pages/event-results/event_results.component';
import {EncryptService} from './shared/encrypt';
import {JwtInterceptor} from './services/jwt.interceptor';
import {NoticiaComponent} from './components/pages/noticias/noticia.component';
import {NoticiaDetailsComponent} from './components/pages/noticias-details/noticia-details.component';
import {NoticiaService} from './services/noticia.service';
import {ShoppingCartComponent} from './components/pages/shopping-cart/shopping-cart.component';
import {LocalStorageService} from './shared/storage';
import {RankingService} from './services/ranking.service';
import {RankingComponent} from './components/pages/ranking/ranking.component';
import {CampeonatoResultsComponent} from './components/pages/campeonato-results/campeonato_results.component';
import {CampeonatoService} from './services/campeonato.service';
import {LoginComponent} from './components/pages/login/login.component';
import {UsuarioDeportistaService} from './services/usuario-deportista.service';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ChangePasswordComponent} from './components/pages/change-password/change-password.component';
import {InscriptionDetailsComponent} from './components/pages/inscription-details/inscription-details.component';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {TransactionsComponent} from './components/pages/transactions/transactions.component';
import {TransactionDetailsComponent} from './components/pages/transaction-details/transaction-details.component';
import {InscriptionsComponent} from './components/pages/inscriptions/inscriptions.component';
import {DatePipe, registerLocaleData} from '@angular/common';
import {UsuarioOrganizadorService} from './services/usuario-organizador.service';
import {PhoneMaskService} from './shared/phone-mask';
import {MathHelper} from './shared/math_helper';
import {InscriptionsEventsComponent} from './components/pages/inscriptions-events/inscriptions-events.component';
import {PaymentTransferenciaComponent} from './components/pages/payment-transferencia/payment-transferencia.component';
import {OrdenService} from './services/orden.service';
import {ServiciosComponent} from './components/pages/servicios/servicios.component';
import {FechasDisponiblesComponent} from './components/pages/fechas-disponibles/fechas-disponibles.component';

import {CuponService} from './services/cupon.service';
import {BannerCustomComponent} from './components/common/banner-custom/banner-custom.component';
import {Ng9RutModule} from 'ng9-rut';
import {CambioCategoriaComponent} from './components/pages/cambio-categoria/cambio-categoria.component';
import {SolicitudCambioCategoriaService} from './services/solicitud-cambio-categoria.service';
import {MisEstadisticasComponent} from './components/pages/mis-estadisticas/mis-estadisticas.component';
import {NgChartsModule} from 'ng2-charts';
import {PublicidadService} from './services/publicidad.service';
import {AgendaService} from './services/agenda.service'; // a plugin!
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import localeEs from '@angular/common/locales/es';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import {environment} from '../environments/environment';
import {CotizacionService} from './services/cotizacion.service';
import { NgxPayPalModule } from 'ngx-paypal';
import {CurrencyService} from './services/currency.service';
import {AdsenseModule} from 'ng2-adsense';
import {EventAdicionalesComponent} from './components/pages/event-adicionales/event-adicionales.component';
import {AdicionalComponent} from './components/pages/adicional/adicional.component';
import {StringHelper} from './shared/string_helper';
import {AdicionalFormComponent} from './components/pages/adicional-form/adicional-form.component';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import { CountdownTimerComponent } from './components/common/countdown-timer/countdown-timer.component';
import { ActualizarPasswordComponent } from './components/pages/actualizar-password/actualizar-password.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { ClienteService } from './services/cliente.service';
import { AuspiciadorService } from './services/auspiciador.service';
import { MediosPagoService } from './services/medios-pago.service';
import { TicketComponent } from './components/pages/ticket/ticket.component';
import { EventoItemComponent } from './components/common/evento-item/evento-item.component';
import { ReservaPlacaService } from './services/reserva-placa.service';
import { EventosReservaNumerosComponent } from './components/pages/eventos-reserva-numeros/eventos-reserva-numeros.component';
import { ReservarNumeroComponent } from './components/pages/reservar-numero/reservar-numero.component';
import { RegionService } from './services/region.service';
import { CertificadoHelper } from './shared/certificado_helper';
import { BancoService } from './services/banco.service';
import { TipoCuentaService } from './services/tipo-cuenta.service';
import { SolicitudDevolucionComponent } from './components/pages/solicitud-devolucion/solicitud-devolucion.component';
import { DevolucionService } from './services/devolucion.service';
import { IpService } from './services/ip.service';
import { DesafioService } from './services/desafio.service';
const config: SocketIoConfig = { url: `${environment.api}`, options: {transports: ['websocket']} };


registerLocaleData(localeEs);

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarStyleOneComponent,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        FooterStyleOneComponent,
        FooterStyleTwoComponent,
        NavbarStyleTwoComponent,
        BannerCustomComponent,
        AboutComponent,
        ServicesComponent,
        ServicesDetailsComponent,
        EventDetailsComponent,
        EventsComponent,
        BlogDetailsComponent,
        BlogComponent,
        FoodCollectionComponent,
        OnlineOrderComponent,
        ChefsComponent,
        BookTableComponent,
        CartComponent,
        CheckoutComponent,
        ComingSoonComponent,
        FaqComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ErrorComponent,
        ContactComponent,
        NavbarStyleThreeComponent,
        InscriptionComponent,
        SafePipe,
        PaymentComponent,
        ResultsComponent,
        EventResultsComponent,
        NoticiaComponent,
        NoticiaDetailsComponent,
        ShoppingCartComponent,
        RankingComponent,
        CampeonatoResultsComponent,
        LoginComponent,
        ProfileComponent,
        ChangePasswordComponent,
        InscriptionDetailsComponent,
        TransactionsComponent,
        TransactionDetailsComponent,
        InscriptionsComponent,
        InscriptionsEventsComponent,
        PaymentTransferenciaComponent,
        ServiciosComponent,
        FechasDisponiblesComponent,
        CambioCategoriaComponent,
        MisEstadisticasComponent,
        EventAdicionalesComponent,
        AdicionalComponent,
        AdicionalFormComponent,
        CountdownTimerComponent,
        RecoverPasswordComponent,
        ActualizarPasswordComponent,
        TicketComponent,
        EventoItemComponent,
        EventosReservaNumerosComponent,
        ReservarNumeroComponent,
        SolicitudDevolucionComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxQRCodeModule,
        Ng9RutModule,
        NgChartsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        BrowserAnimationsModule,
        NgxPayPalModule,
        AdsenseModule.forRoot({
            adClient: environment.adSenseClient,
            adSlot: 8327470592,
        }),
        SocketIoModule.forRoot(config)
    ],
    providers: [
        DesafioService,
        IpService,
        EventoService,
        CategoriaService,
        RiderService,
        TransaccionService,
        ResultadoService,
        EncryptService,
        NoticiaService,
        LocalStorageService,
        CertificadoHelper,
        RankingService,
        CampeonatoService,
        UsuarioDeportistaService,
        UsuarioOrganizadorService,
        PhoneMaskService,
        DatePipe,
        MathHelper,
        StringHelper,
        OrdenService,
        CuponService,
        SolicitudCambioCategoriaService,
        PublicidadService,
        AgendaService,
        CotizacionService,
        CurrencyService,
        ClienteService,
        AuspiciadorService,
        MediosPagoService,
        ReservaPlacaService,
        RegionService,
        BancoService,
        TipoCuentaService,
        DevolucionService,
        { provide: 'WINDOW', useValue: window },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptchaKey,
            } as RecaptchaSettings,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
