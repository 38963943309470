import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RutValidator } from 'ng9-rut';
import { Observable, Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { RegionService } from 'src/app/services/region.service';
import Swal from 'sweetalert2';
import { CarroService } from '../../../services/carro.service';
import { CategoriaService } from '../../../services/categoria.service';
import { CuponService } from '../../../services/cupon.service';
import { EventoService } from '../../../services/evento.service';
import { RiderService } from '../../../services/rider.service';
import { TransaccionService } from '../../../services/transaccion.service';
import { EncryptService } from '../../../shared/encrypt';
import { PhoneMaskService } from '../../../shared/phone-mask';
import { LocalStorageService } from '../../../shared/storage';
import { DesafioService } from 'src/app/services/desafio.service';

@Component({
    selector: 'app-inscription',
    templateUrl: './inscription.component.html',
    styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {
    public id = 0;
    public loading = false;
    public loadingInfoInicial = false;
    public categoria: any = null;
    public evento: any;
    public cupon: any = null;
    private compDestroy: Subject<boolean> = new Subject();
    public formInscripcion: FormGroup;
    public formCupon: FormGroup;
    public formInscripcionPersona2: FormGroup;
    public dataUser = null;
    public mostrarRestriccionEdad = false;
    public errorRestriccionEdad = false;
    public extranjero1 = false;
    public extranjero2 = false;
    public adicionales = [];

    public adicionalesSeleccionados = [];

    // V2
    public categorias: any[] = [];
    public desafios = [];
    public tallasTricotas = [];
    public regiones = [];
    public ciudades = [];
    public ciudadesPersona2 = [];
    public dorsalesDisponibles = [];
    public dorObligatoria: string = null;
    public desafioEvento = [];


    constructor(private activatedroute: ActivatedRoute,
        private categoriaService: CategoriaService,
        private eventoService: EventoService,
        private riderService: RiderService,
        private fb: FormBuilder,
        private router: Router,
        private cuponService: CuponService,
        private localStorageAs: LocalStorageService,
        private encryptService: EncryptService,
        private phoneMask: PhoneMaskService,
        private rutValidator: RutValidator,
        private carroService: CarroService,
        private regionService: RegionService,
        private desafioService: DesafioService,


    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

    ngOnInit(): void {
        this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));
        this.categoriaService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getEventoYCategorias();
                    }
                });

        // this.eventoService.observableData$
        //     .subscribe(
        //         res => {
        //             if (res) {
        //                 this.getEvento();
        //             }
        //         });

        this.getEventoYCategorias();


    }

    getEventoYCategorias() {
        this.loadingInfoInicial = true;
        const obtenerCategoria = this.categoriaService.obtenerCategoriaByEventoId(this.id);
        const obtenerEvento = this.eventoService.obtenerEventoById(this.id);
        const obtenerAdicionalesByEventoId = this.eventoService.obtenerAdicionalesByEventoId(this.id);
        const obtenerDesafiosPorEventoId = this.eventoService.obtenerDesafiosPorEventoId(this.id);
        const obtenerDesafioEventoPorEventoId = this.desafioService.obtenerTodosDesafiosEvento(this.id);

        const petitions = forkJoin([obtenerCategoria, obtenerEvento, obtenerAdicionalesByEventoId, obtenerDesafiosPorEventoId, obtenerDesafioEventoPorEventoId]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.categorias = res[0];
                    // this.categoriasFilter = this.categorias;
                    this.evento = res[1];
                    if (this.evento.estado !== 'POR_REALIZAR') {
                        Swal.fire({
                            icon: 'warning',
                            title: "Inscripciones no habilitadas",
                            text: "Las inscripciones para el evento no se encuentran habilitadas, contacta al organizador del evento",
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: "Ver descripción del evento",
                            allowOutsideClick: false
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                this.router.navigate(['/evento'], { queryParams: { id: this.evento.id } });

                            }
                        });

                    }
                    this.adicionales = res[2].filter((adicional) => adicional.cupos > adicional.vendidos);
                    this.desafios = res[3];
                    this.desafioEvento = res[4];


                    this.initForm();
                    this.initFormPersona2();
                    this.initFormCupon();

                    this.changeExtranjero1(false);
                    this.changeExtranjero2(false);

                    if (this.dataUser) {
                        this.formInscripcion.get('nombre').setValue(this.dataUser.firstName);
                        this.formInscripcion.get('apellido').setValue(this.dataUser.lastName);
                        this.formInscripcion.get('fecha_nacimiento').setValue(this.dataUser.birthDate);
                        this.formInscripcion.get('email').setValue(this.dataUser.email);
                        this.formInscripcion.get('team').setValue(this.dataUser.team);
                        this.formInscripcion.get('phoneNumber').setValue(this.dataUser.phoneNumber);
                        this.formInscripcion.get('rut').setValue(this.dataUser.rut);
                        this.formInscripcion.get('typeOfDocument').setValue(this.dataUser.typeOfDocument);

                        if (this.dataUser.typeOfDocument === 'RUT') {
                            this.changeExtranjero1(false);
                        }

                        if (this.dataUser.typeOfDocument === 'passport') {
                            this.changeExtranjero1(true);
                        }
                    }

                    this.tallasTricotas = this.evento.tallasTricotas;

                    if (this.evento.solicitar_ciudad === 'ACTIVADO') {
                        this.obtenerRegiones();
                    } else {
                        if (this.evento.reserva_placa === 'ACTIVADO') {
                            this.getDorsalesDisponibles();
                        } else {
                            this.loadingInfoInicial = false;
                        }
                    }



                }


                ,
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }


    getDorsalesDisponibles() {

        const obtenerDorsalesDisponibles = this.riderService.obtenerDorsalesDisponibles(this.id);

        const petitions = forkJoin([obtenerDorsalesDisponibles]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.dorsalesDisponibles = res[0];
                    this.loadingInfoInicial = false;
                },
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }


    obtenerRegiones() {
        this.loadingInfoInicial = true;
        const obtenerRegion = this.regionService.obtenerRegion();


        const petitions = forkJoin([obtenerRegion]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.regiones = res[0];
                    if (this.evento.reserva_placa === 'ACTIVADO') {
                        this.getDorsalesDisponibles();
                    } else {
                        this.loadingInfoInicial = false;

                    }
                },
                err => {
                    console.log(err);
                    this.loadingInfoInicial = false;
                });
    }

    obtenerCiudades(form1: boolean) {
        // this.loadingInfoInicial = true;
        this.ciudades = [];

        const obtenerRegion = this.regionService.obtenerCiudadesPorRegion(Number(form1 ? this.formInscripcion.get('region').value : this.formInscripcionPersona2.get('region').value));


        const petitions = forkJoin([obtenerRegion]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (form1) {
                        this.ciudades = res[0];
                    } else {
                        this.ciudadesPersona2 = res[0];
                    }

                    // this.loadingInfoInicial = false;
                },
                err => {
                    console.log(err);
                    // this.loadingInfoInicial = false;
                });
    }

    initForm() {
        this.formInscripcion = this.fb.group({
            nombre: ['', Validators.required],
            apellido: ['', Validators.required],
            evento: [this.evento, Validators.required],
            fecha_nacimiento: [null, Validators.required],
            sexo: ['masculino', Validators.required],
            categoria: [null, Validators.required],
            dor: [null, this.evento.reserva_placa === 'ACTIVADO' ? [Validators.required] : []],
            federado: ['No federado', Validators.required],
            id_uci: [null],
            talla_tricota: [null, this.evento.solicitar_talla_tricota === 'ACTIVADO' ? [Validators.required] : []],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            typeOfDocument: ['RUT', Validators.required],
            phoneNumber: ['', [Validators.required, this.phoneValidator]],
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
            team: [null],
            desafio: ['0'],
            ciudad: [null, this.evento.solicitar_ciudad === 'ACTIVADO' ? [Validators.required] : []],
            region: [],
            ip: (this.localStorageAs.get('ip_connection') === null || this.localStorageAs.get('ip_connection') === undefined) ? null : this.localStorageAs.get('ip_connection')
        });

        this.formInscripcion.get('categoria').disable();
    }

    initFormCupon() {
        this.formCupon = this.fb.group({
            codigo: [null],
            eventoId: [this.id, Validators.required],
        });

    }

    obtenerDesafioSinCupo(categoria){
        if(this.desafioEvento.length === 0){
            return false;
        }

        const desafioEventoFind = this.desafioEvento.find((desafioEvento) => desafioEvento.desafio.id === categoria.desafioEntity.id);
        if(desafioEventoFind === undefined){
            return false;
        }

        if(desafioEventoFind.cupos <= desafioEventoFind.cantidad_inscritos){
            return true;
        }

        return false;
    }

    obtenerDesafioPorDesafioSinCupo(desafio){
        if(this.desafioEvento.length === 0){
            return false;
        }

        const desafioEventoFind = this.desafioEvento.find((desafioEvento) => desafioEvento.desafio.id === desafio.id);
        if(desafioEventoFind === undefined){
            return false;
        }

        if(desafioEventoFind.cupos <= desafioEventoFind.cantidad_inscritos){
            return true;
        }

        return false;
    }

    initFormPersona2() {
        this.formInscripcionPersona2 = this.fb.group({
            nombre: ['', Validators.required],
            apellido: ['', Validators.required],
            evento: [this.evento, Validators.required],
            fecha_nacimiento: [null, Validators.required],
            sexo: [null, Validators.required],
            categoria: [null, Validators.required],
            email: ['', Validators.required],
            dor: [''],
            phoneNumber: ['', [Validators.required, this.phoneValidator]],
            federado: ['No federado', Validators.required],
            id_uci: [null],
            talla_tricota: [null, this.evento.solicitar_talla_tricota === 'ACTIVADO' ? [Validators.required] : []],
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
            typeOfDocument: ['RUT', Validators.required],
            team: [null],
            desafio: ['0'],
            ciudad: [null, this.evento.solicitar_ciudad === 'ACTIVADO' ? [Validators.required] : []],
            region: [],
            ip: (this.localStorageAs.get('ip_connection') === null || this.localStorageAs.get('ip_connection') === undefined) ? null : this.localStorageAs.get('ip_connection')
        });
    }

    porcentajePreventa() {
        const preventa = this.evento.preventasRider.find(
            (preventaFind) =>
                preventaFind.cantidadMinima <= this.evento.cantidad_inscritos &&
                preventaFind.cantidadMaxima >= this.evento.cantidad_inscritos,
        );


        let porcentajeAumento = 1;
        if (preventa) {
            porcentajeAumento = (1 + (preventa.porcentajeAumento / 100));
        }

        return porcentajeAumento;

    }

    initTransaccion() {
        this.loading = true;
        const compra = {
            fecha: Date.now(),
            transacciones: [
                {
                    rider: this.categoria.modo === 'duplas' ?
                        [this.formInscripcion.getRawValue(), this.formInscripcionPersona2.getRawValue()] :
                        [this.formInscripcion.getRawValue()],
                    monto: this.categoria.modo === 'individual' ?
                        Math.ceil(this.categoria.valor * this.porcentajePreventa()) :
                        Math.ceil(this.categoria.valor * this.porcentajePreventa()) * 2,
                    evento: this.formInscripcion.get('evento').value,
                    categoria: this.formInscripcion.get('categoria').value,
                    cupon: this.cupon
                }
            ],
            adicionales: this.generarAdicionales()
        };

        let transaccion: any[] = [];
        let adicional: any[] = [];
        if (JSON.parse(this.localStorageAs.get('carro_compra')) !== null) {
            adicional = JSON.parse(this.localStorageAs.get('carro_compra')).adicionales === undefined ? [] : JSON.parse(this.localStorageAs.get('carro_compra')).adicionales;
            transaccion = JSON.parse(this.localStorageAs.get('carro_compra')).transacciones === undefined ? [] : JSON.parse(this.localStorageAs.get('carro_compra')).transacciones;
        }
        compra.transacciones = compra.transacciones.concat(transaccion);
        compra.adicionales = compra.adicionales.concat(adicional);

        this.localStorageAs.set('carro_compra', JSON.stringify(compra));


        this.loading = false;

        this.router.navigate(['/carro']);
    }

    generarAdicionales() {
        const adicionalesReturn = [];
        this.adicionalesSeleccionados.forEach((adicional) => {
            adicionalesReturn.push(adicional.formAdicional);
        });

        return adicionalesReturn;
    }


    getMontoFinal() {
        return this.categoria.modo === 'individual' ?
            Math.ceil(this.categoria.valor * this.porcentajePreventa()) :
            Math.ceil(this.categoria.valor * this.porcentajePreventa()) * 2;
    }

    getMontoFinalAdicionales() {
        let total = 0;
        this.obtenerAdicionalValidado().forEach((adicional) => {
            total = total + adicional.formAdicional.eventoAdicional.precio;
        });
        return total;
    }

    getMontoCargosAdicionales() {
        let total = 0;
        this.obtenerAdicionalValidado().forEach((adicional) => {
            total = total + (adicional.formAdicional.eventoAdicional.precio * adicional.formAdicional.porcentaje_cobro);
        });
        return total;
    }

    getMontoCargos() {
        return this.getMontoFinal() * this.evento.configuracionPrecioRider.porcentaje_cobro;
    }

    changeCategoria() {
        const categoriaValidacion = this.obtenerCategoriasFiltradas().find((categoria) => categoria.id === Number(this.formInscripcion.get('categoria').value));
        if (categoriaValidacion === undefined) {
            return;
        }

        this.categoria = categoriaValidacion;

        if (this.categoria.modo === 'duplas') {
            if (this.categoria.sumatoriaMinima !== null && this.categoria.sumatoriaMaxima !== null) {
                this.mostrarRestriccionEdad = true;
            } else {
                this.mostrarRestriccionEdad = false;
            }

            this.formInscripcionPersona2.get('categoria').setValue(this.categoria.id);
            this.formInscripcionPersona2.get('federado').setValue(this.formInscripcion.get('federado').value);


            switch (this.categoria.sexo) {
                case 'Varones':
                    this.formInscripcionPersona2.get('sexo').setValue('masculino');
                    this.formInscripcionPersona2.get('sexo').disable();
                    break;
                case 'Damas':
                    this.formInscripcionPersona2.get('sexo').setValue('femenino');
                    this.formInscripcionPersona2.get('sexo').disable();
                    break;
                case 'Mixto':
                    if (this.formInscripcion.get('sexo').value === 'masculino') {
                        this.formInscripcionPersona2.get('sexo').setValue('femenino');
                    }

                    if (this.formInscripcion.get('sexo').value === 'femenino') {
                        this.formInscripcionPersona2.get('sexo').setValue('masculino');

                    }
                    break;
            }

            this.formInscripcionPersona2.get('categoria').disable();
        } else {
            this.formInscripcion.get('categoria').setValue(this.categoria.id);
            this.mostrarRestriccionEdad = false;
            this.errorRestriccionEdad = false;
        }
    }


    filtrarCategorias() {
        let fechaFin;
        let fechaInicio;
        let diferenciaAnoPersona1;

        switch (this.evento.type) {
            case 'motociclismo':
                fechaFin = new Date(this.evento.fecha_evento).getTime();

                fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value).getTime();
                diferenciaAnoPersona1 = Math.floor(((fechaFin - fechaInicio) / (1000 * 60 * 60 * 24)) / 365);

                if (this.formInscripcion.get('fecha_nacimiento').value !== null && this.formInscripcion.get('sexo').value !== null) {
                    if (this.obtenerCategoriasFiltradas().length > 0) {
                        this.formInscripcion.get('categoria').setValue(this.obtenerCategoriasFiltradas()[0].id);
                        this.changeCategoria();
                        // this.categoria = this.categorias.find((categoria) => categoria.id === Number(this.formInscripcion.get('categoria').value));
                        this.formInscripcion.get('categoria').enable();

                        if (this.categoria.modo === 'duplas') {
                            this.formInscripcionPersona2.get('desafio').setValue(this.formInscripcion.get('desafio').value);
                            this.formInscripcionPersona2.get('desafio').disable();
    
                        }

                    } else {

                        this.formInscripcion.get('categoria').setValue(null);
                        this.categoria = null;

                    }
                }
                break;
            default:
                fechaFin = new Date();

                fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value);
                diferenciaAnoPersona1 = Math.floor(fechaFin.getFullYear() - fechaInicio.getFullYear());


                if (this.formInscripcion.get('fecha_nacimiento').value !== null && this.formInscripcion.get('sexo').value !== null) {
                    if (this.obtenerCategoriasFiltradas().length > 0) {
                        this.formInscripcion.get('categoria').setValue(this.obtenerCategoriasFiltradas()[0].id);
                        this.changeCategoria();
                        // this.categoria = this.categorias.find((categoria) => categoria.id === Number(this.formInscripcion.get('categoria').value));
                        this.formInscripcion.get('categoria').enable();
                    } else {
                        this.formInscripcion.get('categoria').setValue(null);
                        this.categoria = null;
                    }
                }
                break;
        }
    }

    changeFechaInscripcion2() {
        let fechaFin;
        switch (this.evento.type) {
            case 'motociclismo':
                fechaFin = this.evento.fecha_evento;

                if (this.categoria.modo === 'duplas' && this.categoria.sumatoriaMinima !== null && this.categoria.sumatoriaMaxima !== null) {
                    const fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value).getTime();
                    const fechaInicioPersona2 = new Date(this.formInscripcionPersona2.get('fecha_nacimiento').value).getTime();
                    const diferenciaAnoPersona1 = Math.floor(((fechaFin - fechaInicio) / (1000 * 60 * 60 * 24)) / 365);
                    const diferenciaAnoPersona2 = Math.floor(((fechaFin - fechaInicioPersona2) / (1000 * 60 * 60 * 24)) / 365);

                    this.errorRestriccionEdad = diferenciaAnoPersona1 + diferenciaAnoPersona2 > this.categoria.sumatoriaMaxima ||
                        diferenciaAnoPersona1 + diferenciaAnoPersona2 < this.categoria.sumatoriaMinima;

                } else {

                    this.errorRestriccionEdad = false;

                }
                break;
            default:
                fechaFin = new Date();

                if (this.categoria.modo === 'duplas' && this.categoria.sumatoriaMinima !== null && this.categoria.sumatoriaMaxima !== null) {
                    const fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value);
                    const fechaInicioPersona2 = new Date(this.formInscripcionPersona2.get('fecha_nacimiento').value);
                    const diferenciaAnoPersona1 = Math.floor(fechaFin.getFullYear() - fechaInicio.getFullYear());
                    const diferenciaAnoPersona2 = Math.floor(fechaFin.getFullYear() - fechaInicioPersona2.getFullYear());

                    this.errorRestriccionEdad = diferenciaAnoPersona1 + diferenciaAnoPersona2 > this.categoria.sumatoriaMaxima ||
                        diferenciaAnoPersona1 + diferenciaAnoPersona2 < this.categoria.sumatoriaMinima;

                } else {

                    this.errorRestriccionEdad = false;

                }
                break;

        }


    }

    incripcionClick(continuarPago: boolean) {
        Swal.fire({
            icon: 'question',
            title: "Bases del evento",
            text: 'He leído atentamente, estoy de acuerdo y acepto las bases del evento',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Si, acepto!",
            cancelButtonText: `No, cancelar!`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.categoria = this.categorias.find((categoria) => categoria.id === Number(this.formInscripcion.get('categoria').value));

                if (this.categoria.modo === 'duplas') {
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('evento')!.setValue(this.evento);
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('categoria')!.setValue(this.categoria);

                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcionPersona2.get('evento')!.setValue(this.evento);
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcionPersona2.get('categoria')!.setValue(this.categoria);

                } else {
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('evento')!.setValue(this.evento);
                    // tslint:disable-next-line:no-non-null-assertion
                    this.formInscripcion.get('categoria')!.setValue(this.categoria);
                }

                if (this.categoria.modo === 'duplas') {
                    if (!this.formInscripcion.valid || !this.formInscripcionPersona2.valid) {
                        // tslint:disable-next-line:no-shadowed-variable
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire(
                            'Falta información!',
                            'Existen campos inválidos y/o incompletos.',
                            'error'
                        );
                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcion.get('categoria')!.setValue(this.categoria.id);
                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcionPersona2.get('categoria')!.setValue(this.categoria.id);

                        return;
                    }

                    if (((this.formInscripcion.get('federado').value === 'Federado' && this.formInscripcion.get('id_uci').value === null) || (this.formInscripcionPersona2.get('federado').value === 'Federado' && this.formInscripcionPersona2.get('id_uci').value === null))) {
                        // tslint:disable-next-line:no-shadowed-variable
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire(
                            'Falta completar el ID UCI',
                            'Si eres deportista federado, debes completar el ID UCI',
                            'error'
                        );

                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcion.get('categoria')!.setValue(this.categoria.id);
                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcionPersona2.get('categoria')!.setValue(this.categoria.id);

                        return;
                    }


                } else {

                    if (!this.formInscripcion.valid) {
                        // tslint:disable-next-line:no-shadowed-variable
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire(
                            'Falta información!',
                            'Existen campos inválidos y/o incompletos.',
                            'error'
                        );

                        // tslint:disable-next-line:no-non-null-assertion
                        this.formInscripcion.get('categoria')!.setValue(this.categoria.id);
                        return;
                    }

                    if (((this.formInscripcion.get('federado').value === 'Federado' && this.formInscripcion.get('id_uci').value === null))) {
                        // tslint:disable-next-line:no-shadowed-variable
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire(
                            'Falta completar el ID UCI',
                            'Si eres deportista federado, debes completar el ID UCI',
                            'error'
                        );
                        return;
                    }
                }

                if (this.categoria.valor > 0) {
                    if (continuarPago === true) {
                        this.initTransaccion();

                    } else {
                        this.saveRiderSinPago(true);
                    }
                } else {
                    Swal.fire({
                        icon: 'question',
                        title: "Confirmar inscripción",
                        html: this.mensajeValor(),
                        showDenyButton: false,
                        showCancelButton: true,
                        confirmButtonText: "Confirmar inscripción",
                        cancelButtonText: `No, cancelar!`
                      }).then((result) => {
                        if (result.isConfirmed) {
                            this.saveRiderSinPago(false);

                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            Swal.fire({
                                title: "No te preocupes!",
                                text: "Podrás inscribirte en el evento que quieras :)",
                                icon: "success"
                              });
                            
                        }
                      });

                    
                }
            }
          });

        // const swalWithBootstrapButtons = Swal.mixin({
        //     customClass: {
        //         confirmButton: 'btn btn-success',
        //         cancelButton: 'btn btn-danger'
        //     },
        //     buttonsStyling: false
        // });

        // swalWithBootstrapButtons.fire({
        //     title: 'Bases del evento',
        //     text: 'He leído atentamente, estoy de acuerdo y acepto las bases del evento',
        //     showCancelButton: true,
        //     confirmButtonText: 'Si, acepto!',
        //     cancelButtonText: 'No, cancelar!',
        //     reverseButtons: true,
        //     imageUrl: 'https://firebasestorage.googleapis.com/v0/b/riderticket-qa.appspot.com/o/Sin%20ti%CC%81tulo-1b.png?alt=media&token=2ae62340-f752-4498-a498-b2d4971d615a',
        //     imageHeight: 100,
        // }).then((resultTerminosYCondiciones) => {
        //     if (resultTerminosYCondiciones.isConfirmed) {

                

        //     }
        // });

    }

    validarCupon() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (this.categoria === null) {
            swalWithBootstrapButtons.fire(
                'Seleccion de categoría',
                'Para validar un cupón primero debes rellenar los  datos de tu inscripción',
                'error'
            );
            return;
        }

        this.loading = true;
        const petition: Observable<any> = this.cuponService.validarCupon(
            this.formCupon.get('codigo').value, this.formCupon.get('eventoId').value === null ? 0
            : this.formCupon.get('eventoId').value);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (res !== null && res.disponible === true) {
                        swalWithBootstrapButtons.fire({
                            title: 'El cupón es válido!',
                            text: `Quieres incluir el cupón en esta inscripción? `,
                            showCancelButton: true,
                            confirmButtonText: 'Si, ocupar cupón',
                            cancelButtonText: 'No, cancelar!',
                            reverseButtons: true,
                            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/Grupo%20856.png?alt=media&token=9ca3e1e8-470a-4b4a-9901-3a798b18de2c',
                            imageHeight: 100,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.cupon = res;
                                swalWithBootstrapButtons.fire(
                                    'Cupón agregado',
                                    'Se ha agregado el cupón a la inscripción.',
                                    'success'
                                );
                            } else if (
                                /* Read more about handling dismissals below */
                                result.dismiss === Swal.DismissReason.cancel
                            ) {
                                swalWithBootstrapButtons.fire(
                                    'No te preocupes!',
                                    'Podrás utilizar el cupón cuando quieras :)',
                                    'error'
                                );
                            }
                        });
                    } else {

                        swalWithBootstrapButtons.fire(
                            'Cupón no válido',
                            `El cupón ${this.formCupon.get('codigo').value === null ? '-' : this.formCupon.get('codigo').value} no es válido.`,
                            'error'
                        );
                    }

                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });



    }

    phoneValidator(control: FormControl) {
        let phone = control.value;
        if (('' + phone).replace(/\D/g, '').length !== 11) {
            return {
                phoneInvalid: {
                    parsedPhone: ('' + phone).replace(/\D/g, '')
                }
            }
        }
        return null;
    }

    saveRiderSinPago(soloReserva: boolean) {
        this.loading = true;

        let petition: Observable<any>;



        if (this.categoria.modo === 'duplas') {
            const body = this.formInscripcion.getRawValue();
            body.soloReserva = soloReserva === true ? '1' : '0';
            const body2 = this.formInscripcionPersona2.getRawValue();
            body2.soloReserva = soloReserva === true ? '1' : '0';

            petition = this.riderService.crearRiders(body, body2, '0');
        } else {
            const body = this.formInscripcion.getRawValue();
            body.soloReserva = soloReserva === true ? '1' : '0';

            petition = this.riderService.crearRider(body, '0');
        }
        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {

                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    swalWithBootstrapButtons.fire(
                        soloReserva ? 'IMPORTANTE' : 'Felicitaciones!',
                        soloReserva ? 'Recuerda coordinar el pago con la organización para validar tu inscripción' : 'Te has inscrito al evento.',
                        'success'
                    );
                    this.loading = false;

                    this.router.navigate(['/evento'], { queryParams: { id: this.evento.id } });


                },
                err => {
                    console.log(err);
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    swalWithBootstrapButtons.fire(
                        'Ha ocurrido un error!',
                        JSON.parse(err.error).message,
                        'error'
                    );
                    this.loading = false;
                });
    }

    mensajeValor(): string {
        return 'El precio a pagar es: ' +
            '<br>Valor inscripción: $0' +
            '<br>Costos por servicios y transacción: $0' +
            '<br><strong>Total a pagar: $0 </strong>';

    }

    formatPhone() {
        this.formInscripcion.get('phoneNumber').setValue(this.phoneMask.formatPhoneNumber(this.formInscripcion.get('phoneNumber').value));
    }

    formatPhoneDupla() {
        this.formInscripcionPersona2.get('phoneNumber').setValue(this.phoneMask.formatPhoneNumber(this.formInscripcionPersona2.get('phoneNumber').value));
    }

    validatePhone(event) {
        return this.phoneMask.numberOnly(event);
    }

    showRestriccion() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire(
            'Restricción!',
            `Esta categoria posee una restricción de edad creada por el organizador del evento, la sumatoria de las edades en la categoria ${this.categoria.nombre} - ` +
            `${this.categoria.modo} - ${this.categoria.sexo} debe ser mayor o igual a ${this.categoria.sumatoriaMinima} años y menor o igual` +
            ` a ${this.categoria.sumatoriaMaxima} años.`,
            'error'
        );
    }

    getTipoDocumento1() {
        return this.formInscripcion.get('typeOfDocument').value;
    }

    changeTypeDocument1() {

        switch (this.getTipoDocumento1()) {
            case 'RUT':
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formInscripcion.get('rut').setValidators(validator);
                break;
            case 'passport':
                this.formInscripcion.get('rut').setValidators([Validators.required]);

                break;
        }
    }

    getTipoDocumento2() {
        return this.formInscripcion.get('typeOfDocument').value;
    }

    changeTypeDocument2() {

        switch (this.getTipoDocumento2()) {
            case 'RUT':
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formInscripcion.get('rut').setValidators(validator);
                break;
            case 'passport':
                this.formInscripcion.get('rut').setValidators([Validators.required]);

                break;
        }
    }

    changeExtranjero1(esExtranjero: boolean) {
        this.extranjero1 = esExtranjero;
        this.formInscripcion.get('typeOfDocument').setValue(esExtranjero ? 'passport' : 'RUT');
        this.changeTypeDocument1();
    }

    changeExtranjero2(esExtranjero: boolean) {
        this.extranjero1 = esExtranjero;
        this.formInscripcionPersona2.get('typeOfDocument').setValue(esExtranjero ? 'passport' : 'RUT');
        this.changeTypeDocument2();
    }

    selectAdicional(adicional) {
        this.adicionalesSeleccionados.push({
            adicionalId: adicional.id,
            eventoId: this.id,
            validado: false,
            id: Date.now()
        });
    }

    confirmarAdicional(form: any) {
        const adicionalSeleccionado = this.adicionalesSeleccionados.find((adicional) => adicional.id === form.id);
        adicionalSeleccionado.validado = true;
        adicionalSeleccionado.formAdicional = form;
    }

    obtenerValidado(id: number) {
        return this.adicionalesSeleccionados.find((adicional) => adicional.id === id).validado === true;
    }

    obtenerAdicionalValidado() {
        return this.adicionalesSeleccionados.filter((adicional) => adicional.validado === true);
    }

    getAdicionalTexto(form) {
        let texto = '';
        Object.entries(form).forEach(([key, value]) => {
            if (key !== 'id' && key !== 'porcentaje_cobro' && key !== 'eventoAdicional' && key !== 'campos_formulario') {
                texto = texto + ('<b>' + key.replace('_', ' ') + '</b>: ' + value + ' - ');
            }

            if (key === 'campos_formulario') {
                Object.entries(value).forEach(([key2, value2]) => {
                    texto = texto + ('<b>' + key2.replace('_', ' ') + '</b>: ' + value2 + ' - ');
                });
            }

        });

        return texto;
    }

    eliminar(id: number) {
        this.adicionalesSeleccionados = this.adicionalesSeleccionados.filter((adicional) => adicional.id !== id);
    }

    obtenerCategoriasFiltradas() {
        let fechaFin;
        let fechaInicio;
        let diferenciaAnoPersona1;
        let resultado;


        switch (this.evento.type) {
            case 'motociclismo':
                fechaFin = new Date(this.evento.fecha_evento).getTime();

                fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value).getTime();
                diferenciaAnoPersona1 = Math.floor(((fechaFin - fechaInicio) / (1000 * 60 * 60 * 24)) / 365);

                if (Number(this.formInscripcion.get('desafio').value) === 0) {
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcion.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcion.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
                    );
                }else{
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcion.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcion.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1 &&
                        categoria.desafioEntity.id === Number(this.formInscripcion.get('desafio').value)

                    );
                }

                

                break;
            default:
                fechaFin = new Date();

                fechaInicio = new Date(this.formInscripcion.get('fecha_nacimiento').value);
                diferenciaAnoPersona1 = Math.floor(fechaFin.getFullYear() - fechaInicio.getFullYear());

                if (Number(this.formInscripcion.get('desafio').value) === 0) {
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcion.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcion.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
                    );
                }else{
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcion.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcion.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1 && 
                        categoria.desafioEntity.id === Number(this.formInscripcion.get('desafio').value)

                    );
                }
                


                break;
        }


        return resultado;

    }

    obtenerCategoriasFiltradasDupla() {
        let fechaFin;
        let fechaInicio;
        let diferenciaAnoPersona1;
        let resultado;


        switch (this.evento.type) {
            case 'motociclismo':
                fechaFin = new Date(this.evento.fecha_evento).getTime();

                fechaInicio = new Date(this.formInscripcionPersona2.get('fecha_nacimiento').value).getTime();
                diferenciaAnoPersona1 = Math.floor(((fechaFin - fechaInicio) / (1000 * 60 * 60 * 24)) / 365);

                if (Number(this.formInscripcionPersona2.get('desafio').value) === 0) {
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcionPersona2.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcionPersona2.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
                    );
                }else{
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcionPersona2.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcionPersona2.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1 &&
                        categoria.desafioEntity.id === Number(this.formInscripcionPersona2.get('desafio').value)

                    );
                }
                

                if (this.formInscripcionPersona2.get('fecha_nacimiento').value !== null && this.formInscripcionPersona2.get('sexo').value !== null) {
                    if (resultado.length > 0) {
                        this.formInscripcionPersona2.get('categoria').setValue(resultado[0].id);
                        this.categoria = this.categorias.find((categoria) => categoria.id === Number(this.formInscripcionPersona2.get('categoria').value));
                    }
                    this.formInscripcionPersona2.get('categoria').enable();
                }
                break;
            default:
                fechaFin = new Date();

                fechaInicio = new Date(this.formInscripcionPersona2.get('fecha_nacimiento').value);
                diferenciaAnoPersona1 = Math.floor(fechaFin.getFullYear() - fechaInicio.getFullYear());

                if (Number(this.formInscripcionPersona2.get('desafio').value) === 0) {
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcionPersona2.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcionPersona2.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1
                    );
                }else{
                    resultado = this.categorias.filter((categoria) =>
                        (this.formInscripcionPersona2.get('federado').value === 'No federado' ? categoria.federada === 'DESACTIVADA' : (categoria.federada === 'DESACTIVADA' || categoria.federada === 'ACTIVADA')) &&
                        (categoria.sexo === (this.formInscripcionPersona2.get('sexo').value === 'femenino' ? 'Damas' : 'Varones') ||
                            categoria.sexo === 'Mixto') &&
                        categoria.edadMinima <= diferenciaAnoPersona1 && categoria.edadMaxima >= diferenciaAnoPersona1 &&
                        categoria.desafioEntity.id === Number(this.formInscripcionPersona2.get('desafio').value)

                    );
                }
               

                if (this.formInscripcionPersona2.get('fecha_nacimiento').value !== null && this.formInscripcionPersona2.get('sexo').value !== null) {
                    if (resultado.length > 0) {
                        this.formInscripcionPersona2.get('categoria').setValue(resultado[0].id);
                        this.categoria = this.categorias.find((categoria) => categoria.id === Number(this.formInscripcionPersona2.get('categoria').value));
                    }
                    this.formInscripcionPersona2.get('categoria').enable();
                }
                break;
        }


        return resultado;



    }

    obtenerUltimaConfiguracionCat(cat: any) {

        const objetosFiltrados = cat.configuracionCategoriasRider;
        const objetosOrdenados = objetosFiltrados.sort((a, b) => b.id - a.id);
        const ultimoObjeto = objetosOrdenados[0];
        return ultimoObjeto;

    }

    cambioRUT(dupla: boolean) {
        const rut = dupla === false ? this.formInscripcion.get('rut').value : this.formInscripcionPersona2.get('rut').value;
        if (rut.length === 0 || this.evento.reserva_placa !== 'ACTIVADO') { return; }
        const obtenerRiderPorRut = this.riderService.obtenerRidersPorRutYEvento(dupla === false ? this.formInscripcion.get('rut').value : this.formInscripcionPersona2.get('rut').value, this.evento.id);

        const petitions = forkJoin([obtenerRiderPorRut]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (res[0].length > 0) {

                        if (res[0][0].dor.length > 0) {

                            Swal.fire({
                                title: 'Tienes un número de moto reservada',
                                text: `En nuestro sistema para este evento ya tienes el número ${res[0][0].dor} reservado.`,
                                showCancelButton: true,
                                confirmButtonText: `Utilizar ${res[0][0].dor}`,
                                cancelButtonText: 'Escoger otro número',
                                cancelButtonColor: '#d33',
                                reverseButtons: true,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    if (dupla === true) {

                                        this.formInscripcionPersona2.get('dor').setValue(res[0][0].dor);
                                    } else {
                                        this.dorObligatoria = res[0][0].dor;
                                        this.formInscripcion.get('dor').setValue(res[0][0].dor);
                                    }

                                }
                            });



                        } else {
                            this.dorObligatoria = null;
                        }

                    } else {
                        this.dorObligatoria = null;
                    }
                    // this.dorsalesDisponibles = res[0];
                    // this.loadingInfoInicial = false;
                },
                err => {
                    console.log(err);
                    // this.loadingInfoInicial = false;
                });
    }

}
