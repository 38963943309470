import { Component, OnInit } from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {NoticiaService} from '../../../services/noticia.service';
import {take, takeUntil} from 'rxjs/operators';
import {GoogleAnalytics} from '../../../shared/google-analytics';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss']
})
export class NoticiaComponent implements OnInit {
    public loading = false;
    public noticias: any[] = [];
    private compDestroy: Subject<boolean> = new Subject();
  constructor(
      private noticiaService: NoticiaService,
      private googleAnalytics: GoogleAnalytics


  ) { }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('noticias', 'exitoso', '', 'click', 0);

        this.noticiaService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    getDataDefault() {
        this.loading = true;
        const obtenerNoticias = this.noticiaService.obtenerNoticias();

        const petitions = forkJoin([obtenerNoticias]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.noticias = [];
                    res[0].forEach((element: any) => {
                        this.noticias.push(element);
                    });


                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }
}
