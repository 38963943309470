import {Component, OnInit} from '@angular/core';
import {firstValueFrom, forkJoin, Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {EventoService} from '../../../services/evento.service';
import {RiderService} from '../../../services/rider.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {take, takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {RutValidator} from 'ng9-rut';
import {SolicitudCambioCategoriaService} from '../../../services/solicitud-cambio-categoria.service';
import {environment} from '../../../../environments/environment';
import { ReservaPlacaService } from 'src/app/services/reserva-placa.service';

@Component({
    selector: 'app-reservar-numero',
    templateUrl: './reservar-numero.component.html',
    styleUrls: ['./reservar-numero.component.scss']
})
export class ReservarNumeroComponent implements OnInit {
    public id = 0;

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public formReserva: FormGroup ;
    public numerosDisponibles: any[]  = [];
    public extranjero = false;


    constructor(private activatedroute: ActivatedRoute,
                private reservaPlacaService: ReservaPlacaService,
                private riderService: RiderService,
                private solicitudCambioCategoriaService: SolicitudCambioCategoriaService,
                private fb: FormBuilder,
                private router: Router,
                private rutValidator: RutValidator,

    ) {
        this.activatedroute.queryParams.subscribe(params => {
            if (params.id !== undefined){
                this.id = Number(params.id);
  
            }
        });
    }

    ngOnInit(): void {
        this.obtenerNumerosDisponibles();
    }


    initFormReserva() {
        this.formReserva = this.fb.group({
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
            dor: ['', [Validators.required, Validators.minLength(1)]],
            eventoId: [this.id],

        });
    }


    obtenerNumerosDisponibles(){
       

        

        this.loading = true;
        const petition: Observable<any> = this.reservaPlacaService.obtenerPlacasDisponibles(this.id);

        const petitions = forkJoin([petition]);

        petitions.pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.numerosDisponibles = res[0];
                    this.initFormReserva();
                    this.loading = false;

                },
                err => {

                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });
    }

    

    reservar() {
        if (!this.formReserva.valid) {
            // tslint:disable-next-line:no-shadowed-variable
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Falta información!',
                'Existen campos inválidos y/o incompletos.',
                'error'
            );

            return;
        }

        Swal.fire({
            icon: "question",
            title: "Estás seguro?",
            showCancelButton: true,
            confirmButtonText: "Estoy seguro",
            cancelButtonText: `Cancelar`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.loading = true;
                const petition: Observable<any> = this.reservaPlacaService.crearReservaPlaca(this.formReserva.value);

                        petition
                            .pipe(take(1), takeUntil(this.compDestroy))
                            .subscribe(
                                res => {
                                    Swal.fire( {icon: "success", title: "Éxito", text:"Has reservado tu número con éxito. Ahora quedará asociado a tu RUT/DNI"});

                                    this.router.navigate(['/reserva-numeros']);

                                },
                                err => {
                                    if (err) {
                                        Swal.fire({
                                          icon: 'error',
                                          title: 'Oops...',
                                          text: JSON.parse(err.error).message,
                                        })
                            
                                      }
                                    this.loading = false;
                                });
            }
          });
    }


    changeExtranjero(esExtranjero: boolean) {
        this.extranjero = esExtranjero;
        this.changeTypeDocument();
    }

    changeTypeDocument() {

        switch (this.extranjero){
            case false:
                const validator: any[] = [Validators.required, this.rutValidator,  Validators.minLength(5)];
                this.formReserva.get('rut').setValidators(validator);
                break;
            case true:
                this.formReserva.get('rut').setValidators([Validators.required]);

                break;
        }
    }
}
