<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-more">
                        <br>
                        <h3>{{dataUser.firstName}} {{dataUser.lastName}}</h3>

                        <ul>
                            <li><a routerLink="/profile">Mi Perfil <i class='bx bxs-user'></i></a></li>
                            <li><a routerLink="/mis-inscripciones">Mis inscripciones <i class='bx bx-task'></i></a></li>
                            <li><a routerLink="/mis-transacciones">Mis pagos <i class='bx bxs-credit-card'></i></a></li>
                            <li><a routerLink="/mis-estadisticas">Mis estadisticas <i class='bx bxs-bar-chart-alt-2'></i></a></li>
                            <li><a routerLink="/change-password">Contraseña <i class='bx bxs-key'></i></a></li>
                            <li><a class="logout" (click)="logout()">Cerrar sesión <i class='bx bx-exit'></i></a></li>
                        </ul>
                    </div>


                </div>
            </div>

            <div class="col-lg-9">
                <section class="checkout-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkout-item ">
                                    <h2>Cambio de contraseña</h2>

                                    <div class="checkout-one">
                                        <form [formGroup]="formChangePassword">
                                            <div class="form-group">
                                                <label>Contraseña actual:</label>
                                                <input formControlName="passwordOld" [type]="showPassword ? 'text' : 'password'" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <label>Contraseña nueva:</label>
                                                <input formControlName="password" [type]="showPassword ? 'text' : 'password'" class="form-control">
                                            </div>

                                            <div class="form-group">
                                                <input (change)="changeShowPass()" type="checkbox"><label>&nbsp;&nbsp;Mostrar contraseña</label>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="text-center">
                            <button (click)="changePassword()" class="btn cmn-btn">Cambiar contraseña</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
