import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EncryptService} from '../../../shared/encrypt';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsuarioDeportistaService} from '../../../services/usuario-deportista.service';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2';
import {UsuarioOrganizadorService} from '../../../services/usuario-organizador.service';
import {PhoneMaskService} from "../../../shared/phone-mask";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    public loading = false;
    public dataUser = null;
    private compDestroy: Subject<boolean> = new Subject();
    public formUser: FormGroup ;
    public extranjero = false;

    constructor(private usuarioDeportistaService: UsuarioDeportistaService,
              private usuarioOrganizadorService: UsuarioOrganizadorService,
              private router: Router,
              private encryptService: EncryptService,
              private fb: FormBuilder,
              private datePipe: DatePipe,
              private phoneMask: PhoneMaskService

  ) { }

  ngOnInit(): void {
      this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));

      this.initForm();

      if(this.dataUser.typeOfDocument === 'RUT'){
          this.changeExtranjero(false);
      }

      if(this.dataUser.typeOfDocument === 'passport'){
          this.changeExtranjero(true);
      }
  }

    initForm() {
        this.formUser = this.fb.group({
            firstName: [this.dataUser ? this.dataUser.firstName : '', Validators.required],
            lastName: [this.dataUser ? this.dataUser.lastName : '', Validators.required],
            birthDate: [this.dataUser ? (this.datePipe.transform(this.dataUser.birthDate, 'yyyy-MM-dd', 'UTC')) : '', Validators.required],
            nationality: [this.dataUser ? this.dataUser.nationality : null],
            phoneNumber: [this.dataUser ? this.dataUser.phoneNumber : null],
            rut: [this.dataUser ? this.dataUser.rut : null, Validators.required],
            typeOfDocument: [this.dataUser ? this.dataUser.typeOfDocument : null],
            team: [this.dataUser ? this.dataUser.team : null],
        });
    }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();

        this.router.navigate(['/']);
    }

    update(){
        if (!this.formUser.valid){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Faltan campos obligatorios',
                'error'
            );
            return;
        }


        this.loading = true;

        let petition = null;

        if (this.dataUser.role === 'Organizador'){
            petition = this.usuarioOrganizadorService.update(this.dataUser.id, this.formUser.value);
        }else{
            petition = this.usuarioDeportistaService.update(this.dataUser.id, this.formUser.value);
        }

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.loading = false;

                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    swalWithBootstrapButtons.fire(
                        'Muy bien!',
                        'Has actualizado tu información con éxito!',
                        'success'
                    );

                    sessionStorage.setItem('dataUserComplete', this.encryptService.encryptData(JSON.stringify(res)));
                    sessionStorage.setItem('userDataCompleteInSession', this.encryptService.encryptData(JSON.stringify(res)));

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });
    }

    formatPhone(){
        this.formUser.get('phoneNumber').setValue(this.phoneMask.formatPhoneNumber(this.formUser.get('phoneNumber').value));
    }


    validatePhone(event){
        return this.phoneMask.numberOnly(event);
    }

    changeExtranjero(esExtranjero: boolean) {
        this.extranjero = esExtranjero;
        this.formUser.get('typeOfDocument').setValue(esExtranjero ? 'passport' : 'RUT');
        this.changeTypeDocument();
    }

    changeTypeDocument() {

        switch (this.getTipoDocumento()){
            case 'RUT':
                const validator: any[] = [Validators.required,  Validators.minLength(5)];
                this.formUser.get('rut').setValidators(validator);
                break;
            case 'passport':
                this.formUser.get('rut').setValidators([Validators.required]);

                break;
        }
    }

    getTipoDocumento(){
        return this.formUser.get('typeOfDocument').value;
    }
}
