<app-navbar-style-three></app-navbar-style-three>


<section class="checkout-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="checkout-item ">
                    <h2 style="margin: 0 10px 50px 10px">Recuperación de contraseñaa, ingresa tu correo electrónico</h2>

                    <div class="checkout-one">
                        <form [formGroup]="formRegister">

                            <div class="form-group">
                                <label>Correo electrónico:</label>
                                <input formControlName="email" type="email" class="form-control">
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>

        <div class="text-center">
            <button (click)="recuperarContrasena()" class="btn cmn-btn">Siguiente</button>
        </div>

        <div class="cart-wrap">
            <div class="shop-back">
                <a routerLink="/login">Ya tengo cuenta?, Iniciar sesión</a>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
