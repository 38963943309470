<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Preguntas frecuentes</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Preguntas frecuentes</li>
            </ul>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Preguntas frecuentes</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>Qué es Rider?</a>
                            <p>Es una plataforma tecnológica-digital desarrollada con la finalidad de facilitar el proceso de gestión de eventos deportivos.</p>
                        </li>
                        <li>
                            <a>Cuál es el precio para utilizar la plataforma?</a>
                            <p>Tenemos los precios mas económicos del mercado, si quieres conocer más escríbenos a <strong>hola@riderticket.cl</strong></p>
                        </li>
                        <li>
                            <a>Para quién esta dirigida esta plataforma?</a>
                            <p>Esta plataforma está dirigida a organizadores de eventos deportivos y deportistas.</p>
                        </li>
<!--                        <li>-->
<!--                            <a>How can we get in touch with you?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a>Is this restaurant 24 hours open?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?</p>-->
<!--                        </li>-->
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Organizadores de eventos</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>Cómo ingreso a la plataforma?</a>
                            <p>Escribenos a <strong>hola@riderticket.cl</strong> y te daremos un usuario y una clave con la que posteriormente podrás ingresar a nuestro portal de gestión de eventos.</p>
                        </li>
                        <li>
                            <a>Que podré gestionar en la plataforma?</a>
                            <p>En la plataforma podrás crear categorias (individuales/duplas), eventos, riders, ver tus ganancias, publicar los resultados y ver datos estadísticos de tus eventos.</p>
                        </li>
                        <li>
                            <a>Alguien me ayuda?</a>
                            <p>Tienes soporte 24/7 de parte de nuestro equipo</p>
                        </li>
<!--                        <li>-->
<!--                            <a>Do we give home delivery to pizza?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a>When pizza is available?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident porro laboriosam magni labore quaerat, aspernatur iusto error ducimus adipisci, et corporis recusandae illum tenetur hic? Earum excepturi porro fuga ipsum? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint natus ipsa accusantium saepe dolorem a mollitia tempora consequatur totam modi pariatur nulla, aspernatur dolore consequuntur temporibus unde minima repellendus laboriosam.</p>-->
<!--                        </li>-->
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Deportistas</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>Qué puedo hacer como deportista?</a>
                            <p>Para ti deportista, hemos desarrollado una página web con mucho cariño y dedicación, donde podrás ver los eventos disponibles, inscribirte y pagar con diversos medios de pago.</p>
                        </li>
                        <li>
                            <a>Podré ver los resultados de los eventos?</a>
                            <p>Así es, podrás ver los resultados de los eventos.</p>
                        </li>
<!--                        <li>-->
<!--                            <a>What people say about our pasta?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a>Do we give home delivery to pizza?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a>When pizza is available?</a>-->
<!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>-->
<!--                        </li>-->
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>Resultados</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li>
                            <a>Qué pasa si un resultado esta mal registrado?</a>
                            <p>Escríbenos a <strong>hola@riderticket.cl</strong> y nos comunicaremos con el organizador del evento</p>
                        </li>
<!--                        <li>-->
<!--                            <a>Podré ver los resultados de los eventos?</a>-->
<!--                            <p>Así es, podrás ver los resultados de los eventos.</p>-->
<!--                        </li>-->
                        <!--                        <li>-->
                        <!--                            <a>What people say about our pasta?</a>-->
                        <!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>-->
                        <!--                        </li>-->
                        <!--                        <li>-->
                        <!--                            <a>Do we give home delivery to pizza?</a>-->
                        <!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>-->
                        <!--                        </li>-->
                        <!--                        <li>-->
                        <!--                            <a>When pizza is available?</a>-->
                        <!--                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quis modi ullam amet debitis libero veritatis enim repellat optio natus eum delectus deserunt, odit expedita eos molestiae ipsa totam quidem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, quos mollitia voluptatum aliquam repellendus similique iure fuga aspernatur amet odit! At vitae dicta excepturi quasi? Veritatis, pariatur excepturi! Illum, ut?3</p>-->
                        <!--                        </li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
