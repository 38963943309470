<div class="page-title-area" [style.background-image]="urlBannerSanitizer">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="page-title-item">
                    <h2>{{eventName}}</h2>
                    <ul>
                        <li><a [routerLink]="routerOne">{{navigationOne}}</a></li>
                        <li><i class='bx bx-chevron-right' ></i></li>
                        <li>{{navigationTwo}}</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>
