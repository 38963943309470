<app-navbar-style-three></app-navbar-style-three>

<app-banner-custom *ngIf="!loading" [urlBanner]="evento.url_banner" [eventName]="evento.nombre" routerOne="/"
    navigationOne="Inicio" [navigationTwo]="evento.nombre"></app-banner-custom>

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3" *ngIf="!loading">
                <div class="services-details-item">
                    <div class="services-details-item">

                        <img [src]="evento.url_foto" alt="Service" style="margin-bottom: 16px;">

                        <br>
                    </div>

                    <div class="services-details-item" *ngIf="cantidadEventosAdicionalesValidos() > 0">
                        <div class="services-details-more">
                            <ul>
                                <li><a routerLink="/adicionales-evento" [queryParams]="{ id: evento.id}">Productos
                                        adicionales <i class='bx bx-plus'></i></a></li>

                            </ul>
                        </div>
                    </div>

                    <div class="services-details-item" *ngIf="evento.url_strava !== null">
                        <a [href]="evento.url_strava" style="color: white;" target="_blank">
                            <div class="services-details-order" style="background-color: #fc4c01">
                                <h3>Ver ruta</h3>

                                <img src="assets/img/strava.jpeg">
                                <br>
                            </div>
                        </a>


                    </div>

                    <div class="services-details-item" style="margin-top: 10px;display: inline-block;">
                        <ng-adsense [adClient]="getEnvironmentAdSense()" [adSlot]="7460941019"></ng-adsense>
                    </div>

                    <p class="calendar">{{getDayNumber(evento.fecha_evento)}}
                        <em>{{getMonthSpanish(evento.fecha_evento)}}</em></p>



                </div>
            </div>

            <div class="col-lg-9">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <div class="row" *ngIf="!loading">

                            <h3 style="text-align: center;">{{evento.nombre}}
                                <span *ngIf="evento.estado === 'SUSPENDIDO'" class="red"> - SUSPENDIDO</span>
                                <span *ngIf="evento.estado === 'PROXIMAMENTE'" class="green"> - PROXIMAMENTE</span>
                                <span *ngIf="evento.estado === 'INSCRIPCIONES_CERRADAS'" class="green"> - INSCRIPCIONES
                                    CERRADAS</span>
                                <span
                                    *ngIf="evento.cupos === evento.cantidad_inscritos && evento.estado === 'POR_REALIZAR'"
                                    class="red"> - CUPOS AGOTADOS</span>

                            </h3>
                            <p style="text-align: center;">
                                <img *ngIf="evento.usuario.photoProfile !== null" style="margin-bottom: initial;
                                    width: 45px;
                                    height: 45px;
                                    border: 2px solid #ffbe00;
                                    margin-right: 10px;
                                    object-fit: cover;" [src]="evento.usuario.photoProfile" />
                                <img *ngIf="evento.usuario.photoProfile === null" style="margin-bottom: initial;
                                    width: 45px;
                                    height: 45px;
                                    border: 2px solid #ffbe00;
                                    margin-right: 10px;
                                    object-fit: cover;"
                                    src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/5778454.png?alt=media&token=3245a542-d833-4345-ab80-6835d629cbf9" />
                                Publicado por: {{evento.usuario.firstName}} {{evento.usuario.lastName}}

                            </p>

                            <br>




                        </div>
                        <div style="padding: 10px;text-align: center;" *ngIf="!loading">
                            <a style="width: 50%;"
                                *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'ACTIVADO'"
                                class="cmn-btn" routerLink="/resultados" [queryParams]="{ id: evento.id}">Resultados</a>
                            <a style="width: 50%;"
                                *ngIf="evento.estado === 'REALIZADO' && evento.servicio_cronometraje === 'DESACTIVADO'"
                                class="cmn-btn"> Finalizado</a>
                            <a style="width: 50%;"
                                *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos"
                                class="cmn-btn" routerLink="/inscripcion"
                                [queryParams]="{ id: evento.id}">Inscribirse</a>

                        </div>
                        <br>
                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Fecha: </h3>
                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div class="row" style="text-align: center;" *ngIf="!loading">

                            <span><strong>Fecha evento:&nbsp;</strong>{{evento.fecha_evento | date : 'dd-MM-yyyy' :
                                'UTC'}}  <span *ngIf="evento.fecha_fin_evento !== null">al {{evento.fecha_fin_evento | date : 'dd-MM-yyyy' :
                                    'UTC'}}</span> </span>
                                
                                <br>
                            <span><strong>Hora citación:&nbsp;</strong>{{evento.hora_citacion}}</span><br>
                            <span><strong>Hora largada:&nbsp;</strong>{{evento.hora_largada}}</span><br>

                        </div>


                        <br>
                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Lugar del evento: </h3>
                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div style="text-align: center;" *ngIf="!loading"><span><strong>¿Dónde se encuentra el
                                    evento?:&nbsp;</strong>{{evento.lugar}}</span>
                        </div>
                        <hr>
                        <div id="mapa-mapbox" style="width: 100%; height: 300px;"></div>
                        <br>
                        <h3 *ngIf="!loading && evento.mostrarRuta === 'ACTIVADO'"
                            style="margin-bottom: 10px; text-align: center;">Ruta: </h3>
                        <div *ngIf="!loading && evento.mostrarRuta === 'ACTIVADO'" class="arrow"
                            style="margin-bottom: 50px">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div #mapaRuta id="mapa-mapbox-ruta" style="width: 100%; height: 300px;"></div>
                        <br *ngIf="!loading && evento.mostrarRuta === 'ACTIVADO'">
                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Categorías del evento:
                        </h3>
                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <table class="table" *ngIf="!loading">
                            <tr>
                                <th>Desafío</th>
                                <th>Categoría</th>
                                <th *ngIf="evento.mostrar_giros === 'ACTIVADO'" style="text-align: center;">Giros</th>

                                <ng-container *ngIf="evento.mostrar_precio === 'ACTIVADO'">
                                    <th *ngFor="let preventa of preventas; let i = index" style="text-align: center;">
                                        {{preventa.titulo}} <br>
                                        <span *ngIf="preventa.mostrarCupos === 'ACTIVADO'" style="font-size: 12px;
                                    font-weight: bold;
                                    color: grey;
                                    word-wrap: break-word;"> Cupos: {{(preventa.cantidadMaxima -
                                            preventa.cantidadMinima) +1}}</span>
                                    </th>

                                </ng-container>

                            </tr>
                            <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"></tr>
                            <tr [attr.rowspan]="preventas.length + 2" style="border-bottom: solid 1px #dee2e6"></tr>
                            <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"></tr>

                            <ng-container *ngFor="let desafio of desafios; let d = index">
                                <tr *ngFor="let categoria of ordenarCategorias(desafio.nombre); let j = index">
                                    <td [attr.rowspan]="ordenarCategorias(desafio.nombre).length" *ngIf="j === 0">
                                        {{desafio.nombre}}</td>
                                    <td>{{categoria.nombre}} - {{categoria.sexo}}</td>
                                    <td style="text-align: center;" *ngIf="evento.mostrar_giros === 'ACTIVADO'">
                                        {{categoria.giros}}
                                    </td>
                                    <ng-container *ngIf="evento.mostrar_precio === 'ACTIVADO'">
                                        <td style="text-align: center;" *ngFor="let preventa of preventas;">
                                            ${{round(categoria.valor *
                                            obtenerMultiplicadorPreventa(preventa.porcentajeAumento))| number: '1.0-0'
                                            }}


                                        </td>
                                    </ng-container>


                                </tr>

                                <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"
                                    *ngIf="d<desafios.length-1"></tr>
                                <tr [attr.rowspan]="preventas.length + 2" style="border-bottom: solid 1px #dee2e6"
                                    *ngIf="d<desafios.length-1"></tr>
                                <tr [attr.rowspan]="preventas.length + 2" style="height: 10px;"
                                    *ngIf="d<desafios.length-1"></tr>
                            </ng-container>

                        </table>


                        <br>
                        <h3 style="margin-bottom: 10px; text-align: center;" *ngIf="!loading">Detalles del evento: </h3>
                        <div class="arrow" style="margin-bottom: 50px" *ngIf="!loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        <div class="descripcion-event" [innerHtml]="evento.descripcion" *ngIf="!loading"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="services-details-area pt-100 pb-70" *ngIf="!loading">-->
<!--    <div class="container">-->
<!--        <div class="row">-->

<!--            <div class="col-lg-12">-->
<!--                <div class="services-details-item">-->
<!--                    <div class="services-details-fresh">-->

<!--                        <div class="row">-->
<!--                            <div class="col-lg-5">-->
<!--                                <img [src]="evento.url_foto" alt="Service">-->

<!--                            </div>-->
<!--                            <div class="col-lg-7">-->
<!--                                <h3>{{evento.nombre}}-->
<!--                                    <span *ngIf="evento.estado === 'SUSPENDIDO'" class="red"> - SUSPENDIDO</span>-->
<!--                                    <span *ngIf="evento.estado === 'PROXIMAMENTE'" class="green"> - PROXIMAMENTE</span>-->
<!--                                    <span *ngIf="evento.cupos === evento.cantidad_inscritos && evento.estado === 'POR_REALIZAR'" class="red"> - CUPOS AGOTADOS</span>-->

<!--                                </h3>-->
<!--                                <ul>-->
<!--                                    <li><strong>Fecha evento: </strong>{{evento.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}</li>-->
<!--                                    <li><strong>Hora citación: </strong>{{evento.hora_citacion}}</li>-->
<!--                                    <li><strong>Hora largada: </strong>{{evento.hora_largada}}</li>-->
<!--                                    <li><strong>Lugar: </strong>{{evento.lugar}}</li>-->
<!--                                    <li><strong>Categorias: </strong></li>-->
<!--                                    <ul>-->
<!--                                        <ng-container *ngIf="evento.mostrar_precio === 'ACTIVADO'">-->
<!--                                            <li *ngFor="let categoria of ordenarCategorias()">{{categoria.nombre}} - {{categoria.sexo}} - <strong>${{categoria.valor}} <span *ngIf="evento.mostrar_giros === 'ACTIVADO'">- # Giros: {{categoria.giros}}</span></strong></li>-->

<!--                                        </ng-container>-->
<!--                                        <ng-container *ngIf="evento.mostrar_precio === 'DESACTIVADO'">-->
<!--                                            <li *ngFor="let categoria of ordenarCategorias()">{{categoria.nombre}} - {{categoria.sexo}} <span *ngIf="evento.mostrar_giros === 'ACTIVADO'">- # Giros: {{categoria.giros}}</span></li>-->

<!--                                        </ng-container>-->
<!--                                    </ul>-->
<!--                                    <li *ngIf="evento.url_strava !== null"><strong>Strava: </strong>-->
<!--                                        <a [href]="evento.url_strava" target="_blank">Ver ruta</a></li>-->
<!--                                </ul>-->
<!--                                <br>-->
<!--                                <a *ngIf="evento.estado === 'REALIZADO'" class="cmn-btn" routerLink="/resultados" [queryParams]="{ id: evento.id}">Resultados</a>-->
<!--                                <a *ngIf="evento.estado === 'POR_REALIZAR' && evento.cupos > evento.cantidad_inscritos" class="cmn-btn" routerLink="/inscripcion" [queryParams]="{ id: evento.id}">Inscribirse</a>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <hr>-->
<!--                        <h3 style="margin-bottom: 50px">Descripción: </h3>-->
<!--                        <div [innerHtml]="evento.descripcion"></div>-->

<!--                        <div class="row">-->
<!--                            <div class="map_main">-->
<!--                                <div class="map-responsive">-->
<!--                                    <iframe-->
<!--                                        [src]='evento.url_map | safe'-->
<!--                                        width="600" height="450" style="border:0; width: 100%;" allowfullscreen="" loading="lazy"></iframe>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->



<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading"></app-preloader>