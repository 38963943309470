import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { environment } from "../../../../environments/environment";
import { CotizacionService } from '../../../services/cotizacion.service';
import { EventoService } from '../../../services/evento.service';
import { PublicidadService } from '../../../services/publicidad.service';
import { RiderService } from "../../../services/rider.service";
import { GoogleAnalytics } from '../../../shared/google-analytics';
import { MathHelper } from '../../../shared/math_helper';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public eventos: any[] = [];
    public afiches: any[] = [];
    public topmasculino: any[] = [];
    public topfemenino: any[] = [];
    public publicaciones: any[] = [];
    public eventosFiltro: any[] = [];

    @ViewChild('todos') todos: ElementRef;
    @ViewChild('realizados') realizados: ElementRef;
    @ViewChild('suspendidos') suspendidos: ElementRef;
    @ViewChild('por_realizar') porRealizar: ElementRef;
    @ViewChild('ciclismo') ciclismo: ElementRef;
    @ViewChild('duatlon') duatlon: ElementRef;
    @ViewChild('running') running: ElementRef;

    public filtro = 'POR_REALIZAR';

    public captchaResolved = false;
    public formCotizacion: FormGroup;

    constructor(
        private eventoService: EventoService,
        private cotizacionService: CotizacionService,
        private riderService: RiderService,
        private publicidadService: PublicidadService,
        private mathHelper: MathHelper,
        private googleAnalytics: GoogleAnalytics,
        private fb: FormBuilder,

    ) {

    }


    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('inicio', 'exitoso', '', 'click', 0);
        this.initForm();

        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    initForm() {
        this.formCotizacion = this.fb.group({
            nombre: ['', Validators.required],
            correoElectronico: ['', Validators.required],
            numeroTelefono: [null, Validators.required],
            numeroDeportistas: [null, Validators.required],
            lugarEvento: [null, Validators.required],
            comentarios: [null, Validators.required],
            fechaEvento: [null, Validators.required],
            token: [null, Validators.required],

        });
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventos = this.eventoService.obtenerEventosConLimiteVisibles(20);
        const obtenerAfiche = this.publicidadService.obtenerAfichePorEstado('DISPONIBLE');
        const obtenerMejoresRidersPorSexoFemenino = this.riderService.obtenerMejoresRidersPorSexo('femenino');
        const obtenerMejoresRidersPorSexoMasculino = this.riderService.obtenerMejoresRidersPorSexo('masculino');
        const obtenerEventosBikerace = this.eventoService.obtenerEventosBikeraceConLimiteVisibles(20);

        const petitions = forkJoin([obtenerEventos, obtenerAfiche, obtenerMejoresRidersPorSexoFemenino, obtenerMejoresRidersPorSexoMasculino, obtenerEventosBikerace]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any) => {
                    this.eventos = [];
                    res[0].forEach((element: any) => {
                        element.platform = 'riderticket';
                        this.eventos.push(element);
                    });
                    res[4].forEach((element: any) => {
                        element.platform = 'bikerace';
                        this.eventos.push(element);
                    });
                    this.afiches = [];
                    this.afiches = res[1];
                    if (this.afiches.length > 0) {
                        Swal.fire({
                            // padding: 20,
                            title: `${this.afiches[0].titulo}`,
                            // text: 'Modal with a custom image.',
                            // imageUrl: `${this.afiches[0].url_imagen}`,
                            // imageWidth: '20%',
                            // imageHeight: '40%',
                            html: `<img src="${this.afiches[0].url_imagen}">`,
                            heightAuto: false,
                            imageAlt: 'Afiche bikerace',
                            showCancelButton: true,
                            cancelButtonText: 'Cerrar',
                            confirmButtonText: `${this.afiches[0].texto_boton}`,
                            reverseButtons: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.open(`${this.afiches[0].url_redireccion}`, '_blank');
                            }
                        });
                    }
                    this.topfemenino = res[2];
                    this.topmasculino = res[3];


                    this.eventosFiltro = this.eventos;
                    this.filtrarEventos('POR_REALIZAR');

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    filtrarEventos(estado: string) {
        this.filtro = estado;


        switch (estado) {
            case 'TODAS':
                this.eventosFiltro = this.eventos;
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;
            case 'POR_REALIZAR':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS');
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;
            case 'REALIZADO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            case 'SUSPENDIDO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            case 'bikerace':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.platform === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'bikerace').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            default:
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.filter((evento) => evento.platform === 'riderticket').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;

        }
    }


    solicitarCotizacion() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!this.captchaResolved) {
            swalWithBootstrapButtons.fire(
                'Verificación.',
                'Te falto pasar por el proceso de verificación',
                'error'
            );

            return;
        }

        if (this.formCotizacion.invalid) {
            for (const control of Object.keys(this.formCotizacion.controls)) {
                this.formCotizacion.controls[control].markAsTouched();
            }
            return;
        }



        this.loading = true;
        const petition: Observable<any> = this.cotizacionService.solicitarCotizacion(this.formCotizacion.value);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (res === true) {

                        this.formCotizacion.reset();

                        swalWithBootstrapButtons.fire(
                            'Solicitud recibida',
                            'Te contáctaremos lo antes posible.',
                            'success'
                        );

                    } else {
                        swalWithBootstrapButtons.fire(
                            'No se pudo procesar tu solicitud.',
                            'Verifica la validez de los datos ingresados.',
                            'error'
                        );
                    }

                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });




    }

    checkCaptcha(captchaResponse: string) {
        this.captchaResolved = (captchaResponse && captchaResponse.length > 0);
    }

    camelCaseToWords(str: string) {
        return str
            .trim()
            .toLowerCase()
            .replace(/^[a-z]/g, char => ` ${char.toUpperCase()}`)
            .replace(/[A-Z]|[0-9]+/g, ' $&')
            .replace(/(?:\s+)/, char => '');
    };

    getEnvironmentAdSense() {
        return environment.adSenseClient;
    }

}
